$page-designer-gutters: (
	sm: (
		'sm': 10px,
		'md': 15px,
		'lg-up': 25px
	),
	md: (
		'sm': 25px,
		'md': 40px,
		'lg-up': 45px
	),
	lg: (
		'sm': 35px,
		'md': 50px,
		'lg-up': 65px
	),
	xl: (
		'sm': 45px,
		'md': 60px,
		'lg-up': 80px
	),
	zero: (
		'sm': 0,
		'md': 0,
		'lg-up': 0
	)
);
