/*md

# Select

This component allows user to choose one option from drop-down list.

<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="arrow-down-small" viewBox="0 0 10 6">
        <path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
    </symbol>
</svg>

```html_example
<div class="b-select">
	<select data-ref="field" class="b-select-input m-valid" id="" name="" required="" value="" maxlength="2147483647" aria-required="true" data-event-change="onChange" data-tau="" aria-describedby="" data-event-blur="validate">
		<option value="US" data-id="US" selected="">United States</option>
		<option value="CA" data-id="CA">Canada</option>
	</select>
	<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
		<use href="#arrow-down-small"></use>
	</svg>
</div>
```

## Error State

```html_example
<div class="b-select">
	<select data-ref="field" class="b-select-input m-invalid" id="" name="" required="" value="" maxlength="2147483647" aria-required="true" data-event-change="onChange" data-tau="" aria-describedby="" data-event-blur="validate">
		<option value="" data-id="0">Please select</option>
		<option value="US" data-id="1">United States</option>
		<option value="CA" data-id="2">Canada</option>
	</select>
	<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
		<use href="#arrow-down-small"></use>
	</svg>
</div>
```

*/

.b-select {
	position: relative;
	width: 100%;

	&-icon {
		bottom: 0;
		pointer-events: none;
		position: absolute;
		right: rh(3);
		top: 50%;
		transform: translateY(-50%);
		width: 10px;
	}

	&-input {
		appearance: none;
		background: $color-bg;
		border: $global-line solid $color-input-border;
		border-radius: $input-radius;
		box-shadow: none;
		box-sizing: border-box;
		color: inherit;
		cursor: pointer;
		display: inline-block;
		font-family: inherit;
		font-size: 13px;
		font-weight: normal;
		height: $size-input-height;
		line-height: $size-input-height - 2 * $global-line;
		outline: none;
		padding: 0 33px 0 10px;
		position: relative;
		transition: border-color $motion-ease;
		user-select: none;
		width: 100%;

		&:hover,
		&:active,
		&:focus {
			border-color: $color-select-border;
		}

		&:focus::-ms-value {
			background: transparent;
			color: $color-text;
		}

		&::-ms-expand {
			display: none;
		}

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 $color-text;
		}

		&.m-invalid {
			border-color: $color-error-border;
		}

		&.m-disabled,
		&[disabled] {
			background: $color-bg-shade;
			border-color: $color-text-grey;
			color: $color-text-grey;
			cursor: default;
			pointer-events: none;

			+ .b-select-icon {
				display: none;
			}
		}
	}

	&-input_as_text {
		appearance: none;
		background: none;
		border: none;
		font-size: 16px;
		max-width: 100%;
		pointer-events: none;
		white-space: normal;
	}

	&-container.m-open ~ &-icon {
		transform: translateY(-50%) rotate(180deg);
	}

	&-container {
		position: relative;
		user-select: none;

		> select {
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;

			@include media(lg-up) {
				pointer-events: none;
			}
		}
	}

	&-opener {
		@include media(md-down) {
			pointer-events: none;
		}
	}

	&-panel {
		background-color: $color-white;
		border: $global-line solid $color-border-grey;
		border-radius: $global-radius;
		color: $color-text;
		display: none;
		font-size: 15px;
		margin-top: 8px;
		max-height: 288px;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 4px;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: 2;

		&::-webkit-scrollbar {
			background-color: $color-white;
			width: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $color-border-grey;
			border-radius: 8px;
		}
	}

	&-container.m-open &-panel {
		display: block;
	}

	&-option {
		border-radius: $global-radius;
		cursor: pointer;
		margin-bottom: 4px;
		padding: 5px 8px;

		&:last-child {
			margin-bottom: 0;
		}

		&.m-focus {
			background-color: $color-bg-shade;
		}

		&[data-value=''] {
			display: none;
		}
	}
}

option {
	font-family: inherit;
	font-size: inherit;
}
