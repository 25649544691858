.b-remove_product {
	.b-button {
		font-size: 15px;
		max-height: 40px;
		min-width: 100%;
	}

	&-btn + &-btn {
		margin: 20px auto 0;
	}

	&-header {
		padding-top: 80px;
	}

	&-footer {
		flex-direction: column;
		margin-top: 55px;
	}
}
