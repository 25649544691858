.b-stock_search {
	&-window {
		padding: 20px 15px;
		width: 100%;

		@include media(lg-up) {
			max-width: 820px;
			padding: 30px;
		}
	}

	&-header {
		margin: 0;
		padding: 0;
		position: relative;
	}

	&-body {
		font-size: 14px;
		margin: 0;
		text-align: left;
	}

	.b-dialog-close {
		height: 32px;
		margin-top: 5px;
		right: 0;
		top: 50%;
		width: 32px;
		z-index: 1;

		svg {
			height: 18px;
			width: 18px;
		}
	}
}
