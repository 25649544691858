.b-back_to_top {
	align-items: center;
	background: $color-bg;
	bottom: 10px;
	box-shadow: $depth-1;
	color: $color-action-obj;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 15px;
	font-weight: 500;
	justify-content: center;
	line-height: 1.5;
	min-height: 50px;
	min-width: 50px;
	opacity: 0;
	padding: 10px 10px 4px;
	pointer-events: auto;
	position: fixed;
	right: 10px;
	transition: $motion-ease;
	transition-property: opacity, visibility, box-shadow;
	visibility: hidden;
	z-index: z(back-to-top);

	@include hover-supported {
		&:hover {
			background: $color-action-obj;
			box-shadow: $depth-0;
			color: adjust-color-to-bg($color-text);
			text-decoration: none;

			.b-back_to_top-icon {
				height: 17px;
			}

			.b-back_to_top-svg_line {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

	&.m-showed {
		opacity: 1;
		visibility: visible;
	}

	.b-header_stuck & {
		@include media(md-down) {
			opacity: 1;
			visibility: visible;
		}
	}

	.m-product_sticky_bottom & {
		@include media(md-down) {
			bottom: 72px;
		}
	}

	&-icon {
		display: flex;
		height: 10px;
		margin-bottom: 4px;
		transition: height $motion-ease;
	}

	&-svg_line {
		opacity: 0;
		transform: translateY(-15px);
		transition: $motion-fast;
		transition-property: opacity, transform;
	}

	&-copy_small {
		display: none;
	}
}
