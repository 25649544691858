.b-footer_nav {
	@include media(xl) {
		margin-top: 10px;
	}

	@include media(lg-large-up) {
		display: flex;
		margin-right: 50px;
	}

	@include media(md-large-down) {
		@include g-accordion;

		padding: 40px 0 20px;
		width: 100%;
	}

	@include media(md-large) {
		max-width: 700px;
		padding-left: 60px;
		padding-right: 60px;
		width: 100%;
	}

	&-title {
		@include media(md-large-down) {
			@include g-accordion(_title);
		}

		@include media(lg-large-up) {
			display: inline-block;
			font-size: 13px;
			font-weight: 600;
			line-height: 16px;
			min-width: 105px;
			padding-bottom: 8px;
			position: relative;

			.b-icon_chevron {
				display: none;
			}
		}
	}

	&-button {
		@include media(md-large-down) {
			@include g-accordion(_control);

			border-bottom: 1px solid $color-divider;
		}

		@include media(lg-large-up) {
			display: none;
		}
	}

	&-heading {
		@include media(md-large-down) {
			display: none;
		}
	}

	&-content {
		@include media(md-large-down) {
			@include g-accordion(_content);

			&[aria-hidden='false'] {
				@include g-accordion(_content, expanded);
			}
		}

		@include media(lg-large-up) {
			height: auto !important; // stylelint-disable-line
		}
	}

	&-list {
		@include media(md-large-down) {
			@include g-accordion(_content_inner);
		}
	}

	&-item {
		display: block;
		font-size: 11px;
		line-height: 16px;
		margin: 12px 0;
	}

	&-link {
		padding: 4px 0;
	}

	&-column {
		color: $color-link-footer;

		@include media(lg-large-up) {
			flex-basis: grid-span(3, 'lg');
			margin-right: grid-gutter('lg');
		}

		@include media(sm) {
			@include g-accordion(_item);
		}
	}
}
