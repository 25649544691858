.b-product_tile {
	background-color: $color-bg;
	border: 1px solid $color-divider-light;
	border-radius: $global-radius;
	box-shadow: $depth-1;
	height: 100%;
	padding-bottom: 40px;
	position: relative;
	text-align: left;

	.m-ideas & {
		border: none;
		padding-bottom: 0;
	}

	&.m-bundle {
		border: none;
		box-shadow: none;
		display: flex;
		height: auto;
		padding-bottom: 52px;

		@include media(sm) {
			padding-bottom: 99px;
		}
	}

	&-top {
		border-radius: $global-radius $global-radius 0 0;
		overflow: hidden;
		position: relative;
	}

	&-top_wrap {
		position: relative;

		&.m-bundle {
			min-width: 166px;
			padding: 8px 0 12px 8px;
			width: 166px;

			@include media(md-down) {
				min-width: 152px;
				padding: 8px 0 11px 8px;
				width: 152px;
			}
		}

		.m-multy &.m-bundle {
			@include media(md-down) {
				min-width: 132px;
				width: 132px;
			}
		}
	}

	&-image_link {
		display: block;
	}

	&-image {
		@include g-image_container(_container, aspect-ratio(1, 1));

		img {
			@include g-image_container(_img);

			.m-bundle & {
				border-radius: 4px;
			}
		}
	}

	&-quick_view {
		background-color: rgba($color-bg, 0.8);
		border: none;
		bottom: 0;
		font-weight: 500;
		left: 0;
		opacity: 0;
		position: absolute;
		text-transform: initial;
		transition: $motion-fast;
		transition-property: color, opacity;
		width: 100%;

		@include media(md-down) {
			display: none;
		}

		&:hover {
			background-color: rgba($color-bg, 0.8);
		}

		&.m-product_set {
			pointer-events: none;
		}

		&:focus,
		.b-product_tile-top:hover & {
			opacity: 1;
		}
	}

	&-no_available {
		color: $color-error;
	}

	&-info {
		padding: 10px 10px 15px;

		.m-ideas & {
			padding: 10px 15px;
		}

		&.m-bundle {
			padding: 8px 8px 12px;

			@include media(sm) {
				padding: 8px 8px 11px 4px;
			}
		}
	}

	&-title {
		font-size: 15px;
		min-height: 36px;

		.m-ideas & {
			@include line-clamping(2);

			font-size: 13px;
			line-height: 1.5;
		}

		&.m-bundle {
			font-size: 13px;
			line-height: 1.25;
			margin-right: 24px;
			min-height: 32px;
			position: relative;
			z-index: 1;
		}
	}

	&-link {
		@include g-link;

		-webkit-box-orient: vertical;
		display: -webkit-box;
		font-weight: 400;
		-webkit-line-clamp: 2; /* number of lines to show */
		line-height: 1.35;
		overflow: hidden;
		text-decoration: none;
		text-overflow: ellipsis;
		transition: color $motion-fast;
		word-break: break-word;
	}

	&-price {
		font-size: 15px;
		margin-top: 6px;

		&.m-bundle {
			margin-top: 2px;
		}

		.b-price-item {
			&.m-old {
				font-size: 13px;
			}
		}
	}

	&-rating {
		margin-top: 10px;

		&.m-bundle {
			margin-top: 2px;
		}
	}

	&-call_out {
		align-items: center;
		color: $color-fg-promo;
		display: flex;
		margin-top: 8px;
	}

	&-call_out_text {
		border: 1px solid $color-fg-promo;
		border-radius: 2px;
		font-size: 11px;
		padding: 2px 8px;
	}

	&-low_stock {
		background-color: $color-low-stock-bg;
		border-radius: 2px;
		color: $color-low-stock-text;
		display: inline-block;
		font-size: 11px;
		line-height: 1.4;
		margin-top: 9px;
		padding: 3px 9px;

		&.m-cart {
			margin: 0 0 15px;
		}
	}

	&-bottom {
		bottom: 0;
		left: 0;
		position: absolute;
		width: 100%;

		.m-ideas & {
			display: none;
		}

		&.m-bundle {
			display: flex;
			flex-direction: row-reverse;
			gap: 8px;
			margin: 0 8px 12px;
			width: calc(100% - 16px);

			@include media(sm) {
				flex-direction: column;
			}
		}
	}

	&-cta {
		@include g-button_iconed;

		border-radius: 0 0 $global-radius $global-radius;
		border-top: 1px solid $color-divider;
		color: $color-action-obj;
		font-size: 14px;
		font-weight: 600;
		margin: 0 10px;
		min-height: 40px;
		text-align: center;
		width: calc(100% - 20px);

		&.m-product {
			background-color: $color-action-obj;
			border: 0;
			color: $color-text-white;
			margin: 0;
			padding: 5px;
			width: 100%;

			.m-black-friday & {
				background-color: $color-blue2;
				color: $color-black;
			}
		}

		&.m-info {
			font-weight: 500;

			.m-black-friday & {
				color: $color-pink;
				font-weight: 600;
			}
		}

		&.m-idea {
			color: $color-action;
		}

		&.m-idea_quick_view {
			display: none;

			.l-plp_grid.m-three_column & {
				display: flex;
			}
		}

		&.m-idea_link {
			.l-plp_grid.m-three_column & {
				display: none;
			}
		}

		.b-carousel.m-carousel-idea & {
			display: none;
		}
	}

	&-cta_svg {
		margin-right: 7px;
	}

	&-remove {
		@include g-button_iconed;

		background: rgba($color-bg, 0.6);
		border-radius: 0 $global-radius 0 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: $motion-fast;
		transition-property: background-color, color;

		&:hover {
			color: $color-action;
		}
	}

	&-error {
		color: $color-error;
		margin-top: 10px;
	}

	&-description {
		font-size: 11px;
		line-height: 1.5;
		margin-top: 10px;
	}

	&-description_content {
		@include line-clamping($lines: 1);

		line-height: 22px;
		margin-bottom: 10px;
		max-height: 22px;

		&.m-show-more {
			display: block;
			max-height: none;
		}
	}

	&-description_link {
		cursor: pointer;
		font-size: 13px;
		font-weight: 600;
		text-decoration: underline;
	}
}
