.g-content_banner {
	border-radius: $global-radius;
	box-shadow: $depth-1;
	display: grid;
	overflow: hidden;

	&-caption,
	&-picture {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}

	&-caption {
		@include g-section_holder;

		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		width: 100%;

		&.m-caption_left {
			text-align: left;
		}

		&.m-caption_right {
			text-align: right;
		}
	}

	&-title {
		@include g-heading_4;

		@include media(lg-up) {
			font-size: 32px;
		}
	}
}
