.b-continue_shopping {
	color: $color-action-obj;
	font-size: 15px;
	font-weight: 600;
	text-decoration: underline;

	&:hover {
		color: $color-action;
	}
}
