.b-copyright {
	font-size: 11px;
	font-weight: 400;
	line-height: 16px;
	padding-right: 50px;

	@include media(md-down) {
		font-size: 10px;
		margin-bottom: 16px;
		padding-right: 0;
	}

	@include media(sm) {
		text-align: center;
	}
}
