.b-country_selector {
	&-button {
		align-items: center;
		cursor: pointer;
		display: flex;
		min-height: 28px;
		min-width: 124px;
		padding: 3px 7px 3px 3px;
	}

	&-label {
		color: $color-text;
		font-size: 14px;
		line-height: 16px;
	}

	&-arrow_icon {
		margin-left: 7px;
		width: 8px;
	}

	&-icon {
		background-repeat: no-repeat;
		background-size: 100% 49494%;
		border-radius: 1px;
		box-sizing: content-box;
		display: inline-block;
		height: 22px;
		margin-right: 5px;
		overflow: hidden;
		position: relative;
		vertical-align: middle;
		width: 34px;
	}

	&-button.m-init &-icon {
		background-image: url('images/globale/flags-sprite.png');
	}

	&-icon_CH,
	&-icon_NP {box-shadow: none!important}

	&-icon_DZ {background-position:center 0.2287%}

	&-icon_AO {background-position:center 0.4524%}

	&-icon_BJ {background-position:center 0.6721%}

	&-icon_BW {background-position:center 0.8958%}

	&-icon_BF {background-position:center 1.1162%}

	&-icon_BI {background-position:center 1.3379%}

	&-icon_CM {background-position:center 1.5589%}

	&-icon_CV {background-position:center 1.7805%}

	&-icon_CF {background-position:center 2.0047%}

	&-icon_TD {background-position:center 2.2247%}

	&-icon_CD {background-position:left 2.4467%}

	&-icon_DJ {background-position:left 2.6674%}

	&-icon_EG {background-position:center 2.8931%}

	&-icon_GQ {background-position:center 3.1125%}

	&-icon_ER {background-position:left 3.3325%}

	&-icon_ET {background-position:center 3.5542%}

	&-icon_GA {background-position:center 3.7759%}

	&-icon_GM {background-position:center 4.0015%}

	&-icon_GH {background-position:center 4.2229%}

	&-icon_GN {background-position:center 4.441%}

	&-icon_GW {background-position:left 4.66663%}

	&-icon_CI {background-position:center 4.8844%}

	&-icon_KE {background-position:center 5.1061%}

	&-icon_LS {background-position:center 5.3298%}

	&-icon_LR {background-position:left 5.5495%}

	&-icon_LY {background-position:center 5.7712%}

	&-icon_MG {background-position:center 5.994%}

	&-icon_MW {background-position:center 6.2156%}

	&-icon_ML {background-position:center 6.4363%}

	&-icon_MR {background-position:center 6.658%}

	&-icon_MU {background-position:center 6.8805%}

	&-icon_YT {background-position:center 7.1038%}

	&-icon_MA {background-position:center 7.3231%}

	&-icon_MZ {background-position:left 7.5448%}

	&-icon_NA {background-position:left 7.7661%}

	&-icon_NE {background-position:center 7.98937%}

	&-icon_NG {background-position:center 8.2099%}

	&-icon_CG {background-position:center 8.4316%}

	&-icon_RE {background-position:center 8.6533%}

	&-icon_RW {background-position:right 8.875%}

	&-icon_SH {background-position:center 9.0967%}

	&-icon_ST {background-position:center 9.32237%}

	&-icon_SN {background-position:center 9.5426%}

	&-icon_SC {background-position:left 9.7628%}

	&-icon_SL {background-position:center 9.9845%}

	&-icon_SO {background-position:center 10.2052%}

	&-icon_ZA {background-position:left 10.4269%}

	&-icon_SS {background-position:left 10.6486%}

	&-icon_SD {background-position:center 10.8703%}

	&-icon_SR {background-position:center 11.0945%}

	&-icon_SZ {background-position:center 11.3135%}

	&-icon_TG {background-position:left 11.5354%}

	&-icon_TN {background-position:center 11.7593%}

	&-icon_UG {background-position:center 11.9799%}

	&-icon_TZ {background-position:center 12.2005%}

	&-icon_EH {background-position:center 12.4222%}

	&-icon_YE {background-position:center 12.644%}

	&-icon_ZM {background-position:center 12.8664%}

	&-icon_ZW {background-position:left 13.0873%}

	&-icon_AI {background-position:center 13.309%}

	&-icon_AG {background-position:center 13.5307%}

	&-icon_AR {background-position:center 13.7524%}

	&-icon_AW {background-position:left 13.9741%}

	&-icon_BS {background-position:left 14.1958%}

	&-icon_BB {background-position:center 14.4175%}

	&-icon_BQ {background-position:center 14.6415%}

	&-icon_BZ {background-position:center 14.8609%}

	&-icon_BM {background-position:center 15.0826%}

	&-icon_BO {background-position:center 15.306%}

	&-icon_VG {background-position:center 15.528%}

	&-icon_BR {background-position:center 15.7496%}

	&-icon_CA {background-position:center 15.9694%}

	&-icon_KY {background-position:center 16.1911%}

	&-icon_CL {background-position:left 16.4128%}

	&-icon_CO {background-position:left 16.6345%}

	&-icon_KM {background-position:center 16.8562%}

	&-icon_CR {background-position:center 17.0779%}

	&-icon_CU {background-position:left 17.2996%}

	&-icon_CW {background-position:center 17.5213%}

	&-icon_DM {background-position:center 17.743%}

	&-icon_DO {background-position:center 17.968%}

	&-icon_EC {background-position:center 18.1864%}

	&-icon_SV {background-position:center 18.4081%}

	&-icon_FK {background-position:center 18.6298%}

	&-icon_GF {background-position:center 18.8515%}

	&-icon_GL {background-position:left 19.0732%}

	&-icon_GD {background-position:center 19.2987%}

	&-icon_GP {background-position:center 19.518%}

	&-icon_GT {background-position:center 19.7383%}

	&-icon_GY {background-position:center 19.96%}

	&-icon_HT {background-position:center 20.1817%}

	&-icon_HN {background-position:center 20.4034%}

	&-icon_JM {background-position:center 20.6241%}

	&-icon_MQ {background-position:center 20.8468%}

	&-icon_MX {background-position:center 21.0685%}

	&-icon_MS {background-position:center 21.2902%}

	&-icon_NI {background-position:center 21.5119%}

	&-icon_PA {background-position:center 21.7336%}

	&-icon_PY {background-position:center 21.9553%}

	&-icon_PE {background-position:center 22.177%}

	&-icon_PR {background-position:left 22.4002%}

	&-icon_BL {background-position:center 22.6204%}

	&-icon_KN {background-position:center 22.8421%}

	&-icon_LC {background-position:center 23.0638%}

	&-icon_PM {background-position:center 23.2855%}

	&-icon_VC {background-position:center 23.5072%}

	&-icon_SX {background-position:left 23.732%}

	&-icon_TT {background-position:center 23.9506%}

	&-icon_TC {background-position:center 24.1723%}

	&-icon_US {background-position:center 24.394%}

	&-icon_VI {background-position:center 24.6157%}

	&-icon_UY {background-position:left 24.8374%}

	&-icon_VE {background-position:center 25.0591%}

	&-icon_AB {background-position:center 25.279%}

	&-icon_AF {background-position:center 25.5025%}

	&-icon_AZ {background-position:center 25.7242%}

	&-icon_BD {background-position:center 25.9459%}

	&-icon_BT {background-position:center 26.1676%}

	&-icon_BN {background-position:center 26.3885%}

	&-icon_KH {background-position:center 26.611%}

	&-icon_CN {background-position:left 26.8327%}

	&-icon_GE {background-position:center 27.0544%}

	&-icon_HK {background-position:center 27.2761%}

	&-icon_IN {background-position:center 27.4978%}

	&-icon_ID {background-position:center 27.7195%}

	&-icon_JP {background-position:center 27.9412%}

	&-icon_KZ {background-position:center 28.1615%}

	&-icon_LA {background-position:center 28.3846%}

	&-icon_MO {background-position:center 28.6063%}

	&-icon_MY {background-position:center 28.829%}

	&-icon_MV {background-position:center 29.0497%}

	&-icon_MN {background-position:left 29.2714%}

	&-icon_MM {background-position:center 29.4931%}

	&-icon_NP {background-position:left 29.7148%}

	&-icon_KP {background-position:left 29.9365%}

	&-icon_MP {background-position:center 30.1582%}

	&-icon_PW {background-position:center 30.3799%}

	&-icon_PG {background-position:center 30.6016%}

	&-icon_PH {background-position:left 30.8233%}

	&-icon_SG {background-position:left 31.045%}

	&-icon_KR {background-position:center 31.2667%}

	&-icon_LK {background-position:right 31.4884%}

	&-icon_TW {background-position:left 31.7101%}

	&-icon_TJ {background-position:center 31.9318%}

	&-icon_TH {background-position:center 32.1535%}

	&-icon_TL {background-position:left 32.3752%}

	&-icon_TM {background-position:center 32.5969%}

	&-icon_VN {background-position:center 32.8186%}

	&-icon_AL {background-position:center 33.0403%}

	&-icon_AD {background-position:center 33.25975%}

	&-icon_AM {background-position:center 33.4837%}

	&-icon_AT {background-position:center 33.7054%}

	&-icon_BY {background-position:left 33.9271%}

	&-icon_BE {background-position:center 34.1488%}

	&-icon_BA {background-position:center 34.3705%}

	&-icon_BG {background-position:center 34.5922%}

	&-icon_HR {background-position:center 34.8139%}

	&-icon_CY {background-position:center 35.0356%}

	&-icon_CZ {background-position:left 35.2555%}

	&-icon_DK {background-position:center 35.479%}

	&-icon_EE {background-position:center 35.7007%}

	&-icon_FO {background-position:center 35.9224%}

	&-icon_FI {background-position:center 36.1441%}

	&-icon_FR {background-position:center 36.3658%}

	&-icon_DE {background-position:center 36.5875%}

	&-icon_GI {background-position:center 36.8092%}

	&-icon_GR {background-position:left 37.0309%}

	&-icon_HU {background-position:center 37.2526%}

	&-icon_IS {background-position:center 37.4743%}

	&-icon_IE {background-position:center 37.696%}

	&-icon_IM {background-position:center 37.9177%}

	&-icon_IT {background-position:center 38.1394%}

	&-icon_JE {background-position:center 38.3611%}

	&-icon_XK {background-position:center 38.5828%}

	&-icon_LV {background-position:center 38.8045%}

	&-icon_LI {background-position:left 39.0262%}

	&-icon_LT {background-position:center 39.2479%}

	&-icon_LU {background-position:center 39.4696%}

	&-icon_MT {background-position:left 39.6913%}

	&-icon_MD {background-position:center 39.913%}

	&-icon_MC {background-position:center 40.1347%}

	&-icon_ME {background-position:center 40.3564%}

	&-icon_NL {background-position:center 40.5781%}

	&-icon_MK {background-position:center 40.7998%}

	&-icon_NO {background-position:center 41.0215%}

	&-icon_PL {background-position:center 41.2432%}

	&-icon_PT {background-position:center 41.4649%}

	&-icon_RO {background-position:center 41.6866%}

	&-icon_RU {background-position:center 41.9083%}

	&-icon_SM {background-position:center 42.13%}

	&-icon_RS {background-position:center 42.3517%}

	&-icon_SK {background-position:center 42.5734%}

	&-icon_SI {background-position:center 42.7951%}

	&-icon_ES {background-position:left 43.0168%}

	&-icon_SE {background-position:center 43.2385%}

	&-icon_CH {background-position:center 43.4602%}

	&-icon_TR {background-position:center 43.6819%}

	&-icon_UA {background-position:center 43.9036%}

	&-icon_GB {background-position:center 44.1253%}

	&-icon_VA {background-position:right 44.347%}

	&-icon_BH {background-position:center 44.5687%}

	&-icon_IR {background-position:center 44.7904%}

	&-icon_IQ {background-position:center 45.0121%}

	&-icon_IL {background-position:center 45.2338%}

	&-icon_KW {background-position:left 45.4555%}

	&-icon_JO {background-position:left 45.6772%}

	&-icon_KG {background-position:center 45.897%}

	&-icon_LB {background-position:center 46.1206%}

	&-icon_OM {background-position:left 46.3423%}

	&-icon_PK {background-position:center 46.561%}

	&-icon_PS {background-position:center 46.7857%}

	&-icon_QA {background-position:center 47.0074%}

	&-icon_SA {background-position:center 47.2291%}

	&-icon_SY {background-position:center 47.4508%}

	&-icon_AE {background-position:center 47.6725%}

	&-icon_UZ {background-position:left 47.8942%}

	&-icon_AS {background-position:right 48.1159%}

	&-icon_AU {background-position:center 48.3376%}

	&-icon_CX {background-position:center 48.5593%}

	&-icon_CC {background-position:center 48.781%}

	&-icon_CK {background-position:center 49.002%}

	&-icon_FJ {background-position:center 49.2244%}

	&-icon_PF {background-position:center 49.4445%}

	&-icon_GU {background-position:center 49.6678%}

	&-icon_KI {background-position:center 49.8895%}

	&-icon_MH {background-position:left 50.1112%}

	&-icon_FM {background-position:center 50.3329%}

	&-icon_NC {background-position:center 50.5546%}

	&-icon_NZ {background-position:center 50.7763%}

	&-icon_NR {background-position:left 50.998%}

	&-icon_NU {background-position:center 51.2197%}

	&-icon_NF {background-position:center 51.4414%}

	&-icon_WS {background-position:left 51.6631%}

	&-icon_SB {background-position:left 51.8848%}

	&-icon_TK {background-position:center 52.1065%}

	&-icon_TO {background-position:left 52.3282%}

	&-icon_TV {background-position:center 52.5499%}

	&-icon_VU {background-position:left 52.7716%}

	&-icon_WF {background-position:center 52.9933%}
}
