@charset "UTF-8";
/*md
@no-stat

# Breakpoints

## Boilerplate breakpoints

Boilerplate has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Boilerplate is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

*/
/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Palette

This is palette settings for project/brand. It divided into 2 main categories palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwritten on brand level.

*/
/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
/*md
@no-stat

# Rh (Indents rhythm)

This function is designed to keep consistency of vertical and horizontal indents in the project.

Not all values are identical in design, sometimes 20px become 21px, 19px, 22px etc. It does not make
any sense to implement it as is. To keep indents consistent we need to round this values to 4px steps.

Ex: in design 22px / 19px -> rh(5) => 20px; in design 23px -> rh(6) => 24px etc.

This is alternate approach to `$space-md: 10px; $space-lg: 20px;`.

Designers should use the rhythm in his work.

## Usage

```scss
.component {
	@include rh(2); // => 8px
	@include rh(2 4 0); // => 8px 16px 0
}
```
*/
/* stylelint-disable */
/* stylelint-enable */
/*md
@no-stat

# Z-indexes

Z-index is an inherently tricky thing, and maintaining z-index order in a complex layout is difficult.
With different stacking orders and contexts, keeping track of them as their numbers increase can be hard.
<br />
<br />
We use sass function to help manage z-indexes from single place.
The most important requirement of this technique is sticking to it.
Any rogue hard-coded z-index values could compromise the integrity of those derived from your list.

## Usage

**We don't use hardcoded integer `z-index` values. Instead, we use indexes from the map `$z-indexes`**

### 1. Set up `$z-indexes` map
```scss
$z-indexes: (
    components: (
        component_name: (),
        checkbox: (
            before: (),
            after: (),
            icon: (),
        )
    ),
    content: (),
    popup-menu: ()
);
```

### 2. Add values in SCSS classes using `z()` function

#### Global components
```scss
.b-components { z-index: z(components); }
.b-content { z-index: z(content); }
.b-pop_up-menu { z-index: z(popup-menu); }
```

#### Inside a component
```scss
.b-component_name { z-index: z(components, component_name); }
.b-checkbox {
    &-before { z-index: z(components, checkbox, before); }
    &-after { z-index: z(components, checkbox, after); }
    &-icon { z-index: z(components, checkbox, icon); }
}
```

### 3. Get resulting CSS
```scss
.b-components { z-index: 1; }
.b-content { z-index: 2; }
.b-pop_up-menu { z-index: 3; }

.b-component_name { z-index: 1; }
.b-checkbox-before { z-index: 1; }
.b-checkbox-after { z-index: 2; }
.b-checkbox-icon { z-index: 3; }
```

*/
/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. For that, we need to add a new grid name to the `$grids` map with settings.

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 16px 9px),
		grid-margin:  ('xl': 88px, 'lg': 24px, 'md': 32px, 'sm': 15px),
	)
);
```

### Grid-span configuration

Please see details [grid-span](01-core-functions-grid-span.html)

## Work with grids

### Development approaches

#### 1. Using `g-grid` mixin

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.). Please see [grid-span](01-core-functions-grid-span.html) details.

### Get gaps / margin / column span

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.

### Examples of usage

Please see [ready-made tools](05-blocks-guide-l-cols.html) details.

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# adjust-color-to-bg

This function used to adjust color depending on provided background color. It use
luminance human persived criteria as breakpoint for colors
[See more details](http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef).

It is especially useful for crating flexible themes.

## Arguments

```
$backgroundColor - bg color
$colorInverse - color if bg is dark. If not provided would return $color-white
$colorNormal - color if bg is light. If not provided would return $color-text

adjust-color-to-bg($backgroundColor, $colorInverse, $colorNormal)
```

## Usage

```scss
.component {
	color: adjust-color-to-bg($color-bg-header-line-1);

	// => results default 'white' if background dark
	// => results default 'black' if background is light
}

.component-custom-inverse-color {
	color: adjust-color-to-bg($color-bg-footer, grey);

	// => results 'grey' if background dark
	// => results default 'black' if background is light
}

.component-all-custom-colors {
	color: adjust-color-to-bg($color-bg-footer, green, red);

	// => result 'green' if background dark
	// => result 'red' if background is light
}
```

Based on Hugo Giraudel [work](https://css-tricks.com/snippets/sass/luminance-color-function/)
*/
/*md
@no-stat

# grid-span

`grid-span` function returns value which could be used as **width, max-witdth, flex-basis, etc.**

### Parameters
```scss
@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default')
```

## Examples

### Flex-basis example

```scss
.b-grid {
	display: flex;

	.b-grid__item {
		flex-basis: grid-span($column: 3);
	}
}
```

### Floated items example

```scss
.b-grid {
	.b-grid__item {
		float: left;
		width: grid-span($column: 2);
	}
}
```

### Inline-block items example

```scss
.b-grid {
	.b-grid__item {
		display: inline-block;
		max-width: grid-span($column: 2);
	}
}
```

*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 0.5625%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behaviour of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# Line clamping

This mixin is useful for truncated text

Here is a list of parameters you can use:

* lines - the number of rows to display

## Usage

```scss
.component {
	@include line-clamping($lines: 3);
}

*/
/*md

# g-button

Designed to provide same styles of buttons across different components.
It is possible to use with `<button>` or `<a>` elements

## First type button

```html_example
<button type="submit" class="b-button">
	Sign in
</button>
```

## First type disabled button

```html_example
<button type="submit" class="b-button m-disabled">
	Sign in
</button>
```

## First type, full width button

```html_example
<button type="submit" class="b-button m-width_full">
	Sign in
</button>
```

## Second type button

```html_example
<button type="submit" class="b-button m-outline">
	Sign in
</button>
```

## Second type disabled button

```html_example
<button type="submit" class="b-button m-outline m-disabled">
	Sign in
</button>
```

## Second type, full width button

```html_example
<button type="submit" class="b-button m-outline m-width_full">
	Sign in
</button>
```

## Link button

```html_example
<button type="submit" class="b-button m-link">
	Sign in
</button>
```

## Medium height Link button

```html_example
<button type="submit" class="b-button m-link m-medium">
	Sign in
</button>
```

## Small height Link button

```html_example
<button type="submit" class="b-button m-link m-small">
	Sign in
</button>
```

*/
/*md

# g-button_iconed

Designed to provide same styles of buttons that contain only icon (without any text)
across different components without explicit CSS class.

It is used for header menubar icons, hamburger menu items and dialog close button.

```scss
.b-dialog {
	// ...
	&-close {
		@include g-button_iconed;
	}
}
```
*/
/*md

# g-radio

The component is generally used for choosing item which includes in the radio group.

## Usage

Only one g-radio in a given group can be selected at the same time.

If user selects one option in the list that other options come to unselected.

`g-radio` has states: unchecked, checked, hover, disabled and invalid.

## Unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-2" class="b-radio-input"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-2">Some text</label>
</div>
```

## Checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-3" class="b-radio-input" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-3">Some text</label>
</div>
```

## Disabled unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-4" class="b-radio-input" disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-4">Some text</label>
</div>
```

## Disabled checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-5" class="b-radio-input" checked disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-5">Some text</label>
</div>
```

## Invalid unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-6" class="b-radio-input m-invalid"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-6">Some text</label>
</div>
```

## Invalid checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-7" class="b-radio-input m-invalid" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-7">Some text</label>
</div>
```

## Customization by SCSS

Radio button styles that used in several component.

Designed to use same style of radio in different components
ex: b-radio, b-payment_option, b-shipping_option, b-stores etc.

It provide styles only for icon element based on input node.

```scss_example
.b-option_switch {
	// ...
	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		.b-option_switch-input:active + & {
			@include g-radio(_icon, m-active);
		}

		.b-option_switch-input:hover + & {
			@include g-radio(_icon, m-hover);
		}

		.b-option_switch-input:checked + & {
			@include g-radio(_icon, m-checked);
		}

		.b-option_switch-input[disabled] + & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-checkbox

This component allows user to choose between two mutually exclusive state (checked or unchecked).

## Usage

A `g-checkbox` is used for select or unselect action items.

It is always tied to `<label>` that describes two opposite states.

The component usually points to choose settings or preferences.

`g-checkbox` has states (unchecked, checked, hover, disabled and invalid).

## Unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-1" />
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-1">Some text</label>
</div>
```

## Checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-2" checked/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-2">Some text</label>
</div>
```

## Disabled unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-3" disabled/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-3">Some text</label>
</div>
```

## Disabled checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-4" checked disabled/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-4">Some text</label>
</div>
```

## Invalid unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-5"/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-5">Some text</label>
</div>
```

## Invalid checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-6" checked/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-6">Some text</label>
</div>

```

## Customization by SCSS

Checkbox styles that used in several component.

Designed to use same style of checkbox in different components without additional CSS class.
ex: `b-checkbox`, `b-refinement_checkbox`, `b-account_preference` etc.

It provide styles only for icon element based on SVG.

```scss
.b-refinement_checkbox {
	// ...
	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox.m-checked & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox.m-disabled & {
			@include g-checkbox(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-spinner

Global spinner component applied to different blocks that fetch data.

Designed to use same style of spinner in different components and on particular breakpoints.
Ex: b-minicart_popup, b-suggestions, b-plp_grid, b-product_details, b-cart etc.

```scss
.b-product_gallery {
	&.m-loading_long::before {
		@include g-spinner();
	}
	// ...
}
```
*/
/*md

# g-link

Designed to provide same styles of text-type links across different components.

## Usage

```scss
.component-link {
	@include g-link;
}

.component-link {
	@include g-link(inherit);
}
```

*/
/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/
/*md

# g-icon_gradient

Designed to provide same styles of icon gradient across different components without
explicit CSS class.

```scss
.b-user_greeting {
	// ...
	&-icon {
		@include g-icon_gradient;
	}
}
```
*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-snap_scroll

Snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

Designed to use same style of backdrop and logic in different components and on particular breakpoints.
Ex: b-dialog, b-menu_panel, b-refinements_panel, b-minicart_panel etc.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container.

It could be used not only for standalone blocks, but also for page layouts.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold header of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/
/*md

# g-section_holder_footer

Since footer is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold footer of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-footer-inner {
	background: green;

	&-footer {
		@include g-section_holder_footer;
	}
}
```
*/
/*md

# g-heading_*

Some basic simple typography applied to different UI components.

This covers only very basic cases and could be extended.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: rh(8);
	}
}
```
*/
/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allow or dissallow toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allow or dissallow multiple open items
```

## Simple accordion example

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Accordion with multiple open items

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="true"
    data-open-first="false"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Init accordion on sm, md & lg devices

### Attributes

```
.b-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.b-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

```html_example
<div
    data-id="descriptions"
    data-widget.sm.md.lg="accordion"
    data-allow-toggle="true"
    data-open-first="true"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss
.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-title {
		@include g-accordion(_control);
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
```
*/
/*md

# g-grid

Grid layout component based on CSS grid.

It is designed to easy use project defined grid into components where CSS grid is
applicable.

```scss
.b-grid {
	@include g-grid();

	.b-columns__item {
		@include media(lg-up) {
			grid-column: 2 / span 4;
			grid-row: 1 / 2;
		}

		@include media(md-down) {
			grid-column: grid-start / span 7;
		}
	}
}
```
*/
/*md
@no-stat

# Header item

This mixin is useful for header items with icon

## Usage

```scss
.component {
	@include g-header_item();
}

*/
/*md
@no-stat

# Header item element in Hamburger Menu

## Usage

```scss
.component {
	@include g-header_item_menu;
}

*/
/*md

# g-title

Designed to provide same styles of title across different components.

## Usage

```scss
.component {
	@include g-title(true);
}
```

*/
/*md

# g-loader

Designed to provide same styles of loading indicator across different components.

## Usage

```scss
.component {
	@include g-loader;
}
```

*/
@media print {
  @page {
    margin: 10mm;
    padding: 0;
    size: a4;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .b-hide_print {
    display: none !important;
  }
  .b-header_utility,
  .l-header,
  .b-menu_panel,
  .b-footer {
    display: none;
  }
}
/*md

# Normalize forms

This package address differences of default styling through all major browsers.

Best practice not include this package *globally* until we use HTML-tags for UI components.

This styles are based on N.Galaher [normalize.css](https://necolas.github.io/normalize.css/)

*/
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

input[type=button], input[type=submit], input[type=reset] {
  -webkit-appearance: button;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
input[type=checkbox] {
  vertical-align: baseline;
}

button[disabled],
input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: none;
  padding: 0;
}
button:-moz-focusring,
input:-moz-focusring {
  outline: none !important;
}

input::-webkit-inner-spin-button {
  display: none;
}

input::-ms-clear {
  display: none;
}

.g-squiggle {
  position: relative;
  z-index: 0;
}
.g-squiggle-wrap {
  position: relative;
}
.g-squiggle-phrase.m-plain {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.g-squiggle-phrase.m-graphic {
  opacity: 0;
  visibility: hidden;
}
.g-squiggle-icon {
  color: var(--color-squiggle, #F6C052);
  left: 50%;
  position: absolute;
  top: 40%;
  transform: translate(-50%, 0);
  z-index: -1;
}

/*md
@no-stat

# global-animations

This component is designed to store all global animation and include it only once in single place
so all other components could reuse this animations.
*/
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animation-chevron-up {
  0% {
    transform: translateY(-50%) rotate(46deg);
    width: 9px;
  }
  50% {
    transform: translate(-20%, -50%) rotate(0deg);
    width: 7px;
  }
  100% {
    transform: translateY(-50%) rotate(-46deg);
    width: 9px;
  }
}
@keyframes animation-chevron-down {
  0% {
    transform: translateY(-50%) rotate(-46deg);
    width: 9px;
  }
  50% {
    transform: translate(-20%, -50%) rotate(0deg);
    width: 7px;
  }
  100% {
    transform: translateY(-50%) rotate(46deg);
    width: 9px;
  }
}
@keyframes animation-svg-chevron-down {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(180deg);
  }
}
@keyframes animation-svg-chevron-up {
  from {
    transform: rotateX(180deg);
  }
  to {
    transform: rotateX(0deg);
  }
}
@keyframes thumbs-zoom {
  0% {
    transform: translateY(35px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-from-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes dialog-openeing {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes hero-carousel-progress {
  from {
    stroke-dashoffset: 104;
  }
  to {
    stroke-dashoffset: 1;
  }
}
@keyframes loader {
  0%, 100% {
    box-shadow: 0 -2.6em 0 0 #230A16, 1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2), 2.5em 0 0 0 rgba(35, 10, 22, 0.2), 1.75em 1.75em 0 0 rgba(35, 10, 22, 0.2), 0 2.5em 0 0 rgba(35, 10, 22, 0.2), -1.8em 1.8em 0 0 rgba(35, 10, 22, 0.2), -2.6em 0 0 0 rgba(35, 10, 22, 0.5), -1.8em -1.8em 0 0 rgba(35, 10, 22, 0.7);
  }
  12.5% {
    box-shadow: 0 -2.6em 0 0 rgba(35, 10, 22, 0.7), 1.8em -1.8em 0 0 #230A16, 2.5em 0 0 0 rgba(35, 10, 22, 0.2), 1.75em 1.75em 0 0 rgba(35, 10, 22, 0.2), 0 2.5em 0 0 rgba(35, 10, 22, 0.2), -1.8em 1.8em 0 0 rgba(35, 10, 22, 0.2), -2.6em 0 0 0 rgba(35, 10, 22, 0.2), -1.8em -1.8em 0 0 rgba(35, 10, 22, 0.5);
  }
  25% {
    box-shadow: 0 -2.6em 0 0 rgba(35, 10, 22, 0.5), 1.8em -1.8em 0 0 rgba(35, 10, 22, 0.7), 2.5em 0 0 0 #230A16, 1.75em 1.75em 0 0 rgba(35, 10, 22, 0.2), 0 2.5em 0 0 rgba(35, 10, 22, 0.2), -1.8em 1.8em 0 0 rgba(35, 10, 22, 0.2), -2.6em 0 0 0 rgba(35, 10, 22, 0.2), -1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2);
  }
  37.5% {
    box-shadow: 0 -2.6em 0 0 rgba(35, 10, 22, 0.2), 1.8em -1.8em 0 0 rgba(35, 10, 22, 0.5), 2.5em 0 0 0 rgba(35, 10, 22, 0.7), 1.75em 1.75em 0 0 #230A16, 0 2.5em 0 0 rgba(35, 10, 22, 0.2), -1.8em 1.8em 0 0 rgba(35, 10, 22, 0.2), -2.6em 0 0 0 rgba(35, 10, 22, 0.2), -1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2);
  }
  50% {
    box-shadow: 0 -2.6em 0 0 rgba(35, 10, 22, 0.2), 1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2), 2.5em 0 0 0 rgba(35, 10, 22, 0.5), 1.75em 1.75em 0 0 rgba(35, 10, 22, 0.7), 0 2.5em 0 0 #230A16, -1.8em 1.8em 0 0 rgba(35, 10, 22, 0.2), -2.6em 0 0 0 rgba(35, 10, 22, 0.2), -1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2);
  }
  62.5% {
    box-shadow: 0 -2.6em 0 0 rgba(35, 10, 22, 0.2), 1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2), 2.5em 0 0 0 rgba(35, 10, 22, 0.2), 1.75em 1.75em 0 0 rgba(35, 10, 22, 0.5), 0 2.5em 0 0 rgba(35, 10, 22, 0.7), -1.8em 1.8em 0 0 #230A16, -2.6em 0 0 0 rgba(35, 10, 22, 0.2), -1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2);
  }
  75% {
    box-shadow: 0 -2.6em 0 0 rgba(35, 10, 22, 0.2), 1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2), 2.5em 0 0 0 rgba(35, 10, 22, 0.2), 1.75em 1.75em 0 0 rgba(35, 10, 22, 0.2), 0 2.5em 0 0 rgba(35, 10, 22, 0.5), -1.8em 1.8em 0 0 rgba(35, 10, 22, 0.7), -2.6em 0 0 0 #230A16, -1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2);
  }
  87.5% {
    box-shadow: 0 -2.6em 0 0 rgba(35, 10, 22, 0.2), 1.8em -1.8em 0 0 rgba(35, 10, 22, 0.2), 2.5em 0 0 0 rgba(35, 10, 22, 0.2), 1.75em 1.75em 0 0 rgba(35, 10, 22, 0.2), 0 2.5em 0 0 rgba(35, 10, 22, 0.2), -1.8em 1.8em 0 0 rgba(35, 10, 22, 0.5), -2.6em 0 0 0 rgba(35, 10, 22, 0.7), -1.8em -1.8em 0 0 #230A16;
  }
}
@media (prefers-reduced-motion) {
  * {
    animation: none !important;
    transition: none !important;
  }
}
/*md

# b-button

Please see [g-button](./02-components-g-button.html) for details.

*/
.b-button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  max-width: 100%;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, color, border;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  background: #45132C;
  border: 1px solid #45132C;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  padding: 0 10px;
}
@media not all and (pointer: coarse) {
  .b-button:hover {
    background: #230A16;
    border-color: #230A16;
    text-decoration: none;
  }
}
.b-button.m-outline {
  background-color: #FFFFFF;
  border-color: #D2D2D2;
  color: #45132C;
}
@media not all and (pointer: coarse) {
  .b-button.m-outline:hover {
    background: #45132C;
    border-color: #45132C;
    color: #FFFFFF;
  }
}
.b-button.m-link {
  cursor: pointer;
  text-decoration: underline;
  background: transparent;
  border-color: transparent;
  color: inherit;
  font-weight: 600;
  letter-spacing: 0;
  padding: 0;
}
@media not all and (pointer: coarse) {
  .b-button.m-link:hover {
    color: #C23D74;
  }
}
.b-button.m-small {
  border-radius: 35px;
  font-size: 13px;
  height: 33px;
  padding: 0 20px;
}
.b-button.m-large {
  padding: 13px 26px;
}
.b-button.m-width_full {
  width: 100%;
}
.b-button.m-sale {
  background-color: #A42015;
  border-color: #A42015;
}
@media not all and (pointer: coarse) {
  .b-button.m-sale:hover {
    background: #52100B;
    border-color: #52100B;
  }
}
.b-button.m-pills {
  background-color: #398484;
  border-color: #398484;
}
@media not all and (pointer: coarse) {
  .b-button.m-pills:hover {
    background: #1C4242;
    border-color: #1C4242;
  }
}
.b-button.m-icon_only {
  padding-left: 15px;
  padding-right: 15px;
}
.b-button-icon {
  margin-right: 10px;
}
.b-button.m-icon_only .b-button-icon {
  margin-right: 0;
}
.b-button.m-promo {
  background-color: #C23D74;
  border-color: #C23D74;
  padding: 0 25px;
}
.b-button.m-lozenge {
  background: none;
  border: 1px solid #45132C;
  border-radius: 4px;
  color: #45132C;
  height: initial;
  padding: 12px;
}
.b-button.m-tertiary {
  background-color: #398484;
  border-color: #398484;
}
.b-button:disabled, .b-button.m-disabled {
  background-color: #D2D2D2;
  border-color: #D2D2D2;
  color: #757575;
  pointer-events: none;
}

.b-back_to_top {
  align-items: center;
  background: #FFFFFF;
  bottom: 10px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  color: #45132C;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  line-height: 1.5;
  min-height: 50px;
  min-width: 50px;
  opacity: 0;
  padding: 10px 10px 4px;
  pointer-events: auto;
  position: fixed;
  right: 10px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: opacity, visibility, box-shadow;
  visibility: hidden;
  z-index: 2;
}
@media not all and (pointer: coarse) {
  .b-back_to_top:hover {
    background: #45132C;
    box-shadow: none;
    color: #FFFFFF;
    text-decoration: none;
  }
  .b-back_to_top:hover .b-back_to_top-icon {
    height: 17px;
  }
  .b-back_to_top:hover .b-back_to_top-svg_line {
    opacity: 1;
    transform: translateY(0);
  }
}
.b-back_to_top.m-showed {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 1023px) {
  .b-header_stuck .b-back_to_top {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 1023px) {
  .m-product_sticky_bottom .b-back_to_top {
    bottom: 72px;
  }
}
.b-back_to_top-icon {
  display: flex;
  height: 10px;
  margin-bottom: 4px;
  transition: height cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.b-back_to_top-svg_line {
  opacity: 0;
  transform: translateY(-15px);
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: opacity, transform;
}
.b-back_to_top-copy_small {
  display: none;
}

/*md

# b-global_alerts

This alerts used to notify blind user about dynamic changes on the page. Ex: load more products,
filters applied, sorting applied etc. To meet basic a11y requirements.

It is recommended to not hide this alerts and make them visible - it ease of testing
and fix as soon as it broken.

This messages should not have prominent visual styles.

```html_example
<div class="b-global_alerts">
    <div
        class="b-global_alerts-item"
        role="alert"
    >
        Filters applied
    </div>
</div>
```

## What this alerts covers

This is middle level between page and components.

Please see more [info](https://confluence.ontrq.com/pages/viewpage.action?pageId=228864950)

## Visually hide alerts

It is possible to hide this alerts visually, but it is not recommended.

We got special modificator to do so - `m-visually_hidden`. Please see hideAccessibilityAlerts site pref to more info.

*/
.b-global_alerts {
  bottom: 20px;
  left: 50%;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  z-index: 15;
}
.b-global_alerts.m-visually_hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-global_alerts-item {
  animation: slide-from-bottom cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  background-color: #F7E7EE;
  color: #2F2F2F;
  cursor: default;
  display: inline-block !important;
  font-size: 15px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 400px;
  min-width: 206px;
  padding: 10px 40px;
}
.b-global_alerts-item[hidden] {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  pointer-events: none;
}
.b-global_alerts .m-notification {
  background: #CFDFA6;
  border-radius: 10px;
  font-weight: 600;
  line-height: 1.25;
  max-width: 152px;
  min-width: auto;
  padding: 13px 16px;
}
.b-global_alerts .m-notification::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMiAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMS41NjMxIiByPSIxMSIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTkuMzMzMzcgMTYuNTYzMUM5LjEzMzM3IDE2LjU2MzEgOS4wMDAwNCAxNi40OTY0IDguODY2NzEgMTYuMzYzMUw1LjUzMzM3IDEzLjAyOThDNS4yNjY3MSAxMi43NjMxIDUuMjY2NzEgMTIuMzYzMSA1LjUzMzM3IDEyLjA5NjRDNS44MDAwNCAxMS44Mjk4IDYuMjAwMDQgMTEuODI5OCA2LjQ2NjcxIDEyLjA5NjRMOS4zMzMzNyAxNC45NjMxTDE2LjIgOC4wOTY0MkMxNi40NjY3IDcuODI5NzYgMTYuODY2NyA3LjgyOTc2IDE3LjEzMzQgOC4wOTY0MkMxNy40IDguMzYzMDkgMTcuNCA4Ljc2MzA5IDE3LjEzMzQgOS4wMjk3Nkw5LjgwMDA0IDE2LjM2MzFDOS42NjY3MSAxNi40OTY0IDkuNTMzMzcgMTYuNTYzMSA5LjMzMzM3IDE2LjU2MzFaIiBmaWxsPSIjQTFCRDNFIi8+Cjwvc3ZnPg==");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 22px;
  content: "";
  display: block;
  height: 30px;
}
.b-global_alerts .m-notification_copy::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMyIgdmlld0JveD0iMCAwIDIyIDIzIiBmaWxsPSJub25lIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMS4wNjMxIiByPSIxMSIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTUuNzgwNTIgMy44NjI4NUM0LjY5MyAzLjg2Mjg1IDMuODAwNzggNC43NTUwNSAzLjgwMDc4IDUuODQyNTlWMTMuMzIzQzMuODAwNzggMTQuNDEwNSA0LjY5Mjk4IDE1LjMwMjcgNS43ODA1MiAxNS4zMDI3SDEzLjI2MDlDMTQuMzQ4NSAxNS4zMDI3IDE1LjI0MDcgMTQuNDEwNSAxNS4yNDA3IDEzLjMyM1Y1Ljg0MjU5QzE1LjI0MDcgNC43NTUwNyAxNC4zNDg1IDMuODYyODUgMTMuMjYwOSAzLjg2Mjg1SDUuNzgwNTJaTTUuNzgwNTIgNC45NDE3M0gxMy4yNjA5QzEzLjc2ODggNC45NDE3MyAxNC4xNTkxIDUuMzM0NyAxNC4xNTkxIDUuODQyNTlWMTMuMzIzQzE0LjE1OTEgMTMuODMwOSAxMy43Njg4IDE0LjIyMTIgMTMuMjYwOSAxNC4yMjEySDUuNzgwNTJDNS4yNzI2MSAxNC4yMjEyIDQuODc5NjYgMTMuODMwOSA0Ljg3OTY2IDEzLjMyM1Y1Ljg0MjU5QzQuODc5NjYgNS4zMzQ2OCA1LjI3MjYzIDQuOTQxNzMgNS43ODA1MiA0Ljk0MTczWiIgZmlsbD0iI0NGREZBNiIvPjxwYXRoIGQ9Ik0xNC42OTgyIDYuODI0NzdDMTQuNTU1MiA2LjgyNDg2IDE0LjQxOCA2Ljg4MTc1IDE0LjMxNjkgNi45ODI5NEMxNC4yMTU3IDcuMDg0MDQgMTQuMTU4OCA3LjIyMTIyIDE0LjE1ODcgNy4zNjQyQzE0LjE1ODggNy41MDcyOCAxNC4yMTU3IDcuNjQ0MzcgMTQuMzE2OSA3Ljc0NTQ3QzE0LjQxOCA3Ljg0NjY2IDE0LjU1NTIgNy45MDM1NSAxNC42OTgyIDcuOTAzNjRIMTYuMjIwOEMxNi43MjQzIDcuOTAzNjQgMTcuMTE5IDguMzAxMDcgMTcuMTE5IDguODA0NVYxNi4yODQ5QzE3LjExOSAxNi43ODg0IDE2LjcyNDIgMTcuMTgzMSAxNi4yMjA4IDE3LjE4MzFIOC43NDAzOUM4LjIzNjk0IDE3LjE4MzEgNy44Mzk1MyAxNi43ODgzIDcuODM5NTMgMTYuMjg0OUw3LjgzOTYyIDE0Ljc2NDlDNy44NDAxNiAxNC42MjE0IDcuNzgzNjIgMTQuNDgzNSA3LjY4MjQ0IDE0LjM4MThDNy41ODExNiAxNC4yODAxIDcuNDQzNjMgMTQuMjIyOSA3LjMwMDE4IDE0LjIyMjhDNy4xNTY2NiAxNC4yMjI5IDcuMDE5MTMgMTQuMjgwMSA2LjkxNzkzIDE0LjM4MThDNi44MTY3NCAxNC40ODM1IDYuNzYwMTIgMTQuNjIxNCA2Ljc2MDc1IDE0Ljc2NDlWMTYuMjg0OUM2Ljc2MDc1IDE3LjM3MiA3LjY1MzQgMTguMjY0NyA4Ljc0MDQ4IDE4LjI2NDdIMTYuMjIwOUMxNy4zMDggMTguMjY0NyAxOC4yMDA2IDE3LjM3MiAxOC4yMDA2IDE2LjI4NDlWOC44MDQ1QzE4LjIwMDYgNy43MTc0MiAxNy4zMDggNi44MjQ3NyAxNi4yMjA5IDYuODI0NzdIMTQuNjk4MloiIGZpbGw9IiNDRkRGQTYiLz4KCjwvc3ZnPg==");
}

/*md

# b-highlighter

Focus highlighter CSS part. It draw focus ring over focused element.

We have two types of focus highlighting. In case if custom highlighter is not initialized will be shown default outline highlighter.

### Default outline highlighter
```html_example
<br>
<div>
	<input class="b-input m-focused" type="text" placeholder="Default outline example" style="width:240px;">
</div>
<br>
```

### Custom highlighter
```html_example
<br>
<div style="position:relative;">
	<div class="b-highlighter_inited">
		<input class="b-input" type="text" placeholder="Custom highlighter example" style="width:240px;">
	</div>
	<div class="b-highlighter m-visible" style="top:-5px; left:-4px; width:241px; height:50px;"></div>
</div>
<br>
```
*/
.b-highlighter {
  border: 4px solid transparent;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 #C23D74;
  box-sizing: content-box;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(1.2);
  transition-duration: 0.15s;
  transition-property: top, left, width, height, visibility, transform, opacity;
  transition-timing-function: cubic-bezier(0, 0.2, 0, 1);
  visibility: hidden;
  z-index: 16;
}
.b-highlighter.m-visible {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}
.b-highlighter.m-hurry {
  transition-duration: 0.1s;
}

*:focus,
.m-focused {
  outline: 3px solid hsl(213, 75%, 85%) !important;
}

.b-highlighter_inited *:focus {
  outline: none !important;
}

.b-continue_shopping {
  color: #45132C;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
}
.b-continue_shopping:hover {
  color: #C23D74;
}

.b-heading_1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.25;
}

.b-heading_2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;
}

.b-heading_3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
}

.b-heading_4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
}

.b-heading_4_1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
}

.b-heading_5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
}

.b-heading_6 {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.25;
}

.b-body_text-11 {
  font-size: 11px;
  line-height: 150%;
}
.b-body_text-13 {
  font-size: 13px;
  line-height: 150%;
}
.b-body_text-15 {
  font-size: 15px;
  line-height: 150%;
}
.b-body_text-15_2 {
  font-size: 15px;
  line-height: 125%;
}
.b-body_text-17 {
  font-size: 17px;
  line-height: 125%;
}

.m-regular {
  font-weight: 400;
}

.m-medium {
  font-weight: 500;
}

.m-semibold {
  font-weight: 600;
}

.m-standalone_link {
  color: #45132C;
  font-weight: 600;
  text-decoration: none;
}
.m-standalone_link:hover, .b-text_block .m-standalone_link:hover {
  text-decoration: underline;
}

/*md

# b-link

```html_example
<a class="b-link" href="../customer-services/cp-contact-us.html">our A-Z directory of brands</a>
```
*/
.b-link {
  cursor: pointer;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  .b-link:hover {
    color: #C23D74;
  }
}

/*md

# b-link_phone

Phone link with **tel:[number]** scheme

```html_example
<a href="tel:1-888-222-3380" class="b-link_phone">1-888-222-3380</a>
```
*/
.b-link_phone {
  text-decoration: underline;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  white-space: nowrap;
}
.b-link_phone:hover {
  color: #C23D74;
  text-decoration: none;
}
.b-link_phone.m-simple {
  text-decoration: none;
}
.b-link_phone-icon {
  display: inline-flex;
  margin-right: 10px;
  min-width: 15px;
  vertical-align: middle;
}

/*md

# b-link_email

Email link with **mailto:[email]** scheme

```html_example
<a href="mailto:customerservice@boilerplate.com" class="b-link_email">customerservice@boilerplate.com</a>
```
*/
.b-link_email {
  text-decoration: underline;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  white-space: nowrap;
}
.b-link_email:hover {
  color: #C23D74;
  text-decoration: none;
}
.b-link_email.m-simple {
  text-decoration: none;
}
.b-link_email-icon {
  display: inline-flex;
  margin-right: 10px;
  min-width: 15px;
  vertical-align: middle;
}

/*md

# b-dialog

### We have two types of dialog: *Usual dialog* and *Search suggestions dialog*

The usual dialog have different modifiers **m-*** which depend on the width of the dialog

```html_example
<div>
	<button class="b-button m-outline"
			type="button"
			data-widget="emitBusEvent"
			data-bus-event-type="dialog.show"
			data-event-click.prevent="emitBusEvent"
			data-modal-config="{&quot;content&quot;: &quot; &quot;}"
	>
		Show usual dialog
	</button>
	<button class="b-button m-outline" data-widget="emitBusEvent" data-bus-event-type="page.show.searchbox" data-event-click.prevent="emitBusEvent">
		Show search suggestions dialog
	</button>
</div>
<div data-widget="globalModal" data-disable-rendering="false">
	<div class="b-dialog" data-ref="container" hidden></div>
	<script type="template/mustache" data-ref="template">
		<div data-ref="container" class="b-dialog m-reset_password">
			<div class="b-dialog-window m-top_dialog" role="dialog" data-ref="dialog" aria-modal="true">
				<div class="b-dialog-header">
					<h2 class="b-dialog-title" id="forget-password-form-title">Forgot password?</h2>
					<div data-tau="forgot_password_close">
						<button class="b-dialog-close" type="button" data-dismiss="modal" data-event-click.prevent="cancel">
							<svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18" focusable="false">
								<use href="#close"></use>
							</svg>
						</button>
					</div>
				</div>
				<div class="b-dialog-body">
					<p class="b-form_section">Don't worry - it's easily done! Just enter your email address below and we'll send you a link to reset your password.</p>
					<form class="reset-password-form" action="" method="POST">
						<div data-widget="inputEmail" class="b-form_section m-required">
							<label class="b-form_section-label">Email</label>
							<input type="email" class="b-input">
						</div>
						<div class="b-dialog-footer m-actions">
							<button type="submit" class="b-button m-width_full">Reset password</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</script>
</div>
<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
	<symbol id="close">
		<path fill="currentColor" d="m0.515,0.515c0.39,-0.39 1.023,-0.39 1.414,0l7.071,7.07l7.071,-7.07c0.39,-0.39 1.024,-0.39 1.414,0c0.39,0.39 0.39,1.023 0,1.414l-7.07,7.071l7.07,7.071c0.39,0.39 0.39,1.024 0,1.414c-0.39,0.39 -1.023,0.39 -1.414,0l-7.071,-7.07l-7.071,7.07c-0.39,0.39 -1.024,0.39 -1.414,0c-0.39,-0.39 -0.39,-1.023 0,-1.414l7.07,-7.071l-7.07,-7.071c-0.39,-0.39 -0.39,-1.024 0,-1.414z"></path>
	</symbol>
</svg>

<div data-widget="searchBox" data-disable-rendering="true">
	<div data-ref="container" class="b-dialog m-search_suggestions" data-event-click.self="closeModal">
		<div class="b-search_dialog" data-ref="dialog" role="dialog">
			<div class="b-search_dialog-inner">
				<div class="b-search_dialog-inner_top">
					<div class="b-search_dialog-inner_top_content">
						<div class="b-search_dialog-form_wrap">
							<form role="search" method="get" name="simpleSearch" class="b-search_input">
								<button class="b-search_input-submit" type="submit">
									<svg width="23" height="23" viewBox="0 0 27 28" focusable="false">
										<path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="2" d="M19.484 19.984c4.1-4.1 4.066-10.784-.078-14.927C15.263.913 8.58.879 4.48 4.979c-4.1 4.1-4.066 10.784.078 14.927 4.143 4.144 10.826 4.178 14.927.078zm-.567-.355l7.239 7.494"></path>
									</svg>
								</button>
								<input role="combobox" id="header-search-input" class="b-search_input-input" type="search" name="q" value="" placeholder="Search">
							</form>
							<button type="button" class="b-search_dialog-cancel" data-event-click="closeSearch">Cancel</button>
						</div>
					</div>
				</div>
				<div role="listbox" id="search-suggestions-list" class="b-suggestions m-active">
					<div role="none" class="b-suggestions-inner">
						<div role="none" class="b-suggestions-section m-content">
							<div role="none" class="b-suggestions-title">Quick Links</div>
							<a role="option" id="result-item-0" class="b-suggestions-option b-suggestions-link" href="#">Privacy Policy</a>
							<a role="option" id="result-item-1" class="b-suggestions-option b-suggestions-link" href="#">Men</a>
							<a role="option" id="result-item-2" class="b-suggestions-option b-suggestions-link" href="#">Women</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
```
*/
.b-dialog {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  transition-property: visibility, background-color;
  visibility: hidden;
  z-index: 11;
  justify-content: center;
  padding: 40px 0;
}
.b-dialog.m-opened, .b-dialog.m-active {
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  pointer-events: initial;
  visibility: visible;
}
.b-dialog-window {
  animation: dialog-openeing cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 3px 15px hsla(0, 0%, 0%, 0.35);
  color: #000000;
  margin: auto;
  max-width: 960px;
  padding: 0 20px 36px;
  position: relative;
  transform-origin: top center;
  width: auto;
  z-index: 11;
}
@media screen and (min-width: 1024px) {
  .b-dialog-window {
    margin: auto 32px;
  }
}
@media screen and (min-width: 768px) {
  .b-dialog-window {
    min-width: 600px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-dialog-window {
    max-width: calc(100% - 64px);
  }
}
@media screen and (max-width: 767px) {
  .b-dialog-window {
    max-width: calc(100% - 30px);
    padding: 0 16px 36px;
  }
}
.b-dialog-window.m-inline {
  animation: none;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .b-dialog-3ds2 {
    width: 100%;
  }
}
.b-dialog-header {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding-top: 60px;
}
@media screen and (min-width: 1024px) {
  .b-dialog-header.m-hide_lg {
    display: none;
  }
}
.b-dialog-title {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
  font-weight: 600;
}
.b-dialog-title.m-small {
  display: block;
  text-align: center;
  width: 100%;
}
.b-dialog-close {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  color: #45132C;
  height: 17px;
  position: absolute;
  right: 23px;
  top: 23px;
  width: 17px;
}
.b-dialog-body {
  font-size: 11px;
  line-height: 1.5;
  margin-top: 8px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .b-dialog-body {
    margin-top: 32px;
  }
}
.b-dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .b-dialog-footer {
    flex-direction: column;
  }
}
.b-dialog-footer button {
  font-size: 13px;
  margin: auto;
  max-height: 33px;
  max-width: 172px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-dialog-footer button + button {
    margin-left: 8px;
  }
}
@media screen and (max-width: 767px) {
  .b-dialog-footer button + button {
    margin-top: 8px;
  }
}
.b-dialog-subtitle {
  font-size: 15px;
  margin-top: 33px;
}
.b-dialog-price {
  font-size: 17px;
  font-weight: 600;
  margin-top: -5px;
}
.b-dialog-price .b-price-item.m-new ~ .b-price-item {
  font-size: 15px;
  font-weight: 500;
}
.b-dialog-bg {
  background: #FFFFFF url("../images/modal-bg/modal-bg.png") no-repeat;
  min-width: auto;
  width: 320px;
}
.b-dialog.m-search_suggestions {
  align-items: flex-start;
  display: none;
  overflow-y: auto;
  padding: 0;
}
.b-dialog.m-search_suggestions.m-opened {
  display: block;
}
.b-dialog.m-search_suggestions::after {
  touch-action: none;
}
.b-dialog.m-reset_password .b-form_section {
  margin-bottom: 26px;
  padding: 0 10px;
}
.b-dialog.m-reset_password .b-form_section-label {
  color: #2F2F2F;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.45;
}
.b-dialog.m-session_expired .b-button {
  font-size: 15px;
  max-height: 40px;
}
.b-dialog.m-cart .b-dialog-window {
  padding-bottom: 15px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-dialog.m-cart .b-dialog-window {
    max-width: 686px;
  }
}
.b-dialog.m-cart .b-dialog-header {
  padding-top: 0;
}
.b-dialog.m-cart .b-dialog-body {
  margin-top: 20px;
  text-align: left;
}
.b-dialog.m-modal_promo .b-dialog-window {
  max-width: 520px;
  min-width: auto;
  padding: 0 24px 40px;
  width: 90%;
}
.b-dialog.m-modal_promo .b-dialog-header {
  padding-top: 30px;
}
.b-dialog.m-modal_promo .b-dialog-close {
  right: 12px;
  top: 12px;
}
.b-dialog.m-modal_promo .b-dialog-body {
  margin: 0;
  text-align: center;
}
.b-dialog.m-modal_promo .b-dialog-body h3 {
  font-size: 20px;
  margin: 0 0 8px;
}
@media screen and (min-width: 768px) {
  .b-dialog.m-modal_promo .b-dialog-body h3 {
    font-size: 32px;
  }
}
.b-dialog.m-modal_promo .b-dialog-body p {
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .b-dialog.m-modal_promo .b-dialog-body p {
    font-size: 24px;
  }
}
.b-dialog.m-modal_promo .b-dialog-body h3,
.b-dialog.m-modal_promo .b-dialog-body p {
  line-height: 1.25;
}
.b-dialog.m-modal_promo .b-dialog-body .b-button {
  padding: 0 25px;
}
.b-dialog.m-panel .b-dialog-window {
  background-color: #96D6D4;
  bottom: 0;
  height: 80vh;
  margin: 0;
  max-width: 100%;
  min-width: 375px;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: auto;
  transform: translate(0, 100%);
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  transition-property: transform;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-dialog.m-panel .b-dialog-window {
    height: 100%;
    max-width: initial;
    top: 0;
    transform: translate(100%, 0);
    width: auto;
  }
}
.b-dialog.m-panel .b-dialog-header {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.b-dialog.m-panel .b-dialog-body {
  text-align: left;
}
.b-dialog.m-panel.m-opened .b-dialog-window {
  transform: translate(0);
}
.b-dialog.m-panel .m-rewards {
  display: flex;
  gap: 16px;
  padding: 16px 0;
}
.b-dialog.m-flush {
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .b-dialog.m-flush.m-panel .b-dialog-window {
    width: 375px;
  }
}

/*md

# b-user_content

This component is used to provide regular document styling in places where content managment team could
insert rich HTML markup.

## Headings

```html_example
<div class="b-user_content">
	<h1>General Information</h1>
	<h2>General Information</h2>
	<h3>General Information</h3>
	<h4>General Information</h4>
	<h5>General Information</h5>
</div>
```

## Paragraphs

```html_example
<div class="b-user_content">
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
</div>
```

## An unordered list

```html_example
<div class="b-user_content">
	<ul>
		<li>Provide, operate, and maintain our webste</li>
		<li>Improve, personalize, and expand our webste</li>
		<li>Understand and analyze how you use our webste</li>
	</ul>
</div>
```

## An ordered list

```html_example
<div class="b-user_content">
	<ol>
		<li>Develop new products, services, features, and functionality</li>
		<li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
		<li>Send you emails</li>
		<li>Find and prevent fraud</li>
	</ol>
</div>
```

## Full page

```html_example
<div class="b-user_content">
	<h1>
		Privacy Policy
	</h1>
	<h2>General Information</h2>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<ul>
		<li>Provide, operate, and maintain our webste</li>
		<li>Improve, personalize, and expand our webste</li>
		<li>Understand and analyze how you use our webste</li>
	</ul>
	<ol>
		<li>Develop new products, services, features, and functionality</li>
		<li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
		<li>Send you emails</li>
		<li>Find and prevent fraud</li>
	</ol>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
</div>
```
*/
.b-user_content {
  color: #2F2F2F;
}
.b-user_content h1,
.b-user_content .b-h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
  margin-bottom: 25px;
}
.b-user_content h2,
.b-user_content .b-h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;
}
.b-user_content h3,
.b-user_content .b-h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
}
.b-user_content h4,
.b-user_content .b-h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
}
.b-user_content .b-h4-medium {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
}
.b-user_content h5,
.b-user_content .b-h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
}
.b-user_content h6,
.b-user_content .b-h6 {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.25;
}
.b-user_content h2,
.b-user_content h3,
.b-user_content h4,
.b-user_content h5 {
  color: #45132C;
  margin-bottom: 15px;
  margin-top: 25px;
}
.b-user_content h2:first-child,
.b-user_content h3:first-child,
.b-user_content h4:first-child,
.b-user_content h5:first-child {
  margin-top: 0;
}
.b-user_content p {
  margin-bottom: 16px;
}
.b-user_content .b-bold {
  font-weight: 700;
}
.b-user_content .b-centered {
  text-align: center;
}
.b-user_content .b-paragraph {
  margin-bottom: 0;
}
.b-user_content ul,
.b-user_content ol {
  display: block;
  margin-bottom: 15px;
  padding-left: 20px;
}
.b-user_content li {
  display: list-item;
  margin-bottom: 5px;
}
.b-user_content ul {
  list-style: disc outside;
}
.b-user_content ol {
  list-style: decimal outside;
}
.b-user_content .b-unstyled_list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.b-user_content .b-unstyled_list li {
  display: inherit;
  margin-bottom: 0;
}
.m-back_in-stock .b-user_content h2,
.m-back_in-stock .b-user_content .b-h2 {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.25;
}
.b-user_content a:not(.b-button) {
  text-decoration: underline;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-user_content a:not(.b-button):hover {
  color: #C23D74;
}
.b-user_content hr {
  background-color: #D2D2D2;
  border-style: none;
  height: 1px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.b-user_content td,
.b-user_content th {
  line-height: 1.2;
  padding: 8px;
  text-align: left;
}
.b-user_content th {
  font-weight: 700;
}
.b-user_content table.m-bordered td,
.b-user_content table.m-bordered th {
  border: 1px solid #D2D2D2;
}
.b-user_content table.m-bordered th {
  background-color: #F2F2F2;
}
.b-user_content.b-dialog-body {
  text-align: left;
}
.b-remove_product .b-user_content.b-dialog-body {
  text-align: center;
}

/*md

# b-accordion

Please see [g-accordion](02-components-g-accordion.html) for details

*/
.b-accordion-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
  padding: 12px 15px;
  text-align: left;
  width: 100%;
}
.b-accordion-button:hover {
  color: #C23D74;
}
.b-accordion-button .b-icon_chevron {
  margin-left: 15px;
}
.b-accordion-button_icon {
  margin-left: 15px;
}
[aria-expanded=true] .b-accordion-button_icon {
  transform: rotate(180deg);
}
.b-accordion-content {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: height, opacity;
  visibility: hidden;
}
.b-accordion-content[aria-hidden=false] {
  height: auto;
  opacity: 1;
  visibility: visible;
}
.b-accordion-item:not([data-initialized="1"]) .b-accordion-content {
  height: auto;
  opacity: 1;
  visibility: visible;
}
.b-accordion-content_inner {
  overflow: hidden;
  padding: 0 15px 20px;
}

.b-icon_chevron {
  display: block;
  flex-shrink: 0;
  height: 14px;
  margin-left: auto;
  position: relative;
  width: 14px;
}
.b-icon_chevron::after, .b-icon_chevron::before {
  animation-duration: 0.23s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 2px solid;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  width: 9px;
}
.b-icon_chevron::before {
  animation-name: animation-chevron-down;
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
  left: 0;
}
[aria-expanded=true] ~ .b-icon_chevron::before, [aria-expanded=true] .b-icon_chevron::before {
  animation-name: animation-chevron-up;
}
.b-icon_chevron::after {
  animation-name: animation-chevron-up;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
  right: 0;
}
[aria-expanded=true] ~ .b-icon_chevron::after, [aria-expanded=true] .b-icon_chevron::after {
  animation-name: animation-chevron-down;
}

/*md

# b-message

TBD

*/
.b-message {
  background-color: #F2F2F2;
  border-radius: 2px;
  color: #2F2F2F;
  margin-bottom: 10px;
  padding: 4px 8px;
}
.b-message-button {
  cursor: pointer;
}
@media not all and (pointer: coarse) {
  .b-message-button:hover {
    color: #C23D74;
  }
}
.b-message.m-success {
  background-color: #FFFFFF;
  border: 1px solid #849F23;
  color: #849F23;
}
.b-message.m-error {
  background-color: #FFFFFF;
  border: 1px solid #A42015;
  color: #A42015;
}
.b-checkout_login-section .b-message.m-error, .b-account .b-message.m-error, .b-track_order .b-message.m-error, .b-password_reset .b-message.m-error, .b-data_sheets .b-message.m-error {
  background-color: transparent;
  border: none;
  font-size: 11px;
  line-height: 16px;
  padding-left: 18px;
  padding-top: 8px;
  position: relative;
  text-align: start;
}
.b-checkout_login-section .b-message.m-error::before, .b-account .b-message.m-error::before, .b-track_order .b-message.m-error::before, .b-password_reset .b-message.m-error::before, .b-data_sheets .b-message.m-error::before {
  background: url("../images/ma/info.svg") no-repeat;
  content: "";
  height: 15px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 15px;
}
.b-password_reset .b-message.m-error {
  margin: 0 auto 20px;
  max-width: 288px;
}
.l-checkout-messages .b-message.m-error {
  background: #A42015;
  border-radius: 4px;
  color: #FFFFFF;
  padding: 7px 9px 7px 32px;
}
.l-checkout-messages .b-message.m-error::before {
  background: url("./images/icons/info.svg") no-repeat;
  content: "";
  height: 20px;
  left: 9px;
  position: absolute;
  top: 7px;
  width: 20px;
}
.b-message.m-warning {
  background-color: transparent;
  color: #A42015;
  padding: 0;
}
.b-message.m-order_confirmation {
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
}
.b-message.m-notification {
  background-color: #6AA3A3;
  color: #FFFFFF;
  font-size: 15px;
  padding: 13px;
  text-align: center;
}
.b-message.m-shipping {
  align-items: center;
  background-color: transparent;
  border: 1px solid #45132C;
  color: #45132C;
  display: flex;
}
.b-message.m-shipping svg {
  width: 18px;
}
.b-message-link {
  cursor: pointer;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  .b-message-link:hover {
    color: #C23D74;
  }
}
.b-message-link.m-highlighted {
  font-weight: 600;
}
.b-message-inner.m-warning {
  display: flex;
  text-align: left;
}
.m-expanded .b-message-inner.m-not_selected {
  display: none;
}
.b-message-icon {
  margin-right: 6px;
}
.b-message.m-shipping .b-message-icon {
  margin-right: 10px;
}

/*md

# Input

Default input element

## Default

```html_example
	<input
		class="b-input"
		type="text"
		placeholder="Promo Code"
		name="couponCode"
		required=""
		value=""
	>
```

## Invalid

```html_example
	<input
		class="b-input m-invalid"
		type="text"
		placeholder="Promo Code"
		name="couponCode"
		required=""
		value=""
	>
```

*/
.b-input {
  appearance: none;
  border: 1px solid #ACACAC;
  border-radius: 3px;
  color: #000000;
  cursor: text;
  font-family: inherit;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  transition: border-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  vertical-align: baseline;
  width: 100%;
}
.b-input:hover, .b-input:active, .b-input:focus {
  border-color: #45132C;
}
@media screen and (max-width: 1023px) {
  .b-input:focus {
    font-size: 16px;
  }
}
.b-input.m-invalid {
  border-color: #A42015;
}
.b-input_password .b-input {
  padding-right: 68px;
}
.b-input[disabled] {
  background: #F2F2F2;
  color: #D2D2D2;
}
.b-input::placeholder {
  color: #ACACAC;
}
.b-giftcard .b-input.m-equal {
  max-width: 178px;
}
.b-data_sheets .b-input {
  border-radius: 20px 0 0 20px;
}

/*md

# Select

This component allows user to choose one option from drop-down list.

<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="arrow-down-small" viewBox="0 0 10 6">
        <path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
    </symbol>
</svg>

```html_example
<div class="b-select">
	<select data-ref="field" class="b-select-input m-valid" id="" name="" required="" value="" maxlength="2147483647" aria-required="true" data-event-change="onChange" data-tau="" aria-describedby="" data-event-blur="validate">
		<option value="US" data-id="US" selected="">United States</option>
		<option value="CA" data-id="CA">Canada</option>
	</select>
	<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
		<use href="#arrow-down-small"></use>
	</svg>
</div>
```

## Error State

```html_example
<div class="b-select">
	<select data-ref="field" class="b-select-input m-invalid" id="" name="" required="" value="" maxlength="2147483647" aria-required="true" data-event-change="onChange" data-tau="" aria-describedby="" data-event-blur="validate">
		<option value="" data-id="0">Please select</option>
		<option value="US" data-id="1">United States</option>
		<option value="CA" data-id="2">Canada</option>
	</select>
	<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
		<use href="#arrow-down-small"></use>
	</svg>
</div>
```

*/
.b-select {
  position: relative;
  width: 100%;
}
.b-select-icon {
  bottom: 0;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
}
.b-select-input {
  appearance: none;
  background: #FFFFFF;
  border: 1px solid #ACACAC;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 13px;
  font-weight: normal;
  height: 40px;
  line-height: 38px;
  outline: none;
  padding: 0 33px 0 10px;
  position: relative;
  transition: border-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  user-select: none;
  width: 100%;
}
.b-select-input:hover, .b-select-input:active, .b-select-input:focus {
  border-color: #45132C;
}
.b-select-input:focus::-ms-value {
  background: transparent;
  color: #000000;
}
.b-select-input::-ms-expand {
  display: none;
}
.b-select-input:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}
.b-select-input.m-invalid {
  border-color: #A42015;
}
.b-select-input.m-disabled, .b-select-input[disabled] {
  background: #F2F2F2;
  border-color: #ACACAC;
  color: #ACACAC;
  cursor: default;
  pointer-events: none;
}
.b-select-input.m-disabled + .b-select-icon, .b-select-input[disabled] + .b-select-icon {
  display: none;
}
.b-select-input_as_text {
  appearance: none;
  background: none;
  border: none;
  font-size: 16px;
  max-width: 100%;
  pointer-events: none;
  white-space: normal;
}
.b-select-container.m-open ~ .b-select-icon {
  transform: translateY(-50%) rotate(180deg);
}
.b-select-container {
  position: relative;
  user-select: none;
}
.b-select-container > select {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 1024px) {
  .b-select-container > select {
    pointer-events: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-select-opener {
    pointer-events: none;
  }
}
.b-select-panel {
  background-color: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  color: #000000;
  display: none;
  font-size: 15px;
  margin-top: 8px;
  max-height: 288px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 4px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
}
.b-select-panel::-webkit-scrollbar {
  background-color: #FFFFFF;
  width: 5px;
}
.b-select-panel::-webkit-scrollbar-thumb {
  background-color: #D2D2D2;
  border-radius: 8px;
}
.b-select-container.m-open .b-select-panel {
  display: block;
}
.b-select-option {
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;
  padding: 5px 8px;
}
.b-select-option:last-child {
  margin-bottom: 0;
}
.b-select-option.m-focus {
  background-color: #F2F2F2;
}
.b-select-option[data-value=""] {
  display: none;
}

option {
  font-family: inherit;
  font-size: inherit;
}

.b-stepper {
  display: flex;
  user-select: none;
  width: 123px;
}
.b-stepper.m-cart {
  width: auto;
}
.b-stepper-button {
  border: 1px solid #ACACAC;
  color: #45132C;
  cursor: pointer;
  display: block;
  line-height: 39px;
  min-height: 41px;
  min-width: 41px;
  text-align: center;
  touch-action: manipulation;
}
.b-stepper-button.m-cart {
  border-color: #ACACAC;
  line-height: 20px;
  min-height: 24px;
  min-width: 24px;
}
.b-stepper-button.m-cart svg {
  height: 9px;
  width: 9px;
}
.b-stepper-button:active {
  background-color: #45132C;
  color: #FFFFFF;
  fill: currentColor;
}
.b-stepper-button[disabled] {
  background-color: #FFFFFF;
  color: #ACACAC;
  pointer-events: none;
}
.b-stepper-button.m-minus {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.b-stepper-button.m-plus {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.b-stepper-input {
  appearance: none;
  border-color: #ACACAC;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0;
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
  min-height: 41px;
  min-width: 41px;
  padding: 0;
  text-align: center;
  width: 100%;
}
.b-stepper-input.m-cart {
  font-size: 11px;
  min-height: 24px;
  min-width: 24px;
}
.b-stepper-input:focus {
  border-color: #45132C;
}
@media screen and (max-width: 1023px) {
  .b-stepper-input:focus {
    font-size: 16px;
  }
}
.b-stepper-input[disabled] {
  background-color: #FFFFFF;
  color: #ACACAC;
  cursor: default;
  opacity: 1;
  -webkit-text-fill-color: #D2D2D2;
}

/*md

# Form section

Use this component to add some structure to forms.
It is possible to use any form element inside.

## Input element with label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input1">
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
	</div>
```

## Input element with caption

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input3">
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input3" placeholder="Promo Code" name="couponCode" required="" value="">
		<span class="b-form_section-caption">
			Casing &amp; hyphens need to be exact
		</span>
	</div>
```

## Input element with error

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input4">
			Enter Promo Code
		</label>
		<input class="b-input m-invalid" type="text" id="input4" placeholder="Promo Code" name="couponCode" required="" value="">
		<div role="alert" class="b-form_section-message">
			Coupon cannot be added to your cart
		</div>
		<span class="b-form_section-caption">
			Casing &amp; hyphens need to be exact
		</span>
	</div>
```

## Select element with label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="select1">
			Country
		</label>
		<div class="b-select">
			<select class="b-select-input" id="select1" name="" required="" value="">
				<option value="US" data-id="US">United States</option>
				<option value="CA" data-id="CA">Canada</option>
			</select>
			<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
				<path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
			</svg>
		</div>
	</div>
```

## Checkbox element with label

```html_example
	<div class="b-form_section">
		<div class="b-checkbox">
			<input type="checkbox" class="b-checkbox-input" id="checkox1">
			<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
			</svg>
			<label class="b-form_section-label" for="checkox1">
				By signing up, you agree to <a href="#" target="_blank">Privacy and Cookie Policies</a>
			</label>
		</div>
	</div>
```

*/
.b-form_section {
  margin-bottom: 24px;
  /* stylelint-disable */
  /* stylelint-enable */
}
.b-form_section.m-small {
  width: 250px;
}
.b-form_section.m-highlight {
  background: #F2F2F2;
  padding: 16px;
}
.b-form_section.m-fullwidth {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .b-form_section.m-button_row {
    text-align: center;
    width: 100%;
  }
}
.b-registration-inner .b-form_section, .b-registration-item .b-form_section, .b-data_sheets-item .b-form_section {
  margin-bottom: 0;
}
.b-form .b-form_section.m-no_margin {
  margin-bottom: 0;
}
.b-track_order .b-form_section {
  margin-bottom: 10px;
}
.b-dialog_migrate .b-form_section {
  padding: 0 15px;
}
.b-data_sheets .b-form_section {
  width: 100%;
}
.b-form_section-subtitle {
  font-size: 12px;
}
.b-form_section-subtitle.m-payment {
  margin-top: 24px;
}
.b-form_section-label {
  color: #2F2F2F;
  display: flex;
}
.b-form_section-label.m-wai {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-checkbox .b-form_section-label {
  display: block;
}
.b-product_notify_me-field .b-form_section-label {
  color: #757575;
  font-size: 11px;
  white-space: nowrap;
}
.b-registration .b-form_section-label {
  line-height: 1.45;
}
.b-form_section-link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}
@media not all and (pointer: coarse) {
  .b-form_section-link:hover {
    color: #C23D74;
  }
}
.b-registration .b-form_section-link {
  font-weight: 600;
}
.b-product_notify_me-field .b-form_section-link {
  text-decoration: none;
}
.b-form_section > .b-form_section-label {
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 6px;
}
.b-dialog_migrate .b-form_section > .b-form_section-label {
  font-size: 11px;
  line-height: 15px;
}
.b-form_section-caption {
  display: block;
  font-size: 11px;
  margin-top: 5px;
}
.b-form_section-value {
  display: block;
  font-size: 16px;
  line-height: 1;
}
.b-form_section-message {
  color: #A42015;
  display: block !important;
  font-size: 11px;
  line-height: 16px;
  margin-top: 4px;
}
.b-form_section-message[hidden] {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  pointer-events: none;
}
.b-coupon_form-content .b-form_section-message {
  margin-top: 4px;
}
.b-form.m-registration .b-form_section.m-required .b-form_section-label::after, .b-billing_form .b-form_section.m-required .b-form_section-label::after, .b-checkout_step.m-summary .b-form_section.m-required .b-form_section-label::after, .b-form.m-checkout_shipping .b-form_section.m-required .b-form_section-label::after {
  content: "*";
}
.b-cart_product-qty .b-form_section {
  margin-bottom: 0;
}

/*md

# b-checkbox

Please see [g-checkbox](02-components-g-checkbox.html) for details.

*/
.b-checkbox {
  align-items: flex-start;
  display: flex;
  font-size: 13px;
  line-height: 18px;
  position: relative;
  user-select: none;
}
.b-checkbox-input {
  cursor: pointer;
  height: 16px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 16px;
  z-index: 1;
}
.b-checkbox-icon {
  background-color: #FFFFFF;
  border: 1px solid #ACACAC;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 8px;
  min-width: 16px;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: border-color;
}
.b-checkbox-icon path {
  transform: scale(0);
  transform-origin: center center;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: transform;
}
.b-checkbox:hover .b-checkbox-icon {
  border-color: #45132C;
}
.b-checkbox-input:checked + .b-checkbox-icon {
  background-color: #45132C;
  border: 1px solid #45132C;
}
.b-checkbox-input:checked + .b-checkbox-icon path {
  color: #FFFFFF;
  transform: scale(1);
}
.b-checkbox-input[disabled] + .b-checkbox-icon {
  background-color: #F2F2F2;
  border-color: #D2D2D2;
  cursor: default;
  transform: scale(1);
}
.b-checkbox-input[disabled] + .b-checkbox-icon path {
  stroke: #757575;
}
.b-checkbox-input.m-invalid + .b-checkbox-icon {
  border-color: #A42015;
}
.b-checkbox-label {
  cursor: pointer;
}
.b-checkbox-input[disabled] + .b-checkbox-icon + .b-checkbox-label {
  color: #D2D2D2;
  cursor: default;
}

.b-password_estimator {
  margin-top: 8px;
}
.b-password_estimator-inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.b-password_estimator-indicator {
  display: flex;
  padding: 8px 0;
}
.b-password_estimator-indicator_item {
  background-color: #F2F2F2;
  height: 3px;
  margin-right: 4px;
  transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  width: 35px;
}
@media screen and (min-width: 768px) {
  .b-password_estimator-indicator_item {
    width: 50px;
  }
}
.b-password_estimator-indicator_item:last-child {
  margin-right: 0;
}
.b-password_estimator-indicator_item.m-weak {
  background-color: #A42015;
}
.b-password_estimator-indicator_item.m-medium {
  background-color: #F4F075;
}
.b-password_estimator-indicator_item.m-strong {
  background-color: #849F23;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-confirmation_create_account .b-password_estimator-indicator_item {
    width: 70px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-confirmation_create_account .b-password_estimator-indicator_item {
    width: 28px;
  }
}
@media screen and (min-width: 1367px) {
  .b-confirmation_create_account .b-password_estimator-indicator_item {
    width: 35px;
  }
}
.b-password_estimator-indicator_description {
  color: #757575;
  font-size: 12px;
  font-weight: 500;
  min-width: 105px;
  padding-left: 10px;
}
.b-password_estimator-caption {
  color: #757575;
  font-weight: 500;
  margin-top: 4px;
}

.b-countdown {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: -10px;
  position: relative;
}
.b-countdown-time {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
}

.b-minicart_panel-container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  transition-property: visibility, background-color;
  visibility: hidden;
  z-index: 11;
}
.b-minicart_panel-container.m-opened, .b-minicart_panel-container.m-active {
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  pointer-events: initial;
  visibility: visible;
}
.b-minicart_panel-container.m-opened {
  overflow: hidden;
}

.b-minicart {
  background-color: #FFFFFF;
  bottom: 0;
  color: #2F2F2F;
  height: 100%;
  max-width: 400px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition-property: transform;
  visibility: hidden;
  width: 80vw;
  z-index: 11;
}
.b-minicart.m-activated {
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
}
.b-minicart.m-active {
  box-shadow: 0 3px 15px hsla(0, 0%, 0%, 0.35);
  transform: translateX(0);
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  visibility: visible;
}
.b-minicart.m-loading_long::before {
  animation: 1s linear infinite rotate;
  border: 0.375em solid #45132C;
  border-left-color: #F2F2F2;
  border-radius: 50%;
  border-top-color: #F2F2F2;
  content: "Loading…";
  display: block;
  height: 3em;
  margin: auto;
  pointer-events: none;
  position: relative;
  text-indent: -9999em;
  width: 3em;
  left: 50%;
  margin: -1em 0 0 -1em;
  position: absolute;
  top: 50%;
}
.b-minicart-inner {
  padding: 20px;
}
.b-minicart[aria-busy=true] .b-minicart-inner {
  opacity: 0.6;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.b-minicart-header {
  margin-bottom: 15px;
}
.b-minicart-messages_item {
  font-size: 13px;
  line-height: 1.45;
  margin-bottom: 10px;
  padding: 4px 8px;
  text-align: left;
}
.b-minicart-messages_item:last-child {
  margin-bottom: 10px;
}
.b-minicart-content {
  margin-bottom: 30px;
}
.b-minicart-summary {
  margin-bottom: 20px;
}
.b-minicart-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
  margin-bottom: 10px;
}
.b-minicart-actions {
  margin-bottom: 30px;
}
.b-minicart-actions .b-button {
  font-size: 15px;
}
.b-minicart-actions .b-button + .b-button {
  margin-top: 20px;
}
.b-minicart-empty {
  margin-top: 85px;
  text-align: center;
}
.b-minicart-empty_title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
  margin: 0 auto 40px;
  max-width: 195px;
}
.b-minicart-empty_button {
  font-size: 15px;
  min-width: 296px;
}

.b-cart_bundle {
  font-size: 15px;
}
.b-cart_bundle-inner {
  border-bottom: 1px solid #ACACAC;
  padding: 0 13px 8px 10px;
}
.b-cart_bundle-label {
  align-items: center;
  color: #2F2F2F;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  width: 100%;
}
.b-cart_bundle-icon {
  animation-duration: 0.23s;
  animation-fill-mode: forwards;
  animation-name: animation-svg-chevron-up;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: #45132C;
  display: flex;
  padding-left: 15px;
}
[aria-expanded=true] ~ .b-cart_bundle-icon, [aria-expanded=true] .b-cart_bundle-icon {
  animation-name: animation-svg-chevron-down;
}
.b-cart_bundle-content {
  color: #757575;
  padding: 13px 6px 0;
}
[aria-expanded=true] ~ .b-cart_bundle-content {
  animation-name: animation-svg-chevron-down;
}
.b-cart_bundle-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.b-cart_bundle-item:last-child {
  margin-bottom: 3px;
}

.b-remove_product .b-button {
  font-size: 15px;
  max-height: 40px;
  min-width: 100%;
}
.b-remove_product-btn + .b-remove_product-btn {
  margin: 20px auto 0;
}
.b-remove_product-header {
  padding-top: 80px;
}
.b-remove_product-footer {
  flex-direction: column;
  margin-top: 55px;
}

.b-minicart_product {
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  color: #000000;
  padding: 15px;
  position: relative;
}
.b-minicart_product + .b-minicart_product {
  margin-top: 10px;
}
.b-minicart_product-inner {
  column-gap: 16px;
  display: grid;
  grid-template-areas: "msg ." "image details" "qty total" "bundle bundle" "remove .";
  grid-template-columns: 76px auto;
  grid-template-rows: auto auto auto;
  position: relative;
  width: 100%;
}
.b-minicart_product-voucher {
  font-size: 13px;
  grid-area: voucher;
}
.b-minicart_product-msg {
  background-color: #FFFFFF;
  border: 1px solid #A42015;
  border-radius: 2px;
  color: #A42015;
  font-size: 13px;
  grid-column: span 2;
  margin-bottom: 10px;
  padding: 4px 8px;
}
.b-minicart_product-link {
  display: block;
}
.b-minicart_product-image {
  grid-area: image;
}
.b-minicart_product-details {
  grid-area: details;
}
.b-minicart_product-picture {
  background: #F2F2F2;
  display: block;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.b-minicart_product-picture img {
  bottom: 0;
  color: #F2F2F2;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-minicart_product-title {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.45;
  margin-bottom: 4px;
}
.b-minicart_product-price {
  font-size: 13px;
  font-weight: 600;
}
.b-minicart_product-price.m-colored {
  color: #C23D74;
}
.b-minicart_product-price_total {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  font-size: 17px;
  grid-area: total;
  margin-top: 20px;
}
.b-minicart_product-price_total .b-price-subtotal {
  display: flex;
}
.b-minicart_product-price_total .b-price-subtotal_item.m-old {
  margin-left: 8px;
}
.b-minicart_product-info {
  grid-area: qty;
  margin-top: 20px;
}
.b-minicart_product-qty {
  display: flex;
  font-size: 13px;
}
.b-minicart_product-qty_inline {
  font-weight: 600;
  margin-right: 5px;
}
.b-minicart_product-remove {
  grid-area: remove;
  margin-top: 25px;
}
.b-minicart_product-remove_text {
  margin-left: 6px;
}
.b-minicart_product-remove_btn {
  align-items: center;
  color: #757575;
  cursor: pointer;
  display: flex;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
@media not all and (pointer: coarse) {
  .b-minicart_product-remove_btn:hover {
    opacity: 0.8;
  }
}
.b-minicart_product-remove_btn.m-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.b-minicart_product-bundle {
  grid-area: bundle;
  margin-top: 20px;
}
.b-minicart_product-bundle .b-cart_bundle-label {
  font-size: 13px;
}
.b-minicart_product .b-cart_product-edit {
  color: #45132C;
  font-size: 13px;
  font-weight: 600;
  margin-top: 15px;
  padding: 0;
}

.b-evoucher {
  display: grid;
  gap: 5px 10px;
  grid-template-columns: auto 1fr;
  margin: 0;
  padding: 10px 8px;
}
.b-minicart_product-details .b-evoucher {
  font-size: 13px;
  padding: 0;
}
.b-evoucher-item {
  font-size: 13px;
  line-height: 16px;
}
.b-evoucher-name {
  font-weight: 600;
  grid-column: 1/2;
}
.b-evoucher-value {
  grid-column: 2/3;
  line-height: 18px;
  margin: 0;
  word-break: break-word;
}

.b-payment_total {
  text-align: left;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-payment_total {
    display: none;
  }
}
.b-minicart .b-payment_total {
  display: table;
}
.b-payment_total-value {
  text-align: right;
  vertical-align: top;
}
.b-payment_total-name, .b-payment_total-value {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  padding: 5px 0;
}
.m-total .b-payment_total-name, .m-total .b-payment_total-value {
  background: #FFFFFF;
  font-weight: 600;
  padding-top: 15px;
}
.b-payment_total-tax {
  display: block;
  font-size: 12px;
  font-weight: 500;
}

.b-product_tile {
  background-color: #FFFFFF;
  border: 1px solid #F2F2F2;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  height: 100%;
  padding-bottom: 40px;
  position: relative;
  text-align: left;
}
.m-ideas .b-product_tile {
  border: none;
  padding-bottom: 0;
}
.b-product_tile.m-bundle {
  border: none;
  box-shadow: none;
  display: flex;
  height: auto;
  padding-bottom: 52px;
}
@media screen and (max-width: 767px) {
  .b-product_tile.m-bundle {
    padding-bottom: 99px;
  }
}
.b-product_tile-top {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  position: relative;
}
.b-product_tile-top_wrap {
  position: relative;
}
.b-product_tile-top_wrap.m-bundle {
  min-width: 166px;
  padding: 8px 0 12px 8px;
  width: 166px;
}
@media screen and (max-width: 1023px) {
  .b-product_tile-top_wrap.m-bundle {
    min-width: 152px;
    padding: 8px 0 11px 8px;
    width: 152px;
  }
}
@media screen and (max-width: 1023px) {
  .m-multy .b-product_tile-top_wrap.m-bundle {
    min-width: 132px;
    width: 132px;
  }
}
.b-product_tile-image_link {
  display: block;
}
.b-product_tile-image {
  background: #F2F2F2;
  display: block;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.b-product_tile-image img {
  bottom: 0;
  color: #F2F2F2;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.m-bundle .b-product_tile-image img {
  border-radius: 4px;
}
.b-product_tile-quick_view {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  bottom: 0;
  font-weight: 500;
  left: 0;
  opacity: 0;
  position: absolute;
  text-transform: initial;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: color, opacity;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-product_tile-quick_view {
    display: none;
  }
}
.b-product_tile-quick_view:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.b-product_tile-quick_view.m-product_set {
  pointer-events: none;
}
.b-product_tile-quick_view:focus, .b-product_tile-top:hover .b-product_tile-quick_view {
  opacity: 1;
}
.b-product_tile-no_available {
  color: #A42015;
}
.b-product_tile-info {
  padding: 10px 10px 15px;
}
.m-ideas .b-product_tile-info {
  padding: 10px 15px;
}
.b-product_tile-info.m-bundle {
  padding: 8px 8px 12px;
}
@media screen and (max-width: 767px) {
  .b-product_tile-info.m-bundle {
    padding: 8px 8px 11px 4px;
  }
}
.b-product_tile-title {
  font-size: 15px;
  min-height: 36px;
}
.m-ideas .b-product_tile-title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 1.5;
}
.b-product_tile-title.m-bundle {
  font-size: 13px;
  line-height: 1.25;
  margin-right: 24px;
  min-height: 32px;
  position: relative;
  z-index: 1;
}
.b-product_tile-link {
  cursor: pointer;
  text-decoration: underline;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-weight: 400;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: 1.35;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  word-break: break-word;
}
@media not all and (pointer: coarse) {
  .b-product_tile-link:hover {
    color: #C23D74;
  }
}
.b-product_tile-price {
  font-size: 15px;
  margin-top: 6px;
}
.b-product_tile-price.m-bundle {
  margin-top: 2px;
}
.b-product_tile-price .b-price-item.m-old {
  font-size: 13px;
}
.b-product_tile-rating {
  margin-top: 10px;
}
.b-product_tile-rating.m-bundle {
  margin-top: 2px;
}
.b-product_tile-call_out {
  align-items: center;
  color: #A42015;
  display: flex;
  margin-top: 8px;
}
.b-product_tile-call_out_text {
  border: 1px solid #A42015;
  border-radius: 2px;
  font-size: 11px;
  padding: 2px 8px;
}
.b-product_tile-low_stock {
  background-color: #D2D2D2;
  border-radius: 2px;
  color: #2F2F2F;
  display: inline-block;
  font-size: 11px;
  line-height: 1.4;
  margin-top: 9px;
  padding: 3px 9px;
}
.b-product_tile-low_stock.m-cart {
  margin: 0 0 15px;
}
.b-product_tile-bottom {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
}
.m-ideas .b-product_tile-bottom {
  display: none;
}
.b-product_tile-bottom.m-bundle {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  margin: 0 8px 12px;
  width: calc(100% - 16px);
}
@media screen and (max-width: 767px) {
  .b-product_tile-bottom.m-bundle {
    flex-direction: column;
  }
}
.b-product_tile-cta {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #D2D2D2;
  color: #45132C;
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px;
  min-height: 40px;
  text-align: center;
  width: calc(100% - 20px);
}
.b-product_tile-cta.m-product {
  background-color: #45132C;
  border: 0;
  color: #FFFFFF;
  margin: 0;
  padding: 5px;
  width: 100%;
}
.m-black-friday .b-product_tile-cta.m-product {
  background-color: #00D2FF;
  color: #000000;
}
.b-product_tile-cta.m-info {
  font-weight: 500;
}
.m-black-friday .b-product_tile-cta.m-info {
  color: #C23D74;
  font-weight: 600;
}
.b-product_tile-cta.m-idea {
  color: #C23D74;
}
.b-product_tile-cta.m-idea_quick_view {
  display: none;
}
.l-plp_grid.m-three_column .b-product_tile-cta.m-idea_quick_view {
  display: flex;
}
.l-plp_grid.m-three_column .b-product_tile-cta.m-idea_link {
  display: none;
}
.b-carousel.m-carousel-idea .b-product_tile-cta {
  display: none;
}
.b-product_tile-cta_svg {
  margin-right: 7px;
}
.b-product_tile-remove {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0 4px 0 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: background-color, color;
}
.b-product_tile-remove:hover {
  color: #C23D74;
}
.b-product_tile-error {
  color: #A42015;
  margin-top: 10px;
}
.b-product_tile-description {
  font-size: 11px;
  line-height: 1.5;
  margin-top: 10px;
}
.b-product_tile-description_content {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22px;
  margin-bottom: 10px;
  max-height: 22px;
}
.b-product_tile-description_content.m-show-more {
  display: block;
  max-height: none;
}
.b-product_tile-description_link {
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
}

/*md

# Product tile swatches

Swatch that used on product tile.

This is simplified version of swatch that use links to appropriate product variation.

```html_example
<div class="b-product_tile_swatches">
	<a class="b-product_tile_swatches-swatch m-selected" href="" title="Green color" data-attr-value="Green" data-tau-color-id="Green" data-tau="color_swatch_selected" style="background-color: #2e7d32"></a>
	<a class="b-product_tile_swatches-swatch " href="#" title="Black color" data-attr-value="Black" data-tau-color-id="Black" style="background-color: #000000"></a>
	<a class="b-product_tile_swatches-swatch " href="#" title="White color" data-attr-value="White" data-tau-color-id="White" style="background-color: #FFFFFF"></a>
	<a class="b-product_tile_swatches-leftover" href="#" title="Show all" aria-label="Show all" data-tau="product_color_showAll">+1</a>
</div>
```
*/
.b-product_tile_swatches {
  align-items: center;
  display: flex;
  margin: 12px 0;
}
.b-product_tile_swatches-swatch {
  background-size: cover;
  border: 1px solid #D2D2D2;
  border-radius: 50%;
  height: 32px;
  margin: 4px 12px 4px 0;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: box-shadow, border-color;
  width: 32px;
}
.b-product_tile_swatches-swatch:last-child {
  margin-right: 0;
}
.b-product_tile_swatches-swatch:hover, .b-product_tile_swatches-swatch.m-selected {
  border-color: #000000;
  box-shadow: inset 0 0 0 2px #FFFFFF;
}
.b-product_tile_swatches-leftover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration: none;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-product_tile_swatches-leftover:hover {
    color: #C23D74;
  }
}

@media screen and (max-width: 1023px) {
  .b-product_tile_alt_view::after, .b-product_tile_alt_view::before {
    background-color: #ACACAC;
    border: 3.5px solid #FFFFFF;
    border-radius: 100%;
    bottom: 8px;
    content: "";
    display: inline-block;
    height: 14px;
    left: 50%;
    pointer-events: none;
    position: absolute;
    width: 14px;
    z-index: 1;
  }
  .b-product_tile_alt_view::before {
    background-color: #45132C;
    transform: translateX(-11px);
  }
  .b-product_tile_alt_view::after {
    transform: translateX(11px);
  }
  .b-product_tile_alt_view.m-alt_active::before {
    background-color: #ACACAC;
  }
  .b-product_tile_alt_view.m-alt_active::after {
    background-color: #45132C;
  }
}
.b-product_tile_alt_view-track {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-product_tile_alt_view-track {
    display: flex;
    -ms-overflow-style: none;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
  }
  .b-product_tile_alt_view-track::-webkit-scrollbar {
    display: none;
  }
}
.b-product_tile_alt_view-item {
  height: 100%;
}
@media screen and (max-width: 1023px) {
  .b-product_tile_alt_view-item {
    min-width: 100%;
    scroll-snap-align: start;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_tile_alt_view-item.m-alt {
    background-color: #FFFFFF;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_tile_alt_view:hover .b-product_tile_alt_view-item.m-alt {
    opacity: 1;
  }
}

/*md

# Rating

Designed to provide same styles of rating across different components.

<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <!-- 0 0 16.476 15.677 shape from 0.0 -->
    <symbol id="star">
        <path d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
    </symbol>
    <!-- 0 0 16.476 15.677 shape from 0.0 -->
    <symbol id="star-half">
        <path class="b-rating-empty" d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
        <path class="b-rating-filled" d="m8.1348 0.19141-2.4434 4.9707-5.6914 0.83594 4.125 4.0078-0.96484 5.6719 4.9746-2.625v-12.861z"></path>
    </symbol>
</svg>

```html_example
<div class="b-rating" title="5 out of 5 Customer Rating">
	<svg class="b-rating-icon" width="100" height="20" viewBox="0 0 100 16" focusable="false">
		<use class="b-rating-filled" href="#star" y="0" x="0.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="20.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="40.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="60.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="80.0"></use>
	</svg>
	<span class="b-rating-number" data-tau="product_rating">(5)</span>
</div>
```

## Rating with link to product

```html_example
<div class="b-rating" title="3.2 out of 5 Customer Rating">
	<a class="b-rating-link" href="#" title="3.2 out of 5 Customer Rating" aria-label="3.2 out of 5 Customer Rating">
		<svg class="b-rating-icon" width="100" height="20" viewBox="0 0 100 16" focusable="false">
			<use class="b-rating-filled" href="#star" y="0" x="0.0"></use>
			<use class="b-rating-filled" href="#star" y="0" x="20.0"></use>
			<use class="b-rating-filled" href="#star" y="0" x="40.0"></use>
			<use href="#star-half" y="0" x="60.0"></use>
			<use class="b-rating-empty" href="#star" y="0" x="80.0"></use>
		</svg>
		<span class="b-rating-number" data-tau="product_rating">(3.2)</span>
	</a>
</div>
```
*/
.b-rating {
  display: flex;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
}
.b-rating-link {
  display: flex;
  text-decoration: none;
}
.b-rating-link:hover {
  color: #C23D74;
  text-decoration: none;
}
.b-rating-icon {
  color: #C23D74;
}
.b-rating-number {
  margin-left: 4px;
}
.b-rating-number.m-no_count {
  text-decoration: underline;
}
.b-rating-average {
  margin-left: 3px;
  text-decoration: underline;
}

/*md

# b-price

Designed to provide same styles of pricing across different components without explicit CSS class.
`.b-price` inherit font-size from parent wrapper or general font-size

## Regular price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
</div>
```

## Sale price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-to">Was</span>
	<span class="b-price-item m-old" content="11.99" data-tau-price="old">
		$11.99
	</span>
	<span class="b-price-to">, is</span>
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
</div>
```

## Price range

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-to">From</span>
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
	<span class="b-price-to">to</span>
	<span class="b-price-divider">-</span>
	<span class="b-price-item" content="11.99" data-tau-price="old">
		$11.99
	</span>
</div>
```

## Free price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-item m-free" content="Free" data-tau-price="new">
		FREE
	</span>
</div>
```

*/
.b-price {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  line-height: 1;
  position: relative;
}
.b-price-item {
  display: inline-block;
  white-space: nowrap;
}
.m-checkout_shipping .b-price-item {
  color: #2F2F2F;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
}
.b-price-item:last-child {
  margin: 0;
}
.b-price-item.m-new {
  margin-right: 8px;
}
.b-price-item.m-old {
  font-weight: 600;
  margin-right: 8px;
  text-decoration: line-through;
}
.b-product_bundle-subtotal .b-price-item.m-old {
  margin-right: 0;
}
.b-price-item.m-new ~ .b-price-item {
  color: #757575;
  font-weight: 500;
  text-decoration: line-through;
}
.b-price-item.m-free {
  font-size: 20px;
  font-weight: 700;
}
.m-checkout_shipping .b-price-item.m-free {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
}
.b-price-item.m-total {
  font-size: inherit;
  font-weight: 600;
  text-transform: uppercase;
}
.b-price-item.m-colored {
  color: #C23D74;
}
.b-minicart_product.m-checkout .b-price-item {
  margin-right: 4px;
}
.b-price-divider {
  margin: 0 5px;
}
.b-price-divider.m-range {
  margin: 0 4px;
}
.b-price-subtotal {
  font-weight: 600;
  line-height: 1.2;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .b-price-subtotal {
    display: flex;
  }
}
.b-price-subtotal_item.m-old {
  color: #757575;
  text-decoration: line-through;
}
@media screen and (max-width: 767px) {
  .b-price-subtotal_item.m-old {
    margin-left: 8px;
  }
}

/*md

# b-promotion

Designed to provide same styles of promotion across different components.

We do not support any HTML in promotion messages.

## Promotion with configured link

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for <a href="#">order</a>
	</div>
</div>
```

## Empty promotion

```html_example
<div class="b-promotion">
	<div class="b-promotion-message"></div>
</div>
```

## Promotion with open dialog button

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for order
	</div>
	<button
		class="b-promotion-details"
		type="button"
		data-widget="emitBusEvent"
		data-bus-event-type="dialog.show"
		data-event-click.prevent="emitBusEvent"
		data-tau="promotion_details_cta"
		data-initialized="1"
	>
		Details
	</button>
</div>
```

*/
.b-promotion {
  align-items: center;
  color: #A42015;
  display: flex;
  position: relative;
}
.b-promotion.m-plus {
  color: #2F2F2F;
  display: none;
}
.b-promotion-message {
  align-items: center;
  border: 1px solid #A42015;
  border-radius: 2px;
  display: flex;
  font-size: 11px;
  gap: 4px;
  line-height: 1.4;
  margin-top: 8px;
  padding: 2px 8px;
}
.b-promotion-message.m-link:hover {
  border-color: #52100B;
  color: #52100B;
}
.b-promotion-message.m-empty {
  display: none;
}
.b-promotion-message a {
  color: inherit;
  text-decoration: underline;
}
.b-promotion-message a:hover {
  color: #52100B;
}
.b-promotion-message button:hover {
  color: #52100B;
}
.b-promotion.m-plus .b-promotion-message {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
.b-promotion-details {
  color: #A42015;
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
}
.b-promotion.m-bundle .b-promotion-message {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 8px;
}
.b-promotion.m-bundle .b-promotion-message.m-bundle {
  border-radius: 2px;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  padding: 1px 8px;
}
.b-promotion.m-badge .b-promotion-message {
  background-color: #398484;
  border-color: #398484;
  color: #FFFFFF;
}
.b-product_details .b-promotion {
  margin: 10px 0;
}
.b-product_details .b-promotion.m-bundle {
  margin: 8px 0;
}

/*md

# b-availability

Designed to provide same styles of availability across different components.

```html_example
<div data-ref="container" class="b-availability m-instock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		In Stock
	</div>
</div>
```

```html_example
<div data-ref="container" class="b-availability m-outofstock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		Out Of Stock
	</div>
</div>
```

```html_example
<div data-ref="container" class="b-availability m-lowinstock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		5 Items In Stock
	</div>
</div>
```

*/
.b-availability {
  align-items: center;
  color: #2F2F2F;
  display: flex;
  font-size: 15px;
  margin-bottom: 15px;
}
.b-availability-button {
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-decoration: underline;
}
.b-availability-icon {
  align-items: center;
  color: #398484;
  display: flex;
  justify-content: center;
  margin-right: 12px;
  min-width: 24px;
  width: 24px;
}
.b-availability-icon.m-outofstock {
  color: #ACACAC;
}
.b-availability-icon svg {
  height: auto;
  max-width: 100%;
}
.b-availability.m-last, .b-availability:last-child {
  margin-bottom: 0;
}
.b-availability-low_stock {
  color: #A34F00;
  font-weight: 600;
}

.b-search_input {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 20px;
  display: flex;
  font-size: 15px;
  margin-right: 32px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .b-search_input {
    margin-right: 16px;
  }
}
.b-search_input::after {
  border-right: 1px solid #D2D2D2;
  content: "";
  height: 38px;
  left: calc(100% + 24px);
  position: absolute;
  width: 0;
}
@media screen and (max-width: 767px) {
  .b-search_input::after {
    left: calc(100% + 16px);
  }
}
.b-search_input-submit {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  align-self: stretch;
  background: #45132C;
  border-radius: 0;
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
  color: #FFFFFF;
  height: 38px;
  transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  width: 43px;
}
@media not all and (pointer: coarse) {
  .b-search_input-submit:hover, .b-search_input-submit:focus {
    background: #230A16;
  }
}
.b-search_input-submit_icon {
  color: #FFFFFF;
}
.b-search_input-input {
  appearance: none;
  background: transparent;
  border: 1px solid #45132C;
  border-radius: 0;
  border-bottom-left-radius: 18px;
  border-top-left-radius: 18px;
  box-sizing: border-box;
  color: #000000;
  height: 38px;
  line-height: 38px;
  padding: 0 0 0 20px;
  text-align: left;
  width: 100%;
}
.b-search_input-input::-webkit-search-cancel-button, .b-search_input-input::-webkit-search-decoration, .b-search_input-input::-webkit-inner-spin-button {
  appearance: none;
}
.b-search_input-input::-ms-clear {
  display: none;
}
.b-search_input-input::placeholder {
  color: #757575;
}
@media screen and (max-width: 1023px) {
  .b-search_input-input:focus {
    font-size: 16px;
  }
}
.b-search_input-clear {
  cursor: pointer;
  text-decoration: underline;
  color: #ACACAC;
  display: none;
  font-size: 13px;
  font-weight: 600;
  margin-right: 12px;
  position: absolute;
  right: 43px;
}
@media not all and (pointer: coarse) {
  .b-search_input-clear:hover {
    color: #C23D74;
  }
}
.b-search_input-clear.m-visible {
  display: flex;
}

.b-search_dialog {
  height: 100%;
  width: 100%;
}
.b-search_dialog-inner {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  width: 100%;
  z-index: 7;
}
.b-search_dialog-inner_top {
  background-color: #FFFFFF;
}
.b-search_dialog-inner_top_content {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  display: flex;
  justify-content: center;
  max-width: 1400px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-search_dialog-inner_top_content {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-search_dialog-inner_top_content {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-search_dialog-inner_top_content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 768px) {
  .b-search_dialog-inner_top_content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 767px) {
  .b-search_dialog-inner_top_content {
    padding-right: 8px;
  }
}
.b-search_dialog-form_wrap {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 12px 0 16px;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-search_dialog-form_wrap {
    margin: 28px 0 24px;
    padding: 0;
  }
}
.b-search_dialog-cancel {
  color: #45132C;
  cursor: pointer;
  padding: 12px;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-search_dialog-cancel:focus, .b-search_dialog-cancel:hover {
  color: #230A16;
}

.b-suggestions {
  background-color: #FFFFFF;
  overflow-y: auto;
  padding: 12px 0 32px;
  position: relative;
  right: 0;
  width: 100%;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .b-suggestions {
    padding-bottom: 8px;
  }
}
.b-suggestions-inner {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1400px;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-suggestions-inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-suggestions-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-suggestions-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 768px) {
  .b-suggestions-inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.b-suggestions-inner.m-empty {
  align-items: center;
  color: #757575;
  display: flex;
  justify-content: center;
  min-height: 120px;
  padding: 0 8px;
  text-align: center;
}
.b-suggestions.m-loading .b-suggestions-inner {
  opacity: 0.3;
  pointer-events: none;
}
.b-suggestions-section {
  grid-column: span 12;
}
@media screen and (max-width: 767px) {
  .b-suggestions-section {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-suggestions-section {
    grid-column: span 6;
  }
  .b-suggestions-section.m-categories {
    grid-column: span 12;
  }
}
@media screen and (min-width: 1024px) {
  .b-suggestions-section {
    grid-column: span 4;
  }
  .b-suggestions-section.m-one_type {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1367px) {
  .b-suggestions-section {
    grid-column: span 3;
  }
  .b-suggestions-section.m-one_type, .b-suggestions-section.m-categories {
    grid-column: span 6;
  }
}
@media screen and (min-width: 768px) {
  .b-suggestions-section.m-col_2 {
    grid-column: span 6;
  }
}
.b-suggestions-grid {
  display: grid;
}
@media screen and (min-width: 1367px) {
  .b-suggestions-grid {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-suggestions-grid {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-suggestions-grid {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-suggestions-grid {
    grid-gap: 16px 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-suggestions-grid {
    display: block;
  }
}
.b-suggestions-title {
  border-bottom: 1px solid #D2D2D2;
  color: #45132C;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 4px;
}
@media screen and (max-width: 767px) {
  .b-suggestions-title {
    font-size: 15px;
    margin-bottom: 16px;
    padding-bottom: 7px;
  }
}
.b-suggestions-options {
  font-size: 15px;
}
.b-suggestions-options.m-tags {
  display: flex;
  flex-wrap: wrap;
}
.b-suggestions-options.m-links {
  display: inline-flex;
  flex-direction: column;
}
.b-suggestions-option {
  margin-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .b-suggestions-option {
    font-size: 13px;
  }
}
.b-suggestions-option.b-tag {
  font-size: 15px;
  line-height: 1;
  margin-right: 16px;
}
.b-suggestions-message {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
@media screen and (max-width: 767px) {
  .b-suggestions-view_results {
    width: 100%;
  }
}
.b-suggestions-view_results.m-focused {
  background: #45132C;
  color: #FFFFFF;
}

.b-suggestions_category {
  color: #000000;
  font-weight: bold;
  text-decoration: none;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-suggestions_category-title {
  text-decoration: underline;
}
.b-suggestions_category:hover, .b-suggestions_category.m-focused {
  color: #C23D74;
  text-decoration: underline;
}
.b-suggestions_category-image {
  background: #F2F2F2;
  display: block;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  border-radius: 4px;
  height: 30px;
  margin-right: 12px;
  max-height: 30px;
  max-width: 30px;
  min-width: 30px;
  padding: 0;
  width: 30px;
}
.b-suggestions_category-image img {
  bottom: 0;
  color: #F2F2F2;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-suggestions_category-parent_title {
  font-weight: normal;
}

.b-suggestions_guess {
  color: #000000;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0;
  text-decoration: none;
}
.b-suggestions_guess.m-focused, .b-suggestions_guess:hover {
  text-decoration: none;
}
.b-suggestions_guess-correction_wrap {
  font-weight: 500;
}
.b-suggestions_guess-correction {
  text-decoration: underline;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-suggestions_guess.m-focused .b-suggestions_guess-correction, .b-suggestions_guess:hover .b-suggestions_guess-correction {
  color: #C23D74;
}

.b-suggestions_product {
  background-color: #FFFFFF;
  border: 1px solid #F2F2F2;
  border-radius: 2px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  color: #000000;
  display: flex;
  margin: 0 30px 12px 0;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .b-suggestions_product {
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .b-suggestions_product {
    margin-right: 0;
  }
}
.b-suggestions_product:hover {
  text-decoration: none;
}
.b-suggestions_product-picture {
  background: #F2F2F2;
  display: block;
  overflow: hidden;
  padding-bottom: 96.7741935484%;
  position: relative;
  width: 100%;
  max-width: 100%;
  width: 124px;
}
.b-suggestions_product-picture img {
  bottom: 0;
  color: #F2F2F2;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-suggestions_product-info {
  margin: 12px 16px 12px 12px;
}
.b-suggestions_product-title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-suggestions_product:hover .b-suggestions_product-title, .b-suggestions_product.m-focused .b-suggestions_product-title {
  color: #C23D74;
  text-decoration: underline;
}
.b-suggestions_product-price_info {
  margin-right: 8px;
}
.b-suggestions_product-price {
  font-size: 15px;
  margin-bottom: 8px;
}
.b-suggestions_product-price_item {
  color: #ACACAC;
  font-weight: 500;
  text-decoration: line-through;
}
.b-suggestions_product .b-price-item {
  margin-right: 10px;
}
.b-suggestions_product .b-rating-icon {
  min-width: 95px;
}

@media screen and (min-width: 1024px) {
  .b-header_track_order {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-header_track_order {
    cursor: pointer;
    text-decoration: underline;
    align-items: center;
    border-bottom: 1px solid #D2D2D2;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    min-height: 50px;
    padding: 15px 10px 15px 15px;
    text-decoration: none;
    width: 100%;
    box-shadow: none;
    padding: 0 4px;
  }
  @media not all and (pointer: coarse) {
    .b-header_track_order:hover {
      color: #C23D74;
    }
  }
}
.b-header_track_order-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
}

.b-country_selector {
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .b-country_selector {
    align-items: center;
    display: flex;
    position: relative;
  }
}
.b-country_selector-locale {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-country_selector-locale {
    height: 28px;
    padding: 3px 7px 3px 3px;
  }
}
@media screen and (min-width: 1024px) {
  .b-country_selector-locale.m-switcher:hover {
    background: #F2F2F2;
  }
}
.b-country_selector-item {
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-country_selector-switcher {
    cursor: pointer;
    text-decoration: underline;
    align-items: center;
    border-bottom: 1px solid #D2D2D2;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    min-height: 50px;
    padding: 15px 10px 15px 15px;
    text-decoration: none;
    width: 100%;
    box-shadow: none;
  }
  @media not all and (pointer: coarse) {
    .b-country_selector-switcher:hover {
      color: #C23D74;
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-country_selector-switcher {
    border: 1px solid rgba(0, 0, 0, 0.27);
    display: block;
  }
}
.b-country_selector-switcher:hover {
  text-decoration: none;
}
.b-country_selector-locale_language {
  color: #000000;
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
  margin-right: 7px;
}
@media screen and (max-width: 1023px) {
  .b-country_selector-locale_icon {
    margin-left: auto;
  }
}
.b-country_selector-flyout {
  background-color: #FFFFFF;
  border: 1px solid #b3b3b3;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.35);
  color: #000000;
  display: none;
  left: 0;
  position: absolute;
  top: 49px;
  width: 100%;
  z-index: 5;
}
.b-country_selector-flyout[aria-hidden=false] {
  display: block;
}

.b-header_account {
  font-size: 11px;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .b-header_account {
    font-size: 13px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_stuck .b-header_account, .b-header_slim .b-header_account {
    font-size: 11px;
  }
}
.b-header_account-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  margin-bottom: 7px;
}
@media screen and (min-width: 768px) {
  .b-header_account-icon {
    height: 28px;
    width: 28px;
  }
}
@media screen and (min-width: 1367px) {
  .b-header_stuck .b-header_account-icon, .b-header_slim .b-header_account-icon {
    height: 26px;
    margin-bottom: 3px;
    width: 26px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-header_stuck .b-header_account-icon, .b-header_slim .b-header_account-icon {
    margin: 0 4px 0 0;
  }
}
@media screen and (max-width: 1366px) {
  .b-header_stuck .b-header_account-icon, .b-header_slim .b-header_account-icon {
    height: 24px;
    width: 24px;
  }
}
@media screen and (max-width: 1023px) {
  .b-header_stuck .b-header_account-icon, .b-header_slim .b-header_account-icon {
    margin-bottom: 1px;
  }
}
@media screen and (max-width: 767px) {
  .m-skinny_nav .b-header_account-icon {
    height: 24px;
    margin-bottom: 1px;
    width: 24px;
  }
}
.b-header_account-link {
  align-items: center;
  color: #45132C;
  display: flex;
  flex-direction: column;
  line-height: 16px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: color, fill;
}
@media not all and (pointer: coarse) {
  .b-header_account-link:hover {
    opacity: 0.8;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-header_stuck .b-header_account-link, .b-header_slim .b-header_account-link {
    flex-direction: row;
  }
}
.b-header_account-caption {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
  word-break: break-all;
}
@media screen and (max-width: 767px) {
  .b-header_account-caption {
    max-width: 50px;
  }
}
.b-menu-item .b-header_account {
  border-bottom: 1px solid #D2D2D2;
  font-size: 15px;
  font-weight: 600;
}
.b-menu-item .b-header_account-link {
  flex-direction: row;
  padding: 15px 12px;
}
.b-menu-item .b-header_account-icon {
  height: 20px;
  margin: 0 10px 0 0;
  width: 20px;
}
.b-menu-item .b-header_account-caption {
  max-width: 100%;
}
.b-menu-item .b-header_account-icon {
  height: 28px;
  margin: -4px 6px -4px -4px;
  width: 28px;
}

.b-footer-content {
  background-color: #45132C;
}
.b-footer-inner {
  margin: 0 auto;
  max-width: 1360px;
  padding-left: 88px;
  padding-right: 88px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-footer-inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-footer-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .b-footer-inner {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .b-footer-inner {
    padding-top: 36px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .b-footer-inner {
    justify-content: center;
    padding-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer-inner {
    align-items: center;
    flex-direction: column-reverse;
  }
}
.b-footer-sidebar {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 21px;
  min-width: 320px;
}
@media screen and (max-width: 1200px) {
  .b-footer-sidebar {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .b-footer-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 10px;
    order: -1;
    width: 320px;
  }
}
.b-footer-newsletters {
  background-color: #96D6D5;
  border-radius: 2px;
  color: #45132C;
  overflow: hidden;
  padding: 20px 16px;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .b-footer-newsletters {
    flex-shrink: 0;
    min-width: 320px;
  }
}
@media screen and (min-width: 768px) {
  .b-footer-newsletters {
    max-width: 320px;
  }
}
.b-footer-newsletters .g-squiggle {
  opacity: 0;
}
@media screen and (max-width: 1200px) {
  .b-footer-sidebar_content {
    min-width: 260px;
  }
}
.b-footer-locale {
  align-items: center;
  display: flex;
  margin: 20px 0 0;
}
.b-footer-locale_selector {
  background-color: #FFFFFF;
  color: #000000;
  cursor: pointer;
  margin-left: 10px;
}
.b-footer-payment_methods {
  display: inline-flex;
  width: 390px;
}
@media screen and (max-width: 767px) {
  .b-footer-payment_methods {
    max-width: 390px;
    width: auto;
  }
}
@media screen and (min-width: 768px) {
  .b-footer-payment_methods {
    flex-shrink: 0;
  }
}
.b-footer-img {
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .b-footer-img {
    margin-left: 5px;
  }
}
.b-footer-img:first-child {
  margin-left: 0;
}
.b-footer-app {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.25;
  margin-right: 40px;
  min-width: 120px;
  width: 120px;
}
@media screen and (max-width: 767px) {
  .b-footer-app {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .b-footer-app {
    display: flex;
    margin-right: 0;
    width: 250px;
  }
}
@media screen and (min-width: 768px) {
  .b-footer-sidebar .b-footer-app {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .b-footer-sidebar .b-footer-app {
    display: block;
    margin-right: 0;
    width: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .b-footer-app_banners {
    width: 120px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer-app_banners {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}
@media screen and (min-width: 768px) {
  .b-footer-app_banner {
    margin-bottom: 15px;
  }
}
.b-footer-app_image {
  display: block;
}
@media screen and (max-width: 767px) {
  .b-footer-app_image {
    width: 144px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .b-footer-app_qr {
    margin-right: 10px;
    order: -1;
    width: 120px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer-app_qr {
    display: none;
  }
}
.b-footer-qr_image {
  background-color: #FFFFFF;
  border-radius: 10px;
  display: block;
  margin-bottom: 5px;
}
.b-footer-qr_text {
  margin-right: -10px;
}

@media screen and (min-width: 1367px) {
  .b-footer_nav {
    margin-top: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .b-footer_nav {
    display: flex;
    margin-right: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .b-footer_nav {
    padding: 40px 0 20px;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .b-footer_nav {
    max-width: 700px;
    padding-left: 60px;
    padding-right: 60px;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .b-footer_nav-title {
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    min-width: 105px;
    padding-bottom: 8px;
    position: relative;
  }
  .b-footer_nav-title .b-icon_chevron {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .b-footer_nav-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    justify-content: space-between;
    padding: 12px 15px;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #D2D2D2;
  }
}
@media screen and (min-width: 1200px) {
  .b-footer_nav-button {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .b-footer_nav-heading {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .b-footer_nav-content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
    transition-property: height, opacity;
    visibility: hidden;
  }
  .b-footer_nav-content[aria-hidden=false] {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (min-width: 1200px) {
  .b-footer_nav-content {
    height: auto !important;
  }
}
@media screen and (max-width: 1200px) {
  .b-footer_nav-list {
    overflow: hidden;
    padding: 0 15px 20px;
  }
}
.b-footer_nav-item {
  display: block;
  font-size: 11px;
  line-height: 16px;
  margin: 12px 0;
}
.b-footer_nav-link {
  padding: 4px 0;
}
.b-footer_nav-column {
  color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .b-footer_nav-column {
    flex-basis: 25%;
    margin-right: 20px;
  }
}
.b-footer_info {
  background-color: #D2D2D2;
}
.b-footer_info-inner {
  margin: 0 auto;
  max-width: 1360px;
  padding-left: 88px;
  padding-right: 88px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-footer_info-inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-footer_info-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_info-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .b-footer_info-inner {
    flex-direction: column;
  }
}

.b-footer_social {
  margin: 20px 0 0;
}
.b-footer_social-items {
  color: #45132C;
  display: flex;
  margin: 12px 0 0;
}
.b-footer_social-link {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  background-color: #EDB8E4;
  border-radius: 50%;
  margin-right: 20px;
}
@media screen and (max-width: 1200px) {
  .b-footer_social-link:last-child {
    margin-right: 0;
  }
}
.b-footer_social svg {
  fill: #45132C;
}
.b-footer_social image {
  filter: invert(9%) sepia(16%) saturate(7352%) hue-rotate(302deg) brightness(92%) contrast(95%);
}

.b-copyright {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  padding-right: 50px;
}
@media screen and (max-width: 1023px) {
  .b-copyright {
    font-size: 10px;
    margin-bottom: 16px;
    padding-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .b-copyright {
    text-align: center;
  }
}

.b-newsletters-caption {
  margin-bottom: 16px;
}
.b-newsletters-form {
  padding-right: 83px;
  position: relative;
}
.b-newsletters-form[hidden] {
  display: none;
}
.b-newsletters-input {
  appearance: none;
  background: #FFFFFF;
  border: 1px solid #45132C;
  border-radius: 20px;
  box-shadow: none;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 13px;
  height: 40px;
  line-height: 17px;
  padding: 8px 16px;
  width: calc(100% - 10px);
}
.b-newsletters-input::-ms-clear {
  display: none;
}
.b-newsletters-input::placeholder {
  color: #757575;
}
.b-newsletters-message_validation {
  color: #A42015;
  line-height: 1.2;
  margin-top: 8px;
}
.b-newsletters-message_success {
  color: #45132C;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin: 40px 0 5px;
  text-align: center;
}
.b-newsletters-text_success {
  margin-bottom: 40px;
  text-align: center;
}
.b-newsletters-icons_list {
  display: none;
}
.b-newsletters-message_success[hidden] ~ .b-newsletters-icons_list, .b-newsletters-message_success[hidden] ~ .b-newsletters-text_success {
  display: none;
}
.b-newsletters-message_success_announcement {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-newsletters-icon_success {
  position: absolute;
}
.b-newsletters-icon_success.m-first {
  color: #F4F075;
  left: 53px;
  top: -5px;
}
.b-newsletters-icon_success.m-second {
  color: #C23D74;
  right: 0;
  top: 0;
}
.b-newsletters-icon_success.m-third {
  color: #C23D74;
  left: 0;
  top: 75px;
}
.b-newsletters-icon_success.m-fourth {
  color: #F4F075;
  right: 0;
  top: 80px;
}
.b-newsletters-icon_success.m-fifth {
  bottom: 5px;
  color: #F4F075;
  left: 25px;
}
.b-newsletters-icon_success.m-sixth {
  bottom: 0;
  color: #C23D74;
  right: 75px;
}
.b-newsletters-privacy {
  margin: 16px -83px 0 0;
}
.b-newsletters-privacy a {
  cursor: pointer;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  .b-newsletters-privacy a:hover {
    color: #C23D74;
  }
}
.b-newsletters-privacy .b-checkbox-icon {
  min-width: 16px;
}
.b-newsletters-submit {
  position: absolute;
  right: 0;
  text-transform: none;
  top: 0;
  width: 83px;
}
.b-newsletters .b-form_section {
  margin-bottom: 0;
}

.b-notification_panel {
  background: #EDB8E4;
  bottom: 0;
  color: #45132C;
  display: none;
  left: 0;
  padding: 15px 0;
  position: fixed;
  right: 0;
  z-index: 13;
}
.b-notification_panel.m-visible {
  display: block;
}
.b-notification_panel-inner {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  align-items: center;
  display: flex;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-notification_panel-inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-notification_panel-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-notification_panel-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 1023px) {
  .b-notification_panel-inner {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media screen and (min-width: 1024px) {
  .b-notification_panel-inner {
    padding-right: 90px;
  }
}
.b-notification_panel-title {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-notification_panel-description {
  font-size: 15px;
}
@media screen and (min-width: 1024px) {
  .b-notification_panel-description {
    font-size: 13px;
  }
}
.b-notification_panel-link {
  color: inherit;
  text-decoration: none;
}
.b-notification_panel-button {
  margin: 20px 0 0;
}
@media screen and (min-width: 1024px) {
  .b-notification_panel-button {
    margin: 0 20px;
  }
}
.b-notification_panel-button.m-link {
  height: auto;
}
.b-notification_panel-image {
  bottom: 0;
  position: absolute;
  right: 0;
}

.b-footer_trustpilot {
  margin: 0 auto;
  max-width: 1360px;
  padding-left: 88px;
  padding-right: 88px;
  width: 100%;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-footer_trustpilot {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-footer_trustpilot {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_trustpilot {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_trustpilot {
    margin-top: 20px;
  }
}
.b-footer_trustpilot::before {
  background-color: #D2D2D2;
  content: "";
  display: block;
  height: 1px;
  margin-bottom: 20px;
  width: 100%;
}
.b-footer_trustpilot-logo {
  margin: 10px auto;
}

.b-footer_headline-title {
  color: #45132C;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 16px;
}

.b-country_selector-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 28px;
  min-width: 124px;
  padding: 3px 7px 3px 3px;
}
.b-country_selector-label {
  color: #000000;
  font-size: 14px;
  line-height: 16px;
}
.b-country_selector-arrow_icon {
  margin-left: 7px;
  width: 8px;
}
.b-country_selector-icon {
  background-repeat: no-repeat;
  background-size: 100% 49494%;
  border-radius: 1px;
  box-sizing: content-box;
  display: inline-block;
  height: 22px;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  width: 34px;
}
.b-country_selector-button.m-init .b-country_selector-icon {
  background-image: url("images/globale/flags-sprite.png");
}
.b-country_selector-icon_CH, .b-country_selector-icon_NP {
  box-shadow: none !important;
}
.b-country_selector-icon_DZ {
  background-position: center 0.2287%;
}
.b-country_selector-icon_AO {
  background-position: center 0.4524%;
}
.b-country_selector-icon_BJ {
  background-position: center 0.6721%;
}
.b-country_selector-icon_BW {
  background-position: center 0.8958%;
}
.b-country_selector-icon_BF {
  background-position: center 1.1162%;
}
.b-country_selector-icon_BI {
  background-position: center 1.3379%;
}
.b-country_selector-icon_CM {
  background-position: center 1.5589%;
}
.b-country_selector-icon_CV {
  background-position: center 1.7805%;
}
.b-country_selector-icon_CF {
  background-position: center 2.0047%;
}
.b-country_selector-icon_TD {
  background-position: center 2.2247%;
}
.b-country_selector-icon_CD {
  background-position: left 2.4467%;
}
.b-country_selector-icon_DJ {
  background-position: left 2.6674%;
}
.b-country_selector-icon_EG {
  background-position: center 2.8931%;
}
.b-country_selector-icon_GQ {
  background-position: center 3.1125%;
}
.b-country_selector-icon_ER {
  background-position: left 3.3325%;
}
.b-country_selector-icon_ET {
  background-position: center 3.5542%;
}
.b-country_selector-icon_GA {
  background-position: center 3.7759%;
}
.b-country_selector-icon_GM {
  background-position: center 4.0015%;
}
.b-country_selector-icon_GH {
  background-position: center 4.2229%;
}
.b-country_selector-icon_GN {
  background-position: center 4.441%;
}
.b-country_selector-icon_GW {
  background-position: left 4.66663%;
}
.b-country_selector-icon_CI {
  background-position: center 4.8844%;
}
.b-country_selector-icon_KE {
  background-position: center 5.1061%;
}
.b-country_selector-icon_LS {
  background-position: center 5.3298%;
}
.b-country_selector-icon_LR {
  background-position: left 5.5495%;
}
.b-country_selector-icon_LY {
  background-position: center 5.7712%;
}
.b-country_selector-icon_MG {
  background-position: center 5.994%;
}
.b-country_selector-icon_MW {
  background-position: center 6.2156%;
}
.b-country_selector-icon_ML {
  background-position: center 6.4363%;
}
.b-country_selector-icon_MR {
  background-position: center 6.658%;
}
.b-country_selector-icon_MU {
  background-position: center 6.8805%;
}
.b-country_selector-icon_YT {
  background-position: center 7.1038%;
}
.b-country_selector-icon_MA {
  background-position: center 7.3231%;
}
.b-country_selector-icon_MZ {
  background-position: left 7.5448%;
}
.b-country_selector-icon_NA {
  background-position: left 7.7661%;
}
.b-country_selector-icon_NE {
  background-position: center 7.98937%;
}
.b-country_selector-icon_NG {
  background-position: center 8.2099%;
}
.b-country_selector-icon_CG {
  background-position: center 8.4316%;
}
.b-country_selector-icon_RE {
  background-position: center 8.6533%;
}
.b-country_selector-icon_RW {
  background-position: right 8.875%;
}
.b-country_selector-icon_SH {
  background-position: center 9.0967%;
}
.b-country_selector-icon_ST {
  background-position: center 9.32237%;
}
.b-country_selector-icon_SN {
  background-position: center 9.5426%;
}
.b-country_selector-icon_SC {
  background-position: left 9.7628%;
}
.b-country_selector-icon_SL {
  background-position: center 9.9845%;
}
.b-country_selector-icon_SO {
  background-position: center 10.2052%;
}
.b-country_selector-icon_ZA {
  background-position: left 10.4269%;
}
.b-country_selector-icon_SS {
  background-position: left 10.6486%;
}
.b-country_selector-icon_SD {
  background-position: center 10.8703%;
}
.b-country_selector-icon_SR {
  background-position: center 11.0945%;
}
.b-country_selector-icon_SZ {
  background-position: center 11.3135%;
}
.b-country_selector-icon_TG {
  background-position: left 11.5354%;
}
.b-country_selector-icon_TN {
  background-position: center 11.7593%;
}
.b-country_selector-icon_UG {
  background-position: center 11.9799%;
}
.b-country_selector-icon_TZ {
  background-position: center 12.2005%;
}
.b-country_selector-icon_EH {
  background-position: center 12.4222%;
}
.b-country_selector-icon_YE {
  background-position: center 12.644%;
}
.b-country_selector-icon_ZM {
  background-position: center 12.8664%;
}
.b-country_selector-icon_ZW {
  background-position: left 13.0873%;
}
.b-country_selector-icon_AI {
  background-position: center 13.309%;
}
.b-country_selector-icon_AG {
  background-position: center 13.5307%;
}
.b-country_selector-icon_AR {
  background-position: center 13.7524%;
}
.b-country_selector-icon_AW {
  background-position: left 13.9741%;
}
.b-country_selector-icon_BS {
  background-position: left 14.1958%;
}
.b-country_selector-icon_BB {
  background-position: center 14.4175%;
}
.b-country_selector-icon_BQ {
  background-position: center 14.6415%;
}
.b-country_selector-icon_BZ {
  background-position: center 14.8609%;
}
.b-country_selector-icon_BM {
  background-position: center 15.0826%;
}
.b-country_selector-icon_BO {
  background-position: center 15.306%;
}
.b-country_selector-icon_VG {
  background-position: center 15.528%;
}
.b-country_selector-icon_BR {
  background-position: center 15.7496%;
}
.b-country_selector-icon_CA {
  background-position: center 15.9694%;
}
.b-country_selector-icon_KY {
  background-position: center 16.1911%;
}
.b-country_selector-icon_CL {
  background-position: left 16.4128%;
}
.b-country_selector-icon_CO {
  background-position: left 16.6345%;
}
.b-country_selector-icon_KM {
  background-position: center 16.8562%;
}
.b-country_selector-icon_CR {
  background-position: center 17.0779%;
}
.b-country_selector-icon_CU {
  background-position: left 17.2996%;
}
.b-country_selector-icon_CW {
  background-position: center 17.5213%;
}
.b-country_selector-icon_DM {
  background-position: center 17.743%;
}
.b-country_selector-icon_DO {
  background-position: center 17.968%;
}
.b-country_selector-icon_EC {
  background-position: center 18.1864%;
}
.b-country_selector-icon_SV {
  background-position: center 18.4081%;
}
.b-country_selector-icon_FK {
  background-position: center 18.6298%;
}
.b-country_selector-icon_GF {
  background-position: center 18.8515%;
}
.b-country_selector-icon_GL {
  background-position: left 19.0732%;
}
.b-country_selector-icon_GD {
  background-position: center 19.2987%;
}
.b-country_selector-icon_GP {
  background-position: center 19.518%;
}
.b-country_selector-icon_GT {
  background-position: center 19.7383%;
}
.b-country_selector-icon_GY {
  background-position: center 19.96%;
}
.b-country_selector-icon_HT {
  background-position: center 20.1817%;
}
.b-country_selector-icon_HN {
  background-position: center 20.4034%;
}
.b-country_selector-icon_JM {
  background-position: center 20.6241%;
}
.b-country_selector-icon_MQ {
  background-position: center 20.8468%;
}
.b-country_selector-icon_MX {
  background-position: center 21.0685%;
}
.b-country_selector-icon_MS {
  background-position: center 21.2902%;
}
.b-country_selector-icon_NI {
  background-position: center 21.5119%;
}
.b-country_selector-icon_PA {
  background-position: center 21.7336%;
}
.b-country_selector-icon_PY {
  background-position: center 21.9553%;
}
.b-country_selector-icon_PE {
  background-position: center 22.177%;
}
.b-country_selector-icon_PR {
  background-position: left 22.4002%;
}
.b-country_selector-icon_BL {
  background-position: center 22.6204%;
}
.b-country_selector-icon_KN {
  background-position: center 22.8421%;
}
.b-country_selector-icon_LC {
  background-position: center 23.0638%;
}
.b-country_selector-icon_PM {
  background-position: center 23.2855%;
}
.b-country_selector-icon_VC {
  background-position: center 23.5072%;
}
.b-country_selector-icon_SX {
  background-position: left 23.732%;
}
.b-country_selector-icon_TT {
  background-position: center 23.9506%;
}
.b-country_selector-icon_TC {
  background-position: center 24.1723%;
}
.b-country_selector-icon_US {
  background-position: center 24.394%;
}
.b-country_selector-icon_VI {
  background-position: center 24.6157%;
}
.b-country_selector-icon_UY {
  background-position: left 24.8374%;
}
.b-country_selector-icon_VE {
  background-position: center 25.0591%;
}
.b-country_selector-icon_AB {
  background-position: center 25.279%;
}
.b-country_selector-icon_AF {
  background-position: center 25.5025%;
}
.b-country_selector-icon_AZ {
  background-position: center 25.7242%;
}
.b-country_selector-icon_BD {
  background-position: center 25.9459%;
}
.b-country_selector-icon_BT {
  background-position: center 26.1676%;
}
.b-country_selector-icon_BN {
  background-position: center 26.3885%;
}
.b-country_selector-icon_KH {
  background-position: center 26.611%;
}
.b-country_selector-icon_CN {
  background-position: left 26.8327%;
}
.b-country_selector-icon_GE {
  background-position: center 27.0544%;
}
.b-country_selector-icon_HK {
  background-position: center 27.2761%;
}
.b-country_selector-icon_IN {
  background-position: center 27.4978%;
}
.b-country_selector-icon_ID {
  background-position: center 27.7195%;
}
.b-country_selector-icon_JP {
  background-position: center 27.9412%;
}
.b-country_selector-icon_KZ {
  background-position: center 28.1615%;
}
.b-country_selector-icon_LA {
  background-position: center 28.3846%;
}
.b-country_selector-icon_MO {
  background-position: center 28.6063%;
}
.b-country_selector-icon_MY {
  background-position: center 28.829%;
}
.b-country_selector-icon_MV {
  background-position: center 29.0497%;
}
.b-country_selector-icon_MN {
  background-position: left 29.2714%;
}
.b-country_selector-icon_MM {
  background-position: center 29.4931%;
}
.b-country_selector-icon_NP {
  background-position: left 29.7148%;
}
.b-country_selector-icon_KP {
  background-position: left 29.9365%;
}
.b-country_selector-icon_MP {
  background-position: center 30.1582%;
}
.b-country_selector-icon_PW {
  background-position: center 30.3799%;
}
.b-country_selector-icon_PG {
  background-position: center 30.6016%;
}
.b-country_selector-icon_PH {
  background-position: left 30.8233%;
}
.b-country_selector-icon_SG {
  background-position: left 31.045%;
}
.b-country_selector-icon_KR {
  background-position: center 31.2667%;
}
.b-country_selector-icon_LK {
  background-position: right 31.4884%;
}
.b-country_selector-icon_TW {
  background-position: left 31.7101%;
}
.b-country_selector-icon_TJ {
  background-position: center 31.9318%;
}
.b-country_selector-icon_TH {
  background-position: center 32.1535%;
}
.b-country_selector-icon_TL {
  background-position: left 32.3752%;
}
.b-country_selector-icon_TM {
  background-position: center 32.5969%;
}
.b-country_selector-icon_VN {
  background-position: center 32.8186%;
}
.b-country_selector-icon_AL {
  background-position: center 33.0403%;
}
.b-country_selector-icon_AD {
  background-position: center 33.25975%;
}
.b-country_selector-icon_AM {
  background-position: center 33.4837%;
}
.b-country_selector-icon_AT {
  background-position: center 33.7054%;
}
.b-country_selector-icon_BY {
  background-position: left 33.9271%;
}
.b-country_selector-icon_BE {
  background-position: center 34.1488%;
}
.b-country_selector-icon_BA {
  background-position: center 34.3705%;
}
.b-country_selector-icon_BG {
  background-position: center 34.5922%;
}
.b-country_selector-icon_HR {
  background-position: center 34.8139%;
}
.b-country_selector-icon_CY {
  background-position: center 35.0356%;
}
.b-country_selector-icon_CZ {
  background-position: left 35.2555%;
}
.b-country_selector-icon_DK {
  background-position: center 35.479%;
}
.b-country_selector-icon_EE {
  background-position: center 35.7007%;
}
.b-country_selector-icon_FO {
  background-position: center 35.9224%;
}
.b-country_selector-icon_FI {
  background-position: center 36.1441%;
}
.b-country_selector-icon_FR {
  background-position: center 36.3658%;
}
.b-country_selector-icon_DE {
  background-position: center 36.5875%;
}
.b-country_selector-icon_GI {
  background-position: center 36.8092%;
}
.b-country_selector-icon_GR {
  background-position: left 37.0309%;
}
.b-country_selector-icon_HU {
  background-position: center 37.2526%;
}
.b-country_selector-icon_IS {
  background-position: center 37.4743%;
}
.b-country_selector-icon_IE {
  background-position: center 37.696%;
}
.b-country_selector-icon_IM {
  background-position: center 37.9177%;
}
.b-country_selector-icon_IT {
  background-position: center 38.1394%;
}
.b-country_selector-icon_JE {
  background-position: center 38.3611%;
}
.b-country_selector-icon_XK {
  background-position: center 38.5828%;
}
.b-country_selector-icon_LV {
  background-position: center 38.8045%;
}
.b-country_selector-icon_LI {
  background-position: left 39.0262%;
}
.b-country_selector-icon_LT {
  background-position: center 39.2479%;
}
.b-country_selector-icon_LU {
  background-position: center 39.4696%;
}
.b-country_selector-icon_MT {
  background-position: left 39.6913%;
}
.b-country_selector-icon_MD {
  background-position: center 39.913%;
}
.b-country_selector-icon_MC {
  background-position: center 40.1347%;
}
.b-country_selector-icon_ME {
  background-position: center 40.3564%;
}
.b-country_selector-icon_NL {
  background-position: center 40.5781%;
}
.b-country_selector-icon_MK {
  background-position: center 40.7998%;
}
.b-country_selector-icon_NO {
  background-position: center 41.0215%;
}
.b-country_selector-icon_PL {
  background-position: center 41.2432%;
}
.b-country_selector-icon_PT {
  background-position: center 41.4649%;
}
.b-country_selector-icon_RO {
  background-position: center 41.6866%;
}
.b-country_selector-icon_RU {
  background-position: center 41.9083%;
}
.b-country_selector-icon_SM {
  background-position: center 42.13%;
}
.b-country_selector-icon_RS {
  background-position: center 42.3517%;
}
.b-country_selector-icon_SK {
  background-position: center 42.5734%;
}
.b-country_selector-icon_SI {
  background-position: center 42.7951%;
}
.b-country_selector-icon_ES {
  background-position: left 43.0168%;
}
.b-country_selector-icon_SE {
  background-position: center 43.2385%;
}
.b-country_selector-icon_CH {
  background-position: center 43.4602%;
}
.b-country_selector-icon_TR {
  background-position: center 43.6819%;
}
.b-country_selector-icon_UA {
  background-position: center 43.9036%;
}
.b-country_selector-icon_GB {
  background-position: center 44.1253%;
}
.b-country_selector-icon_VA {
  background-position: right 44.347%;
}
.b-country_selector-icon_BH {
  background-position: center 44.5687%;
}
.b-country_selector-icon_IR {
  background-position: center 44.7904%;
}
.b-country_selector-icon_IQ {
  background-position: center 45.0121%;
}
.b-country_selector-icon_IL {
  background-position: center 45.2338%;
}
.b-country_selector-icon_KW {
  background-position: left 45.4555%;
}
.b-country_selector-icon_JO {
  background-position: left 45.6772%;
}
.b-country_selector-icon_KG {
  background-position: center 45.897%;
}
.b-country_selector-icon_LB {
  background-position: center 46.1206%;
}
.b-country_selector-icon_OM {
  background-position: left 46.3423%;
}
.b-country_selector-icon_PK {
  background-position: center 46.561%;
}
.b-country_selector-icon_PS {
  background-position: center 46.7857%;
}
.b-country_selector-icon_QA {
  background-position: center 47.0074%;
}
.b-country_selector-icon_SA {
  background-position: center 47.2291%;
}
.b-country_selector-icon_SY {
  background-position: center 47.4508%;
}
.b-country_selector-icon_AE {
  background-position: center 47.6725%;
}
.b-country_selector-icon_UZ {
  background-position: left 47.8942%;
}
.b-country_selector-icon_AS {
  background-position: right 48.1159%;
}
.b-country_selector-icon_AU {
  background-position: center 48.3376%;
}
.b-country_selector-icon_CX {
  background-position: center 48.5593%;
}
.b-country_selector-icon_CC {
  background-position: center 48.781%;
}
.b-country_selector-icon_CK {
  background-position: center 49.002%;
}
.b-country_selector-icon_FJ {
  background-position: center 49.2244%;
}
.b-country_selector-icon_PF {
  background-position: center 49.4445%;
}
.b-country_selector-icon_GU {
  background-position: center 49.6678%;
}
.b-country_selector-icon_KI {
  background-position: center 49.8895%;
}
.b-country_selector-icon_MH {
  background-position: left 50.1112%;
}
.b-country_selector-icon_FM {
  background-position: center 50.3329%;
}
.b-country_selector-icon_NC {
  background-position: center 50.5546%;
}
.b-country_selector-icon_NZ {
  background-position: center 50.7763%;
}
.b-country_selector-icon_NR {
  background-position: left 50.998%;
}
.b-country_selector-icon_NU {
  background-position: center 51.2197%;
}
.b-country_selector-icon_NF {
  background-position: center 51.4414%;
}
.b-country_selector-icon_WS {
  background-position: left 51.6631%;
}
.b-country_selector-icon_SB {
  background-position: left 51.8848%;
}
.b-country_selector-icon_TK {
  background-position: center 52.1065%;
}
.b-country_selector-icon_TO {
  background-position: left 52.3282%;
}
.b-country_selector-icon_TV {
  background-position: center 52.5499%;
}
.b-country_selector-icon_VU {
  background-position: left 52.7716%;
}
.b-country_selector-icon_WF {
  background-position: center 52.9933%;
}

.b-country_dialog {
  overflow-y: auto;
  transition: none;
}
.b-country_dialog-window {
  background: #FFFFFF url("../images/modal-bg/modal-bg.png") no-repeat;
  min-width: auto;
  width: 320px;
}
.b-country_dialog-label {
  color: #757575;
  display: flex;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.45;
  margin: 16px 0 6px;
}
.b-country_dialog-icon {
  height: 6px;
}
.b-country_dialog-button {
  max-height: 41px;
  max-width: 178px;
}
.b-country_dialog-input {
  color: #2F2F2F;
  font-size: 13px;
}
.b-country_dialog.m-opened {
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  transition-property: visibility, background-color;
}

.b-tag {
  background-color: #398484;
  border-radius: 40px;
  color: #FFFFFF;
  display: inline-flex;
  font-weight: 500;
  height: 40px;
  padding: 12px 25px;
  transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
@media not all and (pointer: coarse) {
  .b-tag:hover {
    background-color: #1C4242;
    text-decoration: none;
  }
}

.b-input_search {
  border-color: #45132C;
  border-radius: 20px 0 0 20px;
  font-size: 15px;
  min-width: 290px;
  padding-left: 20px;
}
@media screen and (min-width: 768px) {
  .b-input_search {
    min-width: 425px;
  }
}
.b-input_search::placeholder {
  color: #757575;
}

/*md

# b-button_multi_state

This button used to have several states, like: "Add to cart" - "Processed" - "Added" and icon of busy state.
It currently used on PDP and Checkout with Adyen integration.

```html_example
<button class="b-button_multi_state m-processing b-button" type="button">
	<svg class="b-button_multi_state-icon" width="19" height="19" viewBox="0 0 19 19" focusable="false">
	    <g fill="currentColor" transform="rotate(-90 9.5 9.5)">
	        <circle cx="15.9" cy="13.3" r="1.1"/>
	        <circle cx="13" cy="16.2" r="1.1"/>
	        <circle cx="16.9" cy="9.1" r="1.1"/>
	        <circle cx="16.1" cy="5.1" r="1.1" opacity=".9"/>
	        <circle cx="13" cy="2" r="1.1" opacity=".8"/>
	        <circle cx="9.1" cy="1.2" r="1.1" opacity=".6"/>
	        <circle cx="5.1" cy="2" r="1.1" opacity=".6"/>
	        <circle cx="2" cy="5.2" r="1.1" opacity=".5"/>
	        <circle cx="1.2" cy="9.1" r="1.1" opacity=".4"/>
	        <circle cx="1.2" cy="9.1" r="1.1" opacity=".4"/>
	        <circle cx="2" cy="13" r="1.1" opacity=".3"/>
	        <circle cx="5.1" cy="16.1" r="1.1" opacity=".2"/>
	        <circle cx="9.1" cy="16.9" r="1.1" opacity=".1"/>
	        <circle cx="9.1" cy="16.9" r="1.1" opacity=".1"/>
	    </g>
	</svg>
	<span>Processing...</span>
</button>
```

*/
.b-button_multi_state {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.b-button_multi_state:disabled, .b-button_multi_state.m-disabled {
  opacity: 1;
}
.b-button_multi_state:disabled:not(.m-processing, .m-no_disabled), .b-button_multi_state.m-disabled:not(.m-processing, .m-no_disabled) {
  background: #D2D2D2;
  border: #D2D2D2;
  color: #757575;
}
.b-dialog.m-quick_view .b-button_multi_state {
  min-height: 46px;
}
.b-button_multi_state.m-processing {
  cursor: wait;
  display: inline-flex;
  pointer-events: none;
}
.b-button_multi_state-icon {
  animation: rotate 1.5s linear infinite;
  display: none;
  margin-right: 8px;
}
.m-processing .b-button_multi_state-icon {
  display: block;
}
.b-button_multi_state.m-processing .b-button_multi_state-icon {
  display: block;
}
.b-button_multi_state-icon_bag {
  margin-right: 6px;
}
.b-button_multi_state-icon_bag svg {
  margin-top: -3px;
  width: 21px;
}
.b-button_multi_state.m-store .b-button_multi_state-icon_bag, .b-button_multi_state.m-processing .b-button_multi_state-icon_bag, .b-button_multi_state:not(.m-no_disabled):disabled .b-button_multi_state-icon_bag, .b-button_multi_state:not(.m-no_disabled).m-disabled .b-button_multi_state-icon_bag {
  display: none;
}

.b-address {
  color: #2F2F2F;
  line-height: 1.5;
}
.b-address-title {
  font-weight: 600;
}
.b-address-subtitle {
  margin: 12px 0 16px;
}
@media screen and (max-width: 1023px) {
  .b-address-type {
    margin-bottom: 16px;
    order: -1;
  }
}
@media screen and (min-width: 1024px) {
  .b-address-type {
    margin: 10px 0 16px;
  }
}
.b-address.m-email {
  margin-top: 8px;
  word-wrap: break-word;
}
.b-address.m-phone {
  margin-top: 6px;
}
.b-summary_group.m-billing .b-address.m-phone {
  display: none;
}
.b-address.m-clickcollect {
  margin-bottom: 10px;
}
@media screen and (max-width: 1023px) {
  .m-checkout_shipping .b-address {
    font-size: 15px;
  }
}

.b-seo_banner {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  margin: 35px auto;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-seo_banner {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-seo_banner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-seo_banner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 768px) {
  .b-seo_banner {
    margin: 50px auto;
  }
}
@media screen and (min-width: 1367px) {
  .b-seo_banner {
    max-width: 1360px;
  }
}
.b-seo_banner-block {
  border-top: 1px solid #D2D2D2;
  padding: 15px 0 30px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-seo_banner-block {
    padding: 20px 0 30px;
  }
}
.b-seo_banner-title {
  color: #2F2F2F;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.25;
  padding: 0 10px;
}
@media screen and (min-width: 768px) {
  .b-seo_banner-title {
    padding: 0 15px;
  }
}
@media screen and (max-width: 1023px) {
  .b-seo_banner-carousel.m-products {
    margin-left: 0;
    margin-right: 0;
  }
}
.b-seo_banner-carousel.m-products .b-carousel-ctrl.m-prev {
  left: -10px;
}
.b-seo_banner-carousel.m-products .b-carousel-ctrl.m-next {
  right: -10px;
}
.b-seo_banner-links {
  display: flex;
  flex-flow: column wrap;
  padding: 0 10px;
}
@media screen and (min-width: 768px) {
  .b-seo_banner-links {
    flex-flow: row nowrap;
    padding: 0 15px;
  }
}
.b-seo_banner-link {
  color: #45132C;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  margin: 30px 0 0;
  padding-right: 15px;
  position: relative;
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .b-seo_banner-link {
    margin: 20px 55px 0 0;
  }
}
@media not all and (pointer: coarse) {
  .b-seo_banner-link:hover {
    text-decoration: none;
  }
}
.b-seo_banner-link_icon {
  position: absolute;
  right: 0;
}
.b-seo_banner .b-product_tile {
  padding-bottom: 0;
}

.b-success_modal-window {
  padding: 10px 30px 59px;
}
.b-success_modal-body {
  margin-top: 10px;
}
.b-success_modal-footer {
  margin-top: 22px;
}
.b-success_modal-btn {
  height: 33px;
  margin: auto;
  min-width: 172px;
}

.b-stock_search-window {
  padding: 20px 15px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-stock_search-window {
    max-width: 820px;
    padding: 30px;
  }
}
.b-stock_search-header {
  margin: 0;
  padding: 0;
  position: relative;
}
.b-stock_search-body {
  font-size: 14px;
  margin: 0;
  text-align: left;
}
.b-stock_search .b-dialog-close {
  height: 32px;
  margin-top: 5px;
  right: 0;
  top: 50%;
  width: 32px;
  z-index: 1;
}
.b-stock_search .b-dialog-close svg {
  height: 18px;
  width: 18px;
}

.b-quick_view-window {
  min-height: 80vh;
  opacity: 0;
  padding: 0 20px 20px;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-quick_view-window {
    min-width: calc(100% - 64px);
  }
}
@media screen and (min-width: 1024px) {
  .b-quick_view-window {
    max-width: 686px;
    padding-bottom: 30px;
    width: 100%;
  }
}
.b-quick_view-header {
  margin-bottom: 20px;
  padding-top: 0;
}
.b-quick_view-body {
  text-align: left;
}
.b-quick_view-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  padding-right: 42px;
}
.b-quick_view-nav_ctrl {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  height: 40px;
  padding: 3px;
}
@media screen and (max-width: 767px) {
  .b-quick_view-nav_ctrl {
    justify-content: center;
    text-align: center;
  }
}
.b-quick_view-nav_ctrl svg {
  margin: 0 2px;
  width: 22px;
}
.b-quick_view.m-idea .b-dialog-close {
  right: 12px;
  top: 11px;
}
.b-quick_view.m-idea .b-quick_view-window {
  min-height: 630px;
  opacity: 1;
  padding: 0 16px 16px;
}
@media screen and (max-width: 767px) {
  .b-quick_view.m-idea .b-quick_view-window {
    width: calc(100% - 30px);
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-quick_view.m-idea .b-quick_view-window {
    width: calc(100% - 64px);
  }
}
@media screen and (min-width: 768px) {
  .b-quick_view.m-idea .b-quick_view-window {
    padding: 0 20px 16px;
  }
}
@media screen and (min-width: 1024px) {
  .b-quick_view.m-idea .b-quick_view-window {
    min-width: 800px;
    width: 800px;
  }
}
.b-quick_view.m-idea .b-quick_view-nav {
  margin: 0 -16px;
}
@media screen and (min-width: 768px) {
  .b-quick_view.m-idea .b-quick_view-nav {
    margin: 0 -20px;
  }
}
.b-quick_view.m-idea .b-quick_view-body, .b-quick_view.m-idea .b-quick_view-header {
  margin: 0;
}

.b-dialog_migrate-window {
  padding: 0 30px 40px;
  width: 400px;
}
@media screen and (max-width: 1023px) {
  .b-dialog_migrate-window {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-dialog_migrate-warn {
  color: #A42015;
  font-size: 12px;
  margin-bottom: 20px;
  padding: 0 30px;
  text-align: center;
}
.b-dialog_migrate-title {
  padding: 0 30px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .b-dialog_migrate-title {
    padding: 0;
  }
}
.b-dialog_migrate-submit {
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 20px;
}

.b-pcipal {
  border: 1px solid #D2D2D2;
  height: 650px;
  width: 500px;
}

.g-badge {
  border-radius: 2px;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
  left: 10px;
  line-height: 1.25;
  max-width: calc(100% - 20px);
  padding: 4px 8px;
  position: absolute;
  top: 10px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .g-badge {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1367px) {
  .g-badge {
    font-size: 18px;
    line-height: 23px;
  }
}
.g-badge-wrapper {
  position: relative;
}
.g-badge.m-corner {
  border-radius: 4px 0 10px;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  max-width: 120px;
  padding: 12px 15px 15px;
  top: 0;
  word-break: break-word;
}
@media screen and (min-width: 768px) {
  .g-badge.m-corner {
    border-radius: 4px 0 20px;
  }
}
.g-badge.m-corner-small {
  border-radius: 4px 0 10px;
  font-weight: 700;
  left: 0;
  min-width: 63px;
  padding: 6px 10px;
  top: 0;
}
@media screen and (min-width: 768px) {
  .g-badge.m-corner-small {
    border-radius: 4px 0 20px;
    font-size: 20px;
    padding: 7px 11px;
  }
}
@media screen and (min-width: 1024px) {
  .g-badge.m-corner-small {
    min-width: 77px;
  }
}
@media screen and (min-width: 1367px) {
  .g-badge.m-corner-small {
    min-width: 103px;
    padding: 15px 17px;
  }
}
.g-badge.m-sale {
  background-color: #A42015;
}
.g-badge.m-new {
  background-color: #0074CC;
}
.g-badge.m-campaign {
  background-color: #398484;
}
.g-badge.m-friday {
  background-color: #45132C;
}
.g-badge.m-soon {
  background-color: #FFFFFF;
  border: 1px solid #0074CC;
  color: #0074CC;
  display: inline-block;
  font-size: 13px;
  left: auto;
  line-height: 14px;
  position: relative;
  top: auto;
  width: fit-content;
}

.g-content_tile {
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  height: 100%;
  overflow: hidden;
}
.g-content_tile-picture {
  display: block;
  font-size: 0;
}
.g-content_tile-picture .g-image {
  width: 100%;
}
.g-content_tile-caption {
  padding: 10px;
}
.g-content_tile-caption.m-promo {
  padding: 15px 20px;
}
.g-content_tile-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
  margin-bottom: 10px;
}
@media screen and (min-width: 1024px) {
  .g-content_tile-title {
    font-size: 24px;
  }
}
.g-content_tile-title.m-promo {
  color: #000000;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
}
.g-content_tile-subtitle.m-promo {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 15px;
}
.g-content_tile-link {
  color: #45132C;
  text-decoration: none;
}
@media not all and (pointer: coarse) {
  .g-content_tile-link:hover {
    text-decoration: underline;
  }
}
.g-content_tile-link.m-bold {
  font-weight: 500;
}
@media not all and (pointer: coarse) {
  .b-highlights-link:hover .g-content_tile-link, .b-featured_category-link:hover .g-content_tile-link, .b-highlights-link:hover .g-content_tile-subtitle, .b-featured_category-link:hover .g-content_tile-subtitle {
    text-decoration: underline;
  }
}
.g-content_tile-actions {
  margin-top: 15px;
}
.g-content_tile.m-horizontal {
  display: flex;
  flex-direction: row-reverse;
}
.g-content_tile.m-horizontal .g-image {
  height: 100%;
}
@media screen and (min-width: 768px) {
  .g-content_tile.m-horizontal .g-content_tile-picture, .g-content_tile.m-horizontal .g-content_tile-caption {
    flex: 0 0 50%;
  }
}
@media screen and (max-width: 767px) {
  .g-content_tile.m-horizontal .g-content_tile-picture {
    flex: 0 0 43%;
  }
}

.g-content_banner {
  border-radius: 4px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  display: grid;
  overflow: hidden;
}
.g-content_banner-caption, .g-content_banner-picture {
  grid-column: 1/2;
  grid-row: 1/2;
}
.g-content_banner-caption {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .g-content_banner-caption {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .g-content_banner-caption {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .g-content_banner-caption {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.g-content_banner-caption.m-caption_left {
  text-align: left;
}
.g-content_banner-caption.m-caption_right {
  text-align: right;
}
.g-content_banner-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
}
@media screen and (min-width: 1024px) {
  .g-content_banner-title {
    font-size: 32px;
  }
}

/*md

# b-carousel (based on scroll)

## Carousel examples

### 1. Hero carousel

```html_example
<div data-widget="carousel" class="b-carousel m-hero">
	<button class="b-carousel-ctrl m-prev" tabindex="-1" aria-hidden="true" title="Previous" data-ref="elemPrevButton" data-event-click="scrollToPrevPage">
		<svg width="40" height="40" viewBox="2 1 40 40" aria-hidden="true" focusable="false">
			<path d="m26.541 8.3536-12.728 12.728 12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
		</svg>
	</button>
	<button class="b-carousel-ctrl m-next" tabindex="-1" aria-hidden="true" title="Next" data-ref="elemNextButton" data-event-click="scrollToNextPage">
		<svg width="40" height="40" viewBox="-1 1 40 40" aria-hidden="true" focusable="false">
			<path d="m13.459 8.3536 12.728 12.728-12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
		</svg>
	</button>
	<div class="b-carousel-track" data-ref="elemCarouselTrack" data-event-scroll.passive="onScroll" data-event-touchstart="onScroll" data-event-mousedown.prevent="onMouseDown" data-event-mouseup="onMouseUp">
		<div class="b-carousel-item">
			<figure class="b-promo_box m-full_bleed m-caption_offcenter">
				<picture class="b-promo_box-picture">
					<img src="../images/guide/examples/hero-carousel-banner-1.jpg?$staticlink$" alt="Girl stand on the beach." loading="eager" width="1600" height="800">
				</picture>
				<figcaption class="b-promo_box-inner">
					<div class="b-promo_box-caption b-promo_caption">
						<h2 class="b-promo_caption-title">Carousel first slide</h2>
						<p class="b-promo_caption-subtitle">Everyone's fallen for boilerplate so we added to her games repertoire.</p>
						<div class="b-promo_caption-actions">
							<a class="b-button" href="#" aria-label="Shop new season at boilerplate">Shop New Season</a>
						</div>
					</div>
				</figcaption>
			</figure>
		</div>
		<div class="b-carousel-item">
			<figure class="b-promo_box m-full_bleed m-caption_offcenter">
				<picture class="b-promo_box-picture">
					<img src="../images/guide/examples/hero-carousel-banner-2.jpg?$staticlink$" alt="Girl with khaki panama." loading="lazy" width="1600" height="800">
				</picture>
				<figcaption class="b-promo_box-inner">
					<div class="b-promo_box-caption b-promo_caption">
						<h2 class="b-promo_caption-title">Carousel second slide</h2>
						<p class="b-promo_caption-subtitle">New playful styles inspired by darts and that staple of a British pub - the fruit machine.</p>
						<div class="b-promo_caption-actions">
							<a class="b-button" href="#" aria-label="Shop new Collection at boilerplate">Shop New Collection</a>
						</div>
					</div>
				</figcaption>
			</figure>
		</div>
		<div class="b-carousel-item">
			<figure class="b-promo_box m-full_bleed m-caption_offcenter">
				<picture class="b-promo_box-picture">
					<img src="../images/guide/examples/hero-carousel-banner-3.jpg?$staticlink$" alt="Girl stand in cloak." loading="lazy" width="1600" height="800">
				</picture>
				<figcaption class="b-promo_box-inner">
					<div class="b-promo_box-caption b-promo_caption">
						<h2 class="b-promo_caption-title">Carousel third slide</h2>
						<div class="b-promo_caption-actions">
							<a class="b-button" href="#" aria-label="Shop new Season at boilerplate">Shop New Season</a>
						</div>
					</div>
				</figcaption>
			</figure>
		</div>
	</div>
</div>
```

### 2. Products carousel

```html_example
<section data-widget="carousel" class="b-carousel m-products">
	<h2 class="b-carousel-title">Look what's new</h2>
	<button class="b-carousel-ctrl m-prev" tabindex="-1" aria-hidden="true" title="Previous" data-ref="elemPrevButton" data-event-click="scrollToPrevPage">
		<svg width="40" height="40" viewBox="2 1 40 40" aria-hidden="true" focusable="false">
			<path d="m26.541 8.3536-12.728 12.728 12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
		</svg>
	</button>
	<button class="b-carousel-ctrl m-next" tabindex="-1" aria-hidden="true" title="Next" data-ref="elemNextButton" data-event-click="scrollToNextPage">
		<svg width="40" height="40" viewBox="-1 1 40 40" aria-hidden="true" focusable="false">
			<path d="m13.459 8.3536 12.728 12.728-12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
		</svg>
	</button>
	<div class="b-carousel-track" data-ref="elemCarouselTrack" data-event-scroll.passive="onScroll" data-event-touchstart="onScroll" data-event-mousedown.prevent="onMouseDown" data-event-mouseup="onMouseUp">
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
	</div>
</section>
<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
	<symbol id="star">
		<path d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
	</symbol>
</svg>
```
*/
.b-carousel {
  position: relative;
}
.b-carousel.m-hybrid::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 60%, rgb(255, 255, 255) 100%);
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
}
.b-carousel.m-hybrid.m-single {
  margin-right: 20px;
  min-width: 342px;
  width: 342px;
}
.b-carousel.m-hybrid.m-single::after {
  display: none;
}
.b-carousel.m-einstein {
  padding-bottom: 40px;
  padding-top: 40px;
}
@media screen and (min-width: 1367px) {
  .b-carousel.m-einstein {
    padding-bottom: 45px;
    padding-top: 45px;
  }
}
@media screen and (max-width: 1023px) {
  .b-carousel.m-einstein {
    padding-bottom: 25px;
    padding-top: 25px;
  }
}
.b-carousel.m-bundle {
  border: 1px solid #F2F2F2;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
}
@media screen and (max-width: 767px) {
  .b-carousel.m-multy {
    margin: 0 15px;
  }
}
.b-carousel-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
  margin-bottom: 13px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .b-carousel-title {
    font-size: 24px;
  }
}
.b-text_block .b-carousel-title {
  margin-bottom: 0;
}
@media screen and (max-width: 1023px) {
  .m-ideas .b-carousel-title {
    font-size: 24px;
  }
}
.b-carousel-callout {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #FFFFFF;
  margin-bottom: 5px;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .b-carousel-callout {
    font-size: 27px;
  }
}
.b-carousel-callout.m-category-grid {
  color: #45132C;
  text-transform: none;
}
.b-carousel-content.m-promotional {
  position: relative;
}
@media screen and (min-width: 768px) {
  .b-carousel-content.m-promotional {
    margin: 0 -8px;
  }
}
.b-carousel-content.m-currated_pd {
  height: 100%;
}
.m-ideas .b-carousel-content {
  margin-bottom: 20px;
}
.b-carousel-track {
  display: flex;
  overflow: hidden;
  overflow-behavior: contain;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
  overflow-scrolling: touch;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  -ms-scroll-chaining: none;
  scrollbar-width: none;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}
.b-carousel-track::-webkit-scrollbar {
  display: none;
}
.b-carousel-track.m-grabbing {
  cursor: grab;
  scroll-behavior: auto;
  user-select: none;
}
.b-carousel-track.m-grabbing::before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.b-carousel-track.m-no_animate {
  scroll-behavior: initial;
}
.b-carousel-track.m-promotional, .b-carousel-track.m-currated {
  border-radius: 4px 4px 16px 16px;
  gap: 16px;
  margin: -10px 0;
  padding: 0 0 10px;
}
@media screen and (max-width: 767px) {
  .b-carousel-track.m-promotional, .b-carousel-track.m-currated {
    gap: 0;
  }
}
.b-carousel-track.m-currated {
  gap: 16px;
  margin: -10px 0;
  padding: 10px 0;
}
@media screen and (min-width: 768px) {
  .b-carousel-track.m-currated {
    gap: 8px;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel-track.m-currated {
    margin: 0;
    padding: 8px 0;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel-track.m-currated_pd {
    display: grid;
    gap: 20px 8px;
    grid-auto-columns: 40%;
    grid-auto-flow: column dense;
    grid-template-rows: 1fr 1fr;
    height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel-track.m-currated_pd {
    gap: 8px;
  }
}
.b-carousel-track.m-lozenges {
  gap: 16px;
  margin-top: 12px;
}
.b-carousel-item {
  scroll-snap-align: start;
}
.b-carousel-item.m-hybrid {
  margin-right: 5px;
  max-width: 55%;
  min-width: 55%;
  position: relative;
}
.b-carousel-item.m-hybrid:hover .b-product_gallery-zoom {
  opacity: 1;
}
.b-carousel-item.m-hybrid[role=button] {
  cursor: url("./images/zoom-in.png"), zoom-in;
}
.b-carousel-item.m-hybrid .b-product_image {
  background: #F2F2F2;
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.b-carousel-item.m-hybrid .b-product_image-img {
  color: #F2F2F2;
  display: block;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .m-single .b-carousel-item.m-hybrid {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
}
.b-carousel-item.m-promotional {
  min-width: 34%;
  width: 34%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-carousel-item.m-promotional {
    min-width: 50%;
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel-item.m-promotional {
    min-width: 100%;
    width: 100%;
  }
}
.b-carousel-item.m-bundle {
  min-width: 100%;
  width: 100%;
}
.b-carousel-item.m-currated {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  min-width: 40%;
  width: 40%;
}
@media screen and (max-width: 767px) {
  .b-carousel-item.m-currated {
    display: flex;
    flex-direction: row;
    gap: 0;
    min-width: 100%;
    width: 100%;
  }
}
.b-carousel-item.m-currated_pd {
  min-width: 100%;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .b-carousel-item.m-currated_pd {
    margin: 0;
    min-width: calc(50% - 4px);
    width: calc(50% - 4px);
  }
}
.b-carousel-item.m-currated_pd > div {
  height: 100%;
}
@media screen and (max-width: 767px) {
  .b-carousel-item_inner {
    margin-right: 8px;
    min-width: calc(50% - 8px);
    width: calc(50% - 8px);
  }
}
.b-carousel-ctrl {
  appearance: none;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #D2D2D2;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  color: #45132C;
  cursor: pointer;
  display: none;
  height: 50px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, opacity;
  user-select: none;
  width: 50px;
  z-index: 2;
}
@media not all and (pointer: coarse) {
  .b-carousel-ctrl:hover {
    background-color: #FFFFFF;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel-ctrl {
    height: 40px;
    width: 40px;
  }
  .b-carousel-ctrl svg {
    height: 19px;
    width: 19px;
  }
}
.b-carousel-ctrl.m-prev {
  left: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-category_tile .b-carousel-ctrl.m-prev {
    left: -25px;
  }
}
@media screen and (max-width: 767px) {
  .b-category_tile .b-carousel-ctrl.m-prev {
    left: -15px;
  }
}
.b-carousel-ctrl.m-next {
  right: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-category_tile .b-carousel-ctrl.m-next {
    right: -25px;
  }
}
@media screen and (max-width: 767px) {
  .b-category_tile .b-carousel-ctrl.m-next {
    right: -15px;
  }
}
.b-carousel-ctrl.m-bundle {
  margin: 0 -40px;
}
@media screen and (max-width: 767px) {
  .b-carousel-ctrl.m-bundle {
    margin: 0 -35px;
  }
}
.b-carousel-ctrl.m-hybrid {
  margin-top: 0;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .b-carousel-ctrl.m-hybrid {
    height: 33px;
    width: 33px;
  }
  .b-carousel-ctrl.m-hybrid svg {
    width: 16px;
  }
}
.b-carousel-ctrl.m-promotional, .m-promotional .b-carousel-ctrl, .b-carousel-ctrl.m-currated {
  background-color: transparent;
  border: none;
  box-shadow: none;
  height: 100%;
  margin-top: 0;
  right: -15px;
}
@media screen and (max-width: 767px) {
  .b-carousel-ctrl.m-promotional, .m-promotional .b-carousel-ctrl, .b-carousel-ctrl.m-currated {
    width: 40px;
  }
  .b-carousel-ctrl.m-promotional svg, .m-promotional .b-carousel-ctrl svg, .b-carousel-ctrl.m-currated svg {
    height: 18px;
    position: relative;
    width: 18px;
  }
}
.b-carousel-ctrl.m-promotional svg, .m-promotional .b-carousel-ctrl svg, .b-carousel-ctrl.m-currated svg {
  position: relative;
  z-index: 1;
}
.b-carousel-ctrl.m-promotional::after, .m-promotional .b-carousel-ctrl::after, .b-carousel-ctrl.m-currated::after {
  background-color: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  content: "";
  display: block;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
@media screen and (max-width: 767px) {
  .b-carousel-ctrl.m-promotional::after, .m-promotional .b-carousel-ctrl::after, .b-carousel-ctrl.m-currated::after {
    height: 40px;
  }
}
.b-carousel-ctrl.m-promotional::before, .m-promotional .b-carousel-ctrl::before, .b-carousel-ctrl.m-currated::before {
  background: linear-gradient(to left, var(--bg-layout-color, #FFFFFF) 0%, transparent 100%);
  content: "";
  height: 100%;
  margin: 0 8px;
  position: absolute;
  right: 13%;
  top: 50%;
  transform: translateY(-50%);
  width: 72px;
  z-index: -1;
}
.b-carousel-ctrl.m-prev.m-promotional, .m-promotional .b-carousel-ctrl.m-prev {
  left: -15px;
  right: auto;
}
.b-carousel-ctrl.m-prev.m-promotional::before, .m-promotional .b-carousel-ctrl.m-prev::before {
  background: linear-gradient(to right, var(--bg-layout-color, #FFFFFF) 0%, transparent 100%);
  left: 13%;
}
.b-comparison .b-carousel-ctrl {
  top: 175px;
}
.b-carousel-ctrl.m-currated {
  right: -24px;
}
@media screen and (max-width: 767px) {
  .b-carousel-ctrl.m-currated {
    right: -15px;
  }
}
.b-carousel-ctrl.m-currated::before {
  height: calc(100% + 20px);
  margin: 0;
  right: 48%;
  width: 35px;
}
@media screen and (max-width: 767px) {
  .b-carousel-ctrl.m-currated::before {
    right: 38%;
  }
}
.b-carousel-ctrl.m-currated svg {
  position: relative;
}
@media screen and (max-width: 767px) {
  .l-grid_layout .b-carousel-ctrl.m-currated::before {
    height: calc(100% - 8px);
  }
}
.b-carousel-ctrl.m-prev.m-currated {
  left: -19px;
}
@media screen and (max-width: 767px) {
  .b-carousel-ctrl.m-prev.m-currated {
    left: -15px;
  }
}
.b-carousel-ctrl.m-prev.m-currated::before {
  background: linear-gradient(to right, var(--bg-layout-color, #FFFFFF) 0%, transparent 100%);
  left: 38%;
}
.b-rounded_tiles .b-carousel-ctrl {
  top: 95px;
}
.b-carousel.m-no_scroll .b-carousel-ctrl, .b-carousel-content.m-no_scroll .b-carousel-ctrl, .b-carousel-ctrl[disabled], .b-carousel-content.m-inited .b-carousel-ctrl[disabled] {
  opacity: 0;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .b-carousel.m-no_scroll .b-carousel-ctrl, .b-carousel-content.m-no_scroll .b-carousel-ctrl, .b-carousel-ctrl[disabled], .b-carousel-content.m-inited .b-carousel-ctrl[disabled] {
    display: none;
  }
}
.b-carousel.m-inited .b-carousel-ctrl, .b-carousel-content.m-inited .b-carousel-ctrl {
  display: block;
}
.b-carousel-page {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  height: 20px;
  line-height: 20px;
  position: relative;
  text-align: center;
  width: 20px;
}
.b-carousel-page::before {
  background-color: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  content: "";
  display: inline-block;
  height: 8px;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: width, height, margin;
  width: 8px;
}
.b-carousel-page.m-current::before {
  background-color: #45132C;
  content: "";
  height: 14px;
  margin-left: -7px;
  margin-top: -7px;
  width: 14px;
}
.b-carousel-description {
  font-size: 15px;
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .b-carousel-description {
    margin-left: 50px;
    margin-right: 50px;
  }
}
.m-ideas .b-carousel-description {
  margin-top: 24px;
}
.b-carousel-viewall_wrapper {
  padding-top: 15px;
  text-align: center;
}
.b-carousel-viewall {
  padding: 0 25px;
}
.b-carousel.m-products {
  margin: 0 12px;
}
@media screen and (max-width: 1023px) {
  .b-carousel.m-products {
    margin-left: 12px;
    margin-right: 12px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-pdp-slots .b-carousel.m-products {
    margin-left: 12px;
    margin-right: 12px;
  }
}
@media screen and (max-width: 1023px) {
  .l-cart-slots .b-carousel.m-products {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.b-plp_no_results .b-carousel.m-products {
  margin-bottom: 0;
}
.b-carousel.m-products .b-carousel-title {
  margin-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .b-carousel.m-products .b-carousel-title {
    margin-bottom: 18px;
  }
}
.b-carousel.m-products .b-carousel-title .g-squiggle {
  z-index: -1;
}
.b-carousel.m-products .b-carousel-content {
  position: relative;
}
@media screen and (min-width: 1024px) {
  .b-carousel.m-products .b-carousel-content {
    margin-left: -11px;
    margin-right: -11px;
  }
}
.b-carousel.m-products .b-carousel-track {
  padding: 8px 0;
}
.b-carousel.m-products .b-carousel-ctrl {
  height: 50px;
  transform: translateY(0);
  width: 50px;
}
.b-carousel.m-products .b-carousel-ctrl svg {
  height: 24px;
  width: 24px;
}
.b-carousel.m-products .b-carousel-ctrl.m-prev {
  left: -15px;
}
@media screen and (max-width: 767px) {
  .b-carousel.m-products .b-carousel-ctrl.m-prev {
    left: -24px;
  }
}
.b-carousel.m-products .b-carousel-ctrl.m-next {
  right: -15px;
}
@media screen and (max-width: 767px) {
  .b-carousel.m-products .b-carousel-ctrl.m-next {
    right: -24px;
  }
}
.b-carousel.m-products .b-carousel-item {
  max-width: 20%;
  min-width: 20%;
  padding: 0 11px;
}
@media screen and (max-width: 1023px) {
  .b-carousel.m-products .b-carousel-item {
    padding: 0 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-carousel.m-products .b-carousel-item {
    max-width: 33.33%;
    min-width: 33.33%;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel.m-products .b-carousel-item {
    max-width: 50%;
    min-width: 50%;
  }
}
.b-carousel.m-carousel-idea .b-product_tile {
  padding-bottom: 0;
}
.b-carousel.m-carousel-idea .b-carousel-item {
  max-width: 25%;
  min-width: 25%;
  padding: 0 11px;
}
@media screen and (max-width: 1023px) {
  .b-carousel.m-carousel-idea .b-carousel-item {
    padding: 0 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-carousel.m-carousel-idea .b-carousel-item {
    max-width: 33.33%;
    min-width: 33.33%;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel.m-carousel-idea .b-carousel-item {
    max-width: 100%;
    min-width: 100%;
  }
}
.b-carousel.m-carousel-comparison .b-carousel-item_this {
  background-color: #F5E0B8;
  border-radius: 2px 2px 0 0;
  font-size: 11px;
  height: 15px;
  left: 10px;
  line-height: 15px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: -15px;
  width: calc(100% - 20px);
}
@media screen and (max-width: 767px) {
  .b-carousel.m-carousel-comparison .b-carousel-item_this {
    left: 5px;
    right: 5px;
    width: calc(100% - 10px);
  }
}
.b-carousel.m-hero {
  margin: 0 auto;
  max-width: 1920px;
}
.b-carousel.m-hero .b-carousel-ctrl.m-prev {
  left: 40px;
}
.b-carousel.m-hero .b-carousel-ctrl.m-next {
  right: 40px;
}
.b-carousel.m-hero .b-carousel-item {
  max-width: 100%;
  min-width: 100%;
}
.b-carousel.m-lozenges .b-carousel-ctrl {
  margin-top: 0;
}
.b-carousel.m-lozenges .b-carousel-ctrl.m-prev {
  left: auto;
  transform: translate(-50%, -50%);
}
.b-carousel.m-lozenges .b-carousel-ctrl.m-next {
  right: 0;
  transform: translate(50%, -50%);
}
.b-carousel.m-rounded_tiles .b-carousel-ctrl {
  margin-top: 25px;
  top: auto;
  transform: initial;
}
.b-carousel.m-set_items .b-carousel-title {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .b-carousel.m-set_items .b-carousel-title {
    font-size: 17px;
    margin-bottom: 16px;
  }
}
.b-carousel.m-set_items .b-carousel-content {
  margin-left: -4px;
  margin-right: -4px;
  position: relative;
}
.b-carousel.m-set_items .b-carousel-item {
  max-width: 33.33%;
  min-width: 33.33%;
  padding: 0 4px;
}
@media screen and (min-width: 1024px) {
  .b-carousel.m-set_items .b-carousel-item {
    max-width: 16.6666666667%;
    min-width: 16.6666666667%;
  }
}
.b-carousel.m-set_items .b-carousel-ctrl {
  margin-top: 0;
  top: 40%;
}
@media screen and (min-width: 768px) {
  .b-carousel.m-set_items .b-carousel-ctrl {
    top: 32%;
  }
}
.b-carousel.m-set_items .b-carousel-ctrl.m-prev {
  left: -10px;
}
.b-carousel.m-set_items .b-carousel-ctrl.m-next {
  right: -10px;
}
.b-carousel.m-set_items .b-product_tile {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-size: 13px;
  padding-bottom: 0;
}
.b-carousel.m-set_items .b-product_tile-info {
  padding: 5px 8px;
}
@media screen and (min-width: 768px) {
  .b-carousel.m-set_items .b-product_tile-info {
    padding: 10px 8px;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel.m-set_items .b-product_tile-link {
    -webkit-line-clamp: 3; /* number of lines to show */
    line-height: 1.45;
  }
}
.b-carousel.m-category-carousel .b-carousel-ctrl {
  background: none;
  border: none;
  color: #FFFFFF;
  font-size: 10px;
  margin-top: 0;
}
.b-carousel.m-category-carousel .b-carousel-ctrl.m-prev {
  left: -38px;
  text-align: right;
}
.b-carousel.m-category-carousel .b-carousel-ctrl.m-next {
  right: -38px;
  text-align: left;
}
.b-carousel.m-category-carousel .b-carousel-ctrl svg {
  position: relative;
  width: 15px;
}
.b-carousel.m-category-carousel .b-carousel-content {
  padding: 0 10px;
  position: relative;
}
.b-carousel.m-category-carousel .b-carousel-callout {
  margin-bottom: 10px;
}
.b-carousel.m-category-carousel .b-carousel-item {
  min-width: 12.5%;
  width: 12.5%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-carousel.m-category-carousel .b-carousel-item {
    min-width: 20%;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel.m-category-carousel .b-carousel-item {
    min-width: 33.3333333333%;
  }
}
.b-carousel.m-carousel-comparison .b-product_tile {
  border-radius: 2px;
  box-shadow: none;
  padding-bottom: 0;
  text-align: center;
}
.b-carousel.m-carousel-comparison .b-product_tile-title {
  font-size: 13px;
}
.b-carousel.m-carousel-comparison .b-price,
.b-carousel.m-carousel-comparison .b-rating {
  justify-content: center;
}
.b-carousel.m-carousel-comparison .b-carousel-track {
  padding: 15px 0 0;
}
.b-carousel.m-carousel-comparison .b-carousel-item {
  padding: 0 10px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .b-carousel.m-carousel-comparison .b-carousel-item {
    padding: 0 5px;
  }
}
.b-carousel.m-carousel-comparison .b-carousel-item.m-current-item .b-product_tile {
  border: 2px solid #F5E0B8;
}
.b-carousel.m-carousel-wishlist {
  margin-bottom: 20px;
}
.b-carousel.m-category-grid .b-category_item-img {
  filter: none;
}
@media screen and (min-width: 1367px) {
  .b-carousel.m-category-grid .b-category_item-img {
    height: 48px;
    width: 48px;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel.m-category-grid .b-category_item-img {
    height: 29px;
    width: 29px;
  }
}
.b-carousel.m-category-grid .b-category_item {
  color: #45132C;
  max-width: 100px;
}
.b-carousel.m-category-grid .b-category_item-title {
  font-weight: 500;
}
.b-carousel.m-category-grid .b-carousel-item {
  min-width: 13.5135135135%;
  width: 13.5135135135%;
}
@media screen and (min-width: 1367px) {
  .b-carousel.m-category-grid .b-carousel-item {
    min-width: 11.9760479042%;
    width: 11.9760479042%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-carousel.m-category-grid .b-carousel-item {
    min-width: 22.2222222222%;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel.m-category-grid .b-carousel-item {
    min-width: 28.5714285714%;
  }
}
.b-carousel.m-category-grid .b-carousel-ctrl {
  box-shadow: none;
  color: #45132C;
}
.b-carousel.m-category-grid .b-carousel-ctrl::before {
  background: linear-gradient(to left, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 120px;
  position: absolute;
  right: calc(100% - 15px);
  top: 50%;
  transform: translateY(-50%);
  width: 55px;
}
@media screen and (max-width: 767px) {
  .b-carousel.m-category-grid .b-carousel-ctrl::before {
    width: 70px;
  }
}
.b-carousel.m-category-grid .b-carousel-ctrl.m-prev::before {
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  left: calc(100% - 15px);
}
.m-ideas .b-carousel.m-products .b-carousel-item {
  max-width: 25%;
  min-width: 25%;
  padding: 0 11px;
}
@media screen and (max-width: 1023px) {
  .m-ideas .b-carousel.m-products .b-carousel-item {
    padding: 0 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .m-ideas .b-carousel.m-products .b-carousel-item {
    max-width: 33.33%;
    min-width: 33.33%;
  }
}
@media screen and (max-width: 767px) {
  .m-ideas .b-carousel.m-products .b-carousel-item {
    max-width: 100%;
    min-width: 100%;
  }
}
.m-promotional .b-carousel.m-products .b-carousel-item {
  max-width: 36%;
  min-width: 36%;
  padding: 0 8px;
}
@media screen and (max-width: 767px) {
  .m-promotional .b-carousel.m-products .b-carousel-item {
    max-width: 100%;
    min-width: 100%;
    padding: 0;
  }
}
.m-promotional .b-carousel.m-products .b-carousel-ctrl {
  height: 100%;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .m-promotional .b-carousel.m-products .b-carousel-ctrl {
    width: 40px;
  }
  .m-promotional .b-carousel.m-products .b-carousel-ctrl svg {
    height: 18px;
    position: relative;
    width: 18px;
  }
}
.m-promotional .b-carousel.m-products .b-carousel-ctrl::before {
  z-index: -1;
}
.m-promotional .b-carousel.m-products .b-carousel-ctrl.m-prev {
  left: -15px;
}
.m-promotional .b-carousel.m-products .b-carousel-ctrl.m-next {
  right: -15px;
}
.l-pdp-slots.m-top-slots .b-carousel-title:not(.m-standard) {
  font-size: 20px;
  margin-bottom: 5px;
}
.l-pdp-slots.m-top-slots:not(.m-standard_header) .b-carousel-title.m-standard {
  display: none;
}
@media screen and (max-width: 767px) {
  .l-pdp-slots.m-top-slots .b-carousel-description {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.b-hero_carousel {
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
  position: relative;
}
.b-hero_carousel.m-currated {
  display: flex;
}
.b-hero_carousel-track {
  display: flex;
  overflow: hidden;
}
.b-hero_carousel-track.m-grabbing {
  cursor: grab;
  user-select: none;
}
.b-hero_carousel-track.m-grabbing::before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.m-currated_main .b-hero_carousel-track {
  height: 100%;
}
.b-hero_carousel-item {
  height: 100%;
  left: 0;
  min-width: 100%;
  top: 0;
  width: 100%;
  will-change: transform;
}
.b-hero_carousel-item:not(:first-child) {
  visibility: hidden;
}
.b-hero_carousel-item.m-currated {
  display: flex;
}
.b-hero_carousel.m-initialized .b-hero_carousel-item {
  overflow: hidden;
  position: absolute;
  transition: cubic-bezier(0.56, 0.03, 0.47, 0.98) 0.8s;
  transition-property: transform, visibility;
  visibility: hidden;
}
.b-hero_carousel.m-initialized .b-hero_carousel-item.m-prev {
  transform: translateX(-100%);
}
.b-hero_carousel.m-initialized .b-hero_carousel-item.m-next {
  transform: translateX(100%);
}
.b-hero_carousel.m-initialized .b-hero_carousel-item.m-current {
  position: static;
  transform: translateX(0);
  visibility: visible;
}
.b-hero_carousel-navigation {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 40px;
  bottom: 10px;
  display: none;
  left: 50%;
  padding: 6px 10px;
  position: absolute;
  transform: translateX(-50%);
  z-index: 1;
}
.b-hero_carousel-ctrl {
  appearance: none;
  border: none;
  color: #000000;
  cursor: pointer;
  height: 22px;
  text-align: center;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, opacity;
  user-select: none;
  width: 22px;
}
.b-hero_carousel-ctrl svg {
  width: 22px;
}
.b-hero_carousel-pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 10px;
  opacity: 0;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  visibility: hidden;
}
@media screen and (max-width: 767px) {
  .b-hero_carousel-pagination {
    transform: none;
  }
}
.b-hero_carousel-pagination.m-initialized {
  opacity: 1;
  visibility: visible;
}
.b-hero_carousel-pagination_dots {
  display: flex;
}
.b-hero_carousel-pagination_dot {
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  fill: #ACACAC;
  height: 14px;
  margin-right: 16px;
  padding: 0;
  position: relative;
  width: 14px;
}
@media not all and (pointer: coarse) {
  .b-hero_carousel-pagination_dot:hover {
    fill: #45132C;
  }
}
.b-hero_carousel-pagination_dot.m-current {
  fill: #45132C;
}
.b-hero_carousel-pagination_dot:last-child {
  margin-right: 0;
}
.b-hero_carousel-pagination_dot[aria-disabled=true] {
  cursor: initial;
}
.b-hero_carousel-pagination_svg {
  display: block;
}
.b-hero_carousel-autoplay {
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  margin-right: 10px;
  padding: 0;
}
.b-hero_carousel-autoplay.m-initialized {
  display: block;
}
.b-hero_carousel-autoplay.m-animated {
  animation: hero-carousel-progress linear;
}
.b-hero_carousel-autoplay_svg {
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 1;
}
.b-hero_carousel-autoplay_progress {
  stroke: #ACACAC;
  stroke-width: 2;
}
.b-hero_carousel-autoplay.m-animated .b-hero_carousel-autoplay_progress {
  stroke: #45132C;
}
.b-hero_carousel-autoplay_progress_back {
  stroke: #ACACAC;
  stroke-width: 2;
}
.b-hero_carousel-autoplay_play, .b-hero_carousel-autoplay_pause {
  display: block;
  fill: #45132C;
  opacity: 1;
  transition: opacity ease 0.2s;
}
@media not all and (pointer: coarse) {
  .b-hero_carousel-autoplay:hover .b-hero_carousel-autoplay_play, .b-hero_carousel-autoplay:hover .b-hero_carousel-autoplay_pause {
    fill: #45132C;
  }
}
.b-hero_carousel-autoplay[aria-pressed=false] .b-hero_carousel-autoplay_play {
  opacity: 0;
}
.b-hero_carousel-autoplay[aria-pressed=true] .b-hero_carousel-autoplay_pause {
  opacity: 0;
}
.b-hero_carousel.m-initialized .b-hero_carousel-navigation {
  display: flex;
}

.b-video_module {
  margin-bottom: 30px;
  padding-top: 20px;
  position: relative;
}
.b-video_module.m-html5 {
  margin: 0;
  padding: 0;
}
.b-video_module-title {
  color: #45132C;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .b-video_module-title {
    font-size: 24px;
  }
}
.b-video_module-main {
  margin: 15px 0;
  text-align: center;
}
.b-video_module-wrapper {
  position: relative;
}
.b-video_module-wrapper .b-play_button {
  background: none;
}
.b-video_module-wrapper .b-play_button.m-hide {
  display: none;
}
.b-video_module-item {
  margin: 0 8px;
  position: relative;
  width: calc(25% - 16px);
}
@media screen and (max-width: 767px) {
  .b-video_module-item {
    margin: 8px 0;
    min-width: calc(50% - 8px);
    width: calc(50% - 8px);
  }
}
.b-video_module-item.m-current {
  border: 2px solid #C23D74;
  border-radius: 2px;
}
.b-video_module-item.m-current .b-play_button {
  display: none;
}
.b-video_module-item .g-content_tile-subtitle {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.b-video_module-thumbs {
  margin: 0 -8px;
}
@media screen and (max-width: 767px) {
  .b-video_module-thumbs {
    margin: 20px 0 0;
    overflow: hidden;
    padding: 0 10px;
  }
}
.b-video_module-video {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.b-video_module-video img {
  margin: 0 auto;
}
.b-video_module-playing {
  background: rgba(194, 61, 116, 0.75);
  bottom: 0;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  padding: 5px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.m-current .b-video_module-playing {
  display: block !important;
}
.b-video_module-content {
  color: #45132C;
  margin-left: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
@media screen and (min-width: 1367px) {
  .b-video_module-content {
    margin-left: 210px;
  }
}
@media screen and (max-width: 767px) {
  .b-video_module-content {
    margin-left: 40px;
  }
}
.b-video_module-content.m-rounded, .b-video_module-content.m-overlay {
  background: rgba(245, 238, 228, 0.75);
  border-radius: 0 35px 35px 0;
  margin: 0;
  max-width: 475px;
  padding: 24px 40px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-video_module-content.m-rounded, .b-video_module-content.m-overlay {
    border-radius: 0 25px 25px 0;
    max-width: 340px;
    padding: 20px 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-video_module-content.m-rounded, .b-video_module-content.m-overlay {
    border-radius: 0 20px 20px 0;
    max-width: 255px;
    padding: 15px 30px;
  }
}
@media screen and (max-width: 767px) {
  .b-video_module-content.m-rounded, .b-video_module-content.m-overlay {
    border-radius: 20px 20px 0 0;
    bottom: 0;
    left: 50%;
    max-width: 330px;
    padding: 15px;
    text-align: center;
    top: auto;
    transform: translateX(-50%) translateY(0);
    width: max-content;
  }
}
@media screen and (orientation: landscape) and (max-width: 767px) {
  .b-video_module-content.m-rounded, .b-video_module-content.m-overlay {
    border-radius: 0 20px 20px 0;
    bottom: auto;
    left: auto;
    max-width: 255px;
    padding: 15px 30px;
    text-align: left;
    top: 50%;
    transform: translateY(-50%);
  }
}
.b-video_module-content.m-overlay {
  align-items: flex-start;
  background: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .b-video_module-content.m-overlay {
    align-items: center;
    justify-content: flex-end;
  }
}
@media screen and (orientation: landscape) and (max-width: 767px) {
  .b-video_module-content.m-overlay {
    align-items: flex-start;
    justify-content: center;
  }
}
.b-video_module-content.m-overlay::before {
  background: linear-gradient(to right, rgba(245, 238, 228, 0.75) 0%, rgba(245, 238, 228, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
  content: "";
  display: block;
  inset: 0 0 0 0;
  position: absolute;
  width: 420px;
  z-index: -1;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-video_module-content.m-overlay::before {
    width: 356px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-video_module-content.m-overlay::before {
    width: 285px;
  }
}
@media screen and (max-width: 767px) {
  .b-video_module-content.m-overlay::before {
    background: linear-gradient(to top, rgba(245, 238, 228, 0.75) 0%, rgba(245, 238, 228, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
    height: 180px;
    left: -50%;
    top: auto;
    width: 200%;
  }
}
@media screen and (orientation: landscape) and (max-width: 767px) {
  .b-video_module-content.m-overlay::before {
    background: linear-gradient(to right, rgba(245, 238, 228, 0.75) 0%, rgba(245, 238, 228, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
    height: 100%;
    inset: 0 0 0 0;
    width: 285px;
  }
}
.b-video_module-content.m-overlay.m-dark {
  background: none;
}
.b-video_module-content.m-dark {
  background: rgba(69, 19, 44, 0.75);
  color: #F5EEE4;
}
.b-video_module-content.m-dark::before {
  background: linear-gradient(to right, rgba(69, 19, 44, 0.75) 0%, rgba(69, 19, 44, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
}
@media screen and (max-width: 767px) {
  .b-video_module-content.m-dark::before {
    background: linear-gradient(to top, rgba(69, 19, 44, 0.75) 0%, rgba(69, 19, 44, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
  }
}
@media screen and (orientation: landscape) and (max-width: 767px) {
  .b-video_module-content.m-dark::before {
    background: linear-gradient(to right, rgba(69, 19, 44, 0.75) 0%, rgba(69, 19, 44, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
  }
}
.b-video_module-heading {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 8px;
  max-height: 120px;
  overflow: hidden;
}
@media screen and (min-width: 1367px) {
  .b-video_module-heading {
    font-size: 48px;
    margin-bottom: 16px;
    max-height: 180px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-video_module-heading {
    font-size: 40px;
    margin-bottom: 16px;
    max-height: 150px;
  }
}
@media screen and (max-width: 767px) {
  .b-video_module-heading {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (orientation: landscape) and (max-width: 767px) {
  .b-video_module-heading {
    font-size: 28px;
    margin-left: 0;
    margin-right: 0;
  }
}
.b-video_module-subheading {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 700;
}
@media screen and (min-width: 1024px) {
  .b-video_module-subheading {
    font-size: 24px;
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 767px) {
  .b-video_module-subheading {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (orientation: landscape) and (max-width: 767px) {
  .b-video_module-subheading {
    font-size: 18px;
    margin-left: 0;
    margin-right: 0;
  }
}
.b-video_module-button {
  padding: 0 28px;
}
.m-dark .b-video_module-button {
  background-color: #F5EEE4;
  border-color: #F5EEE4;
  color: #45132C;
}
.m-dark .b-video_module-button:hover {
  background-color: #45132C;
  border-color: #45132C;
  color: #F5EEE4;
}
.b-video_module-player {
  aspect-ratio: 16/5;
  background: var(--poster) no-repeat;
  background-size: cover;
  display: block;
  margin: 0 auto;
  max-width: 1440px;
}
@media screen and (max-width: 767px) {
  .b-video_module-player {
    background: var(--poster-mobile) no-repeat;
    background-size: cover;
    height: 100vw;
    max-height: none;
  }
}
@media screen and (orientation: landscape) and (max-width: 767px) {
  .b-video_module-player {
    aspect-ratio: 16/5;
    background: var(--poster) no-repeat;
    background-size: cover;
    height: auto;
  }
}
.b-video_module-controls {
  left: 0;
  padding: 18px;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-video_module-play {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  position: absolute;
  right: 18px;
  top: 18px;
  width: 40px;
}
@media screen and (max-width: 767px) {
  .b-video_module-play {
    right: 10px;
    top: 10px;
  }
}
.b-video_module-play_svg {
  fill: transparent;
}
.b-video_module-progress {
  stroke: #45132C;
  stroke-width: 2;
}
.b-video_module-pause_icon {
  display: none;
}
.m-playing .b-video_module-pause_icon {
  display: block;
}
.m-playing .b-video_module-play_icon {
  display: none;
}
.b-video_module .b-play_button svg {
  height: 100px;
  width: 100px;
}
.b-video_module .b-carousel-track {
  margin: -8px 0;
  padding: 8px 0;
}
@media screen and (max-width: 767px) {
  .b-video_module .b-carousel-track {
    gap: 16px;
  }
}
.b-video_module .b-carousel-ctrl {
  border: none;
  box-shadow: none;
  height: 40px;
  margin-top: 0;
  width: 40px;
}
.b-video_module .b-carousel-ctrl::after {
  background-color: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-video_module .b-carousel-ctrl::before {
  background: linear-gradient(to left, var(--bg-layout-color, #FFFFFF) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 300px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: -1;
}
.b-video_module .b-carousel-ctrl.m-prev {
  left: 0;
}
.b-video_module .b-carousel-ctrl.m-prev::before {
  background: linear-gradient(to right, var(--bg-layout-color, #FFFFFF) 0%, rgba(255, 255, 255, 0) 100%);
  left: 8px;
}
.b-video_module .b-carousel-ctrl.m-next {
  right: 0;
}
.b-video_module .b-carousel-ctrl svg {
  height: 19px;
  position: relative;
  width: 19px;
  z-index: 1;
}

.b-socialproof {
  background-color: rgba(242, 242, 242, 0.66);
  border-radius: 2px;
  bottom: 60px;
  cursor: default;
  left: 50%;
  max-width: 414px;
  padding: 10px 40px 10px 10px;
  position: absolute;
  transform: translateX(-50%);
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .b-socialproof {
    max-width: 100%;
    padding: 5px 35px 5px 10px;
  }
}
.l-pdp.m-idea .b-socialproof {
  display: none;
}
.b-socialproof.m-tile {
  bottom: 0;
  max-width: 100%;
  padding: 5px;
  text-align: center;
  width: calc(100% - 20px);
}
@media screen and (max-width: 1023px) {
  .b-socialproof.m-tile {
    bottom: 30px;
  }
}
.b-socialproof-close {
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 7px;
}
.b-socialproof-close svg {
  height: 18px;
  width: 18px;
}
@media screen and (max-width: 767px) {
  .b-socialproof-close svg {
    height: 16px;
    width: 16px;
  }
}
.b-socialproof-content {
  align-items: center;
  display: flex;
  font-size: 19px;
  justify-content: center;
  line-height: 28.5px;
}
@media screen and (max-width: 767px) {
  .b-socialproof-content {
    font-size: 15px;
    line-height: 21px;
  }
}
@media screen and (max-width: 767px) {
  .b-socialproof-content.m-tile {
    font-size: 14px;
    line-height: 20px;
  }
}
.b-socialproof-content.m-tile svg {
  height: 33px;
  width: 33px;
}
@media screen and (max-width: 767px) {
  .b-socialproof-content.m-tile svg {
    height: 22px;
    width: 22px;
  }
}
.b-socialproof-title {
  font-weight: 600;
}
.b-socialproof-inner {
  padding-left: 20px;
  width: max-content;
}
@media screen and (max-width: 767px) {
  .b-socialproof-inner {
    padding-left: 10px;
  }
}
.b-socialproof-inner.m-tile {
  padding-left: 5px;
}

.b-cart_product {
  display: block;
  position: relative;
}
.b-cart_product + .b-cart_product {
  margin-top: 20px;
}
.b-cart_product-caption {
  max-width: 100%;
  width: 100%;
}
.b-cart_product-head_row {
  display: flex;
}
.b-cart_product-item {
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  color: #000000;
  display: grid;
  grid-template-areas: "details details" "misc misc" "bundle bundle";
  grid-template-columns: 148px 1fr;
  position: relative;
}
@media screen and (min-width: 768px) {
  .b-cart_product-item {
    grid-template-areas: "details details misc" "image bundle bundle";
    grid-template-columns: 166px 1fr 60px;
  }
}
.b-cart_product-item + .b-cart_product-item {
  margin-top: 8px;
}
.b-cart_product-item.m-alert {
  border-color: #A42015;
}
.b-cart_product-item.m-recent {
  grid-template-columns: 106px 1fr;
}
@media screen and (min-width: 768px) {
  .b-cart_product-item.m-recent {
    grid-template-columns: 106px 1fr calc(53% - 106px);
  }
}
.b-cart_product-head_cell {
  font-size: 15px;
  font-weight: 400;
  padding: 0 0 10px;
  text-align: left;
}
.b-cart_product-head_cell.m-details {
  width: calc(47% + 106px);
}
@media screen and (min-width: 1367px) {
  .b-cart_product-head_cell.m-details {
    padding-left: 24px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-cart_product-head_cell.m-details {
    padding-left: 14px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-cart_product-head_cell.m-details {
    width: calc(47% + 60px);
  }
}
.b-cart_product-head_cell.m-total {
  margin-left: auto;
  text-align: right;
}
@media screen and (min-width: 1367px) {
  .b-cart_product-head_cell.m-total {
    padding-right: 24px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-cart_product-head_cell.m-total {
    padding-right: 14px;
  }
}
.b-cart_product-cell {
  display: block;
  padding-bottom: 10px;
  padding-top: 10px;
}
@media screen and (max-width: 767px) {
  .b-cart_product-cell {
    padding-top: 0;
  }
}
.b-cart_product-cell.m-recent {
  padding-bottom: 15px;
  padding-top: 15px;
}
.b-cart_product-body {
  display: block;
}
.b-cart_product-image {
  grid-area: image;
}
.b-cart_product-image_link {
  display: block;
}
.b-cart_product-picture {
  background: #F2F2F2;
  display: block;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  border-radius: 2px;
}
.b-cart_product-picture img {
  bottom: 0;
  color: #F2F2F2;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-cart_product-details {
  display: grid;
  font-size: 13px;
  gap: 0 15px;
  grid-area: details;
  grid-template-areas: "image details" "image promo" "image age" "message message";
  grid-template-columns: 148px 1fr;
  line-height: 1.54;
  padding: 10px 5px 5px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .b-cart_product-details {
    grid-template-areas: "image details" "image promo" "image age" "image message";
    grid-template-columns: 166px 1fr;
    padding: 10px;
  }
}
.b-cart_product-details.m-recent {
  grid-template-areas: "image details" "promo promo" "age age" "message message";
  grid-template-columns: 76px 1fr;
  padding: 15px 10px 15px 15px;
}
@media screen and (min-width: 768px) {
  .b-cart_product-details.m-recent {
    grid-template-areas: "image details" "image promo" "image age" "image message";
  }
}
.b-cart_product-details_inner {
  grid-area: details;
}
.b-cart_product-misc {
  display: flex;
  grid-area: misc;
  justify-content: flex-end;
  padding-right: 10px;
}
@media screen and (max-width: 767px) {
  .b-cart_product-misc {
    display: block;
    margin-top: -20px;
    padding-bottom: 0;
    padding-left: 168px;
  }
}
.b-cart_product-misc.m-recent {
  padding-right: 15px;
}
@media screen and (max-width: 767px) {
  .b-cart_product-misc.m-recent {
    margin-top: 0;
    padding: 15px 15px 0;
  }
}
@media screen and (min-width: 768px) {
  .b-cart_product-misc_inner {
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .b-cart_product-misc_inner {
    margin-bottom: -5px;
  }
}
.b-cart_product-misc_inner.m-recent {
  display: grid;
  gap: 12px;
  grid-template-areas: "qty badge" "msg total" "actions actions";
  margin-bottom: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-cart_product-misc_inner.m-recent {
    gap: 0;
    grid-template-areas: "qty total" "msg msg" "actions actions";
  }
}
.b-cart_product-title {
  font-weight: 500;
  margin-bottom: 3px;
}
@media screen and (max-width: 767px) {
  .b-cart_product-title {
    padding-right: 25px;
  }
}
.m-recent .b-cart_product-title {
  margin-bottom: 8px;
}
.b-cart_product-title a {
  cursor: pointer;
  text-decoration: underline;
  text-decoration: none;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: color;
}
@media not all and (pointer: coarse) {
  .b-cart_product-title a:hover {
    color: #C23D74;
  }
}
.b-cart_product-qty_wrap {
  margin-top: 8px;
  width: 75px;
}
@media screen and (min-width: 768px) {
  .b-cart_product-qty_wrap {
    margin-bottom: 10px;
  }
}
.b-cart_product-qty_wrap.m-recent {
  grid-area: qty;
  margin-top: 0;
  width: 91px;
}
.b-cart_product-qty {
  position: relative;
}
.b-cart_product-qty.m-preselected {
  font-size: 13px;
}
.b-cart_product-availability {
  grid-area: msg;
}
@media screen and (max-width: 767px) {
  .b-cart_product-availability {
    margin-top: 10px;
  }
}
.b-cart_product-price {
  display: flex;
}
.b-cart_product-price.m-total {
  font-size: 17px;
  justify-content: flex-end;
}
.b-cart_product-price .b-price {
  flex-wrap: nowrap;
}
@media screen and (max-width: 767px) {
  .b-cart_product-price .b-price {
    height: 18px;
  }
}
@media screen and (max-width: 767px) {
  .b-cart_product-price .b-price-subtotal {
    bottom: 0;
    display: block;
    position: absolute;
    right: 0;
  }
  .m-recent .b-cart_product-price .b-price-subtotal {
    display: flex;
    position: static;
  }
}
@media screen and (min-width: 768px) {
  .b-cart_product-price .b-price-subtotal_item {
    display: inline-block;
    vertical-align: top;
  }
  .m-recent .b-cart_product-price .b-price-subtotal_item {
    display: block;
  }
}
.b-cart_product-promotion {
  grid-area: promo;
}
@media screen and (min-width: 768px) {
  .b-cart_product-promotion .b-promotion:first-child .b-promotion-message {
    margin-top: 0;
  }
}
.b-cart_product-promotion .b-promotion-message {
  margin: 5px 0;
}
.b-cart_product-edit.m-link {
  border: none;
  color: #45132C;
  margin-top: -13px;
  padding: 0;
}
.b-cart_product-actions {
  color: #757575;
  display: flex;
  grid-area: actions;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .b-cart_product-actions {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 768px) {
  .b-cart_quickview .b-cart_product-actions, .l-cart .b-cart_product-actions {
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .b-cart_product-actions.m-recent {
    margin-top: 15px;
  }
}
.b-cart_product-remove {
  cursor: pointer;
  text-decoration: underline;
  color: #757575;
  font-size: 15px;
  text-decoration: none;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: color;
}
@media not all and (pointer: coarse) {
  .b-cart_product-remove:hover {
    color: #C23D74;
  }
}
@media screen and (min-width: 768px) {
  .b-cart_product-remove {
    font-size: 11px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .b-cart_product-remove {
    padding: 7px 10px 10px 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .m-recent .b-cart_product-remove {
    padding: 0;
    position: static;
  }
}
.b-cart_product-button_icon {
  animation-duration: 0.23s;
  animation-fill-mode: forwards;
  animation-name: animation-svg-chevron-up;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: #45132C;
  padding-left: 5px;
}
.b-cart_product-button_icon svg {
  height: 10px;
  width: 16px;
}
.b-cart_product-icon svg {
  width: 16px;
}
.m-recent .b-cart_product-icon {
  margin-right: 6px;
}
.m-recent .b-cart_product-icon svg {
  width: 13px;
}
.b-cart_product-icon_label {
  display: none;
}
.m-recent .b-cart_product-icon_label {
  display: inline;
}
.b-cart_product-msg {
  color: #A42015;
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin: 5px 0 20px;
}
.b-cart_product-badge {
  background-color: #398484;
  border-radius: 2px;
  color: #FFFFFF;
  display: inline-block;
  grid-area: badge;
  margin: 5px 0;
  padding: 2px 8px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .b-cart_product-badge.m-desktop {
    display: none;
  }
}
.m-recent .b-cart_product-badge.m-desktop {
  margin: 15px 0;
}
@media screen and (min-width: 768px) {
  .b-cart_product-badge.m-mobile {
    display: none;
  }
}
.m-recent .b-cart_product-badge.m-mobile {
  align-self: center;
  justify-self: end;
  margin: 5px 0;
}
@media screen and (min-width: 768px) {
  .b-cart_product-total {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}
.b-cart_product-total.m-recent {
  grid-area: total;
  position: static;
}
.b-cart_product-bundle {
  grid-area: bundle;
  padding: 0 10px 10px;
}
@media screen and (min-width: 768px) {
  .b-cart_product-bundle {
    padding-left: 25px;
  }
}
.b-cart_product-hidden {
  margin-top: 20px;
}
.b-cart_product-wishlist_wrapper {
  height: 25px;
}
@media screen and (max-width: 767px) {
  .b-cart_product-wishlist_wrapper {
    padding-bottom: 20px;
  }
}
.b-cart_product-button {
  border-bottom: 1px solid #D2D2D2;
  color: #2F2F2F;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 8px 8px;
  text-align: left;
  width: 100%;
}
.b-cart_product-button.m-active .b-cart_product-button_icon {
  animation-name: animation-svg-chevron-down;
}
.b-cart_product .b-evoucher {
  margin-bottom: 25px;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .b-cart_product .b-evoucher {
    margin-bottom: 5px;
  }
}
.b-cart_product .b-price-subtotal_item {
  line-height: 1;
}
.b-cart_product .b-wishlist_button {
  bottom: 10px;
  font-size: 13px;
  left: 190px;
  position: absolute;
}
.b-cart_product .b-wishlist_button.m-simple {
  color: #757575;
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .b-cart_product .b-wishlist_button.m-simple {
    bottom: 4px;
    font-size: 15px;
    left: 168px;
  }
}
@media screen and (max-width: 767px) {
  .b-cart_product .b-wishlist_button-label {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .m-recent .b-cart_product .b-wishlist_button-label {
    display: inline;
  }
}
@media not all and (pointer: coarse) {
  .b-cart_product .b-wishlist_button[aria-pressed=true]:hover .b-wishlist_button-icon {
    color: #C23D74;
    fill: #C23D74;
  }
}
.b-cart_product .m-recent .b-wishlist_button.m-simple {
  position: static;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .b-cart_product .m-recent .b-wishlist_button.m-simple {
    font-size: 11px;
  }
}

.b-cart_quickview {
  margin-bottom: 24px;
}
.b-cart_quickview-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
  margin-bottom: 10px;
  margin-bottom: 13px;
  text-align: left;
}
.b-cart_quickview-actions {
  font-size: 15px;
  margin-top: 24px;
}
@media screen and (min-width: 1024px) {
  .b-cart_quickview-actions {
    display: flex;
    justify-content: space-between;
  }
}
.b-cart_quickview-button {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-cart_quickview-button {
    width: calc(50% - 12px);
  }
}
.b-cart_quickview-button + .b-cart_quickview-button {
  margin-top: 20px;
}
@media screen and (min-width: 1024px) {
  .b-cart_quickview-button + .b-cart_quickview-button {
    margin-top: 0;
    order: -1;
  }
}
@media screen and (min-width: 1024px) {
  .b-cart_quickview-slots {
    margin: 0 50px;
  }
  .b-cart_quickview-slots .b-carousel .b-carousel-item {
    max-width: 33.33%;
    min-width: 33.33%;
  }
}
.b-cart_quickview-slots > div {
  min-height: 365px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-cart_quickview-slots > div {
    min-height: 410px;
  }
}
.b-cart_quickview-slots > div:empty {
  position: relative;
}
.b-cart_quickview-slots > div:empty::before, .b-cart_quickview-slots > div:empty::after {
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-cart_quickview-slots > div:empty::before {
  background-color: #FFFFFF;
}
.b-cart_quickview-slots > div:empty::after {
  animation: loader 1.1s infinite ease;
  border-radius: 50%;
  display: block;
  font-size: 10px;
  height: 1em;
  margin: auto;
  transform: translateZ(0);
  width: 1em;
}
.b-cart_quickview-slots > div:empty[aria-busy=true] {
  cursor: wait;
  pointer-events: none;
}
.b-cart_quickview-slots > div:empty[aria-busy=true]::before, .b-cart_quickview-slots > div:empty[aria-busy=true]::after {
  opacity: 1;
}
.b-cart_quickview-slots > div:empty::after {
  opacity: 1;
}
.b-cart_quickview-slots .b-slot_bg {
  overflow: visible;
}
.b-cart_quickview-slots .b-carousel.m-products {
  margin: 0;
}
.b-cart_quickview-slots .b-carousel.m-products .b-carousel-title {
  font-size: 20px;
  margin-bottom: 0;
}
.b-cart_quickview-slots .b-carousel.m-products .b-product_tile {
  padding-bottom: 40px;
}
.b-cart_quickview-slots .b-carousel.m-products .b-product_tile-cta {
  display: flex;
}

/* stylelint-disable */
.globale_popup_wrapper a.changeShipping,
input.backToShop.glDefaultBtn,
.glPopupContent .texts,
.glTitle,
.globale_popup_wrapper .SubheaderOfInfo.Switcher,
select#gle_selectedCountry,
select#gle_selectedCurrency,
.glMainContent.Switcher .caption,
.globale_popup_wrapper input.glDefaultBtn.glSaveBtn,
.globale_popup_wrapper .HeaderOfInfo.Switcher,
select#gle_selectedMerchantCurrency {
  font-family: "Poppins Font", "Helvetica", sans-serif !important;
}

/* stylelint-enable */
#FreeShippingBanner {
  background-color: #45132C;
  color: #FFFFFF;
  display: none;
  font-size: 13px;
  margin-bottom: 10px;
  padding: 5px 0;
  text-align: center;
}

.b-ugc {
  margin: 30px auto;
}
@media screen and (min-width: 1024px) {
  .b-ugc {
    margin: 50px auto;
  }
}
.l-static_page .b-ugc {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  padding-bottom: 30px;
  padding-top: 30px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-static_page .b-ugc {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-static_page .b-ugc {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-static_page .b-ugc {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .l-static_page .b-ugc {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
.l-clp .b-ugc {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  padding-bottom: 30px;
  padding-top: 30px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-clp .b-ugc {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-clp .b-ugc {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-clp .b-ugc {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .l-clp .b-ugc {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
.l-clp .b-ugc-inner {
  margin: 0 auto;
  max-width: 1029px;
}
@media screen and (min-width: 1367px) {
  .l-pdp .b-ugc {
    width: 83.3333333333%;
  }
}

.b-category_tile {
  margin-bottom: -25px;
  margin-top: 5px;
}
@media screen and (min-width: 1367px) {
  .b-category_tile {
    max-width: 1360px;
  }
}
@media screen and (max-width: 767px) {
  .b-category_tile {
    margin-bottom: -22px;
    margin-top: 10px;
  }
}
.b-category_tile-item {
  border: 0.5px solid #D2D2D2;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  cursor: pointer;
  flex-basis: 15%;
  margin-bottom: 16px;
  margin-inline-end: 16px;
  min-width: 15%;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-category_tile-item {
    margin-right: 10px;
    min-width: 24%;
  }
}
@media screen and (max-width: 767px) {
  .b-category_tile-item {
    min-width: 48%;
  }
}
.b-category_tile-item:last-child {
  margin-inline-end: 0;
}
.m-alternative_category_tiles .b-category_tile-item.m-category-tiles {
  margin-bottom: 0;
  min-height: 120px;
  min-width: calc(14.2857142857% - 16px);
  width: calc(14.2857142857% - 16px);
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .m-alternative_category_tiles .b-category_tile-item.m-category-tiles {
    min-width: calc(28.5714285714% - 8px);
    width: calc(28.5714285714% - 8px);
  }
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile-item.m-category-tiles {
    margin-bottom: 0;
    min-width: calc(50% - 8px);
    width: calc(50% - 8px);
  }
}
.b-category_tile-link {
  display: block;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}
@media screen and (min-width: 1367px) {
  .b-category_tile-link {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .b-category_tile-link strong {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.b-category_tile-img {
  min-height: 130px;
  min-width: 100%;
}
.b-category_tile-subtitle {
  color: #2F2F2F;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 8px;
}
.b-header_category .b-category_tile {
  margin-bottom: -4px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_category .b-category_tile {
    margin-right: -32px;
  }
}
@media screen and (max-width: 767px) {
  .b-header_category .b-category_tile {
    margin: 10px -15px -2px 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_category .b-category_tile-wrapper {
    gap: 16px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-header_category .b-category_tile-wrapper {
    gap: 10px;
    padding-right: 15px;
  }
}
.b-header_category .b-category_tile-item {
  flex-basis: 12%;
  margin-bottom: 5px;
  min-width: 12%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_category .b-category_tile-item {
    margin-inline-end: 0;
    min-width: 20.5%;
  }
}
@media screen and (max-width: 767px) {
  .b-header_category .b-category_tile-item {
    margin-inline-end: 0;
    min-width: 42%;
  }
}
.b-header_category .b-category_tile-subtitle strong {
  font-weight: 500;
}
.b-header_category .b-category_tile .b-carousel-ctrl::before {
  content: url("../images/icons/arrow-right.svg");
  display: flex;
  height: 17px;
  justify-content: center;
  width: 100%;
}
.b-header_category .b-category_tile .b-carousel-ctrl.m-next {
  margin: 0;
  right: -25px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_category .b-category_tile .b-carousel-ctrl.m-next {
    right: 25px;
  }
}
@media screen and (max-width: 767px) {
  .b-header_category .b-category_tile .b-carousel-ctrl.m-next {
    right: 10px;
  }
}
.b-header_category .b-category_tile .b-carousel-ctrl.m-prev {
  left: -25px;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .b-header_category .b-category_tile .b-carousel-ctrl.m-prev {
    left: 10px;
  }
}
.b-header_category .b-category_tile .b-carousel-ctrl.m-prev::before {
  transform: rotate(180deg);
}
.b-header_category .b-category_tile .b-carousel-ctrl svg {
  display: none;
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile {
    margin-bottom: 0;
    margin-right: -15px;
  }
}
.m-alternative_category_tiles .b-category_tile-item {
  border-radius: 20px;
  flex-basis: 190px;
  margin-inline-end: 19px;
  min-width: 190px;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile-item {
    flex-basis: auto;
    margin-bottom: 0;
    margin-inline-end: 15px;
    min-width: calc(50% - 15px);
    width: calc(50% - 15px);
  }
}
.m-alternative_category_tiles .b-category_tile-item:last-child {
  margin-inline-end: 0;
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile-item:last-child {
    margin-inline-end: 15px;
  }
}
.m-alternative_category_tiles .b-category_tile-img {
  border-radius: 19px;
}
.m-alternative_category_tiles .b-category_tile-subtitle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  color: #45132C;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  line-height: 30px;
  margin: auto;
  max-width: 90%;
  padding: 5px 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: fit-content;
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile-subtitle {
    font-size: 17px;
    line-height: 1.5;
  }
}
.m-alternative_category_tiles .b-category_tile-link {
  text-decoration: none;
}
.m-alternative_category_tiles .b-category_tile .b-carousel-ctrl {
  background: none;
  border: none;
  box-shadow: none;
  height: 100%;
  margin: 0;
  top: 0;
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile .b-carousel-ctrl {
    width: 40px;
  }
  .m-alternative_category_tiles .b-category_tile .b-carousel-ctrl svg {
    height: 26px;
    width: 26px;
  }
}
.m-alternative_category_tiles .b-category_tile .b-carousel-ctrl svg {
  position: relative;
  z-index: 1;
}
.m-alternative_category_tiles .b-category_tile .b-carousel-ctrl::after {
  background-color: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  content: "";
  display: block;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile .b-carousel-ctrl::after {
    height: 40px;
    width: 40px;
  }
}
.m-alternative_category_tiles .b-category_tile .b-carousel-ctrl::before {
  background: linear-gradient(to left, var(--bg-layout-color, #FFFFFF) 0%, transparent 100%);
  content: "";
  height: 100%;
  position: absolute;
  right: 48%;
  top: 0;
  width: 70px;
  z-index: -1;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .m-alternative_category_tiles .b-category_tile .b-carousel-ctrl::before {
    border-radius: 0;
    right: 49%;
  }
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile .b-carousel-ctrl::before {
    right: 10px;
  }
}
.m-alternative_category_tiles .b-category_tile .b-carousel-ctrl.m-category-tiles {
  top: 50%;
}
.m-alternative_category_tiles .b-category_tile .b-carousel-ctrl.m-prev {
  left: -24px;
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile .b-carousel-ctrl.m-prev {
    left: -15px;
  }
}
.m-alternative_category_tiles .b-category_tile .b-carousel-ctrl.m-prev::before {
  background: linear-gradient(to right, var(--bg-layout-color, #FFFFFF) 0%, transparent 100%);
  left: 48%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .m-alternative_category_tiles .b-category_tile .b-carousel-ctrl.m-prev::before {
    border-radius: 0;
    left: 48%;
  }
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile .b-carousel-ctrl.m-prev::before {
    left: 10px;
  }
}
.m-alternative_category_tiles .b-category_tile .b-carousel-ctrl.m-next {
  right: -25px;
}
@media screen and (max-width: 767px) {
  .m-alternative_category_tiles .b-category_tile .b-carousel-ctrl.m-next {
    right: 0;
  }
}
.b-category_tile .g-badge {
  font-size: 13px;
  line-height: 1.25;
}

:root {
  --page_visibility: visible;
}

/* stylelint-disable */
.osano-cm-dialog {
  border: 1px solid #000000;
}

.osano-cm-button--type_save,
.osano-cm-close,
.osano-cm-dialog__list,
.osano-cm-widget {
  display: none;
}

.osano-cm-content__message {
  padding-bottom: 10px;
}

/* stylelint-enable */
/*md

# Apple pay button

Part of the styling of Apple pay button is implemented by Demandware. Those styles inserted throgh
isactivedatahead tag. [Please see](https://confluence.ontrq.com/display/ACDC/ApplePay+button+styling)

We overwrite Demandware styles and provide modern approach for button styling and localization
possibilities.

 */
.b-apple_pay {
  margin: 20px 0 0;
}
.b-apple_pay.m-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.b-apple_pay-button.b-apple_pay-button, .b-apple_pay-button.b-apple_pay-button:hover, .b-apple_pay-button.b-apple_pay-button:active {
  background-size: 75% 52%;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.b-apple_pay-button, .b-apple_pay-button.b-apple_pay-button:hover, .b-apple_pay-button.b-apple_pay-button:active {
    appearance: -apple-pay-button;
    background: none;
    border: none;
    -apple-pay-button-style: white-outline;
  }
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.b-apple_pay-button::after {
    content: "";
  }
}
.b-apple_pay-button.dw-apple-pay-logo-white {
  -apple-pay-button-style: #FFFFFF;
}
.b-apple_pay-button.dw-apple-pay-logo-white.dw-apple-pay-border {
  -apple-pay-button-style: white-outline;
}
.b-apple_pay-button.m-checkout {
  margin-bottom: 22px;
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.m-checkout, .b-apple_pay-button.m-checkout:hover, .b-apple_pay-button.m-checkout:active {
    -apple-pay-button-type: order;
  }
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.m-pdp, .b-apple_pay-button.m-pdp:hover, .b-apple_pay-button.m-pdp:active {
    -apple-pay-button-type: buy;
  }
}
.b-apple_pay.m-disabled .b-apple_pay-button {
  background-color: #D2D2D2;
  border-color: #D2D2D2;
  color: #757575;
  pointer-events: none;
}
.b-apple_pay-description {
  margin: 24px 0 20px;
}
.b-apple_pay-help {
  color: #2F2F2F;
  font-size: 12px;
  margin-top: 8px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-proceed_checkout .b-apple_pay {
    flex: 1;
    margin: 0;
  }
}

.b-wishlist_button {
  align-items: center;
  cursor: pointer;
  display: flex;
  fill: none;
  font-size: 14px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: color, fill;
}
.b-wishlist_button.m-simple {
  cursor: pointer;
  text-decoration: underline;
  font-size: 15px;
  text-decoration: none;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: color;
}
@media not all and (pointer: coarse) {
  .b-wishlist_button.m-simple:hover {
    color: #C23D74;
  }
}
@media screen and (min-width: 768px) {
  .b-wishlist_button.m-simple {
    font-size: 11px;
  }
}
.b-wishlist_button[aria-busy=true] {
  cursor: wait;
  pointer-events: none;
}
.b-wishlist_button[aria-pressed=true] {
  color: #45132C;
  fill: #45132C;
}
.b-wishlist_button[aria-pressed=true] .b-wishlist_button-label.m-not-added {
  display: none;
}
.b-wishlist_button[aria-pressed=false] .b-wishlist_button-label.m-added {
  display: none;
}
.b-product_details.m-idea .b-wishlist_button[aria-pressed=true] {
  color: #FFFFFF;
  fill: #FFFFFF;
}
.b-wishlist_button-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: color, fill;
}
.b-wishlist_button-icon.m-simple {
  height: 16px;
  margin-right: 6px;
  width: 17px;
}
@media screen and (min-width: 768px) {
  .b-wishlist_button-icon.m-simple {
    height: 14px;
    width: 15px;
  }
}
.b-wishlist_button-icon:hover {
  color: inherit;
}
.l-cart .b-wishlist_button-icon {
  color: #45132C;
}
.b-wishlist_button.m-tile .b-wishlist_button-icon {
  color: #45132C;
  height: 20px;
  width: 20px;
}
.b-wishlist_button.m-tile {
  position: absolute;
  right: 10px;
  top: 11px;
}

.b-header_wishlist {
  font-size: 11px;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .b-header_wishlist {
    font-size: 13px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_stuck .b-header_wishlist, .b-header_slim .b-header_wishlist {
    font-size: 11px;
  }
}
.b-header_wishlist-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  margin-bottom: 7px;
}
@media screen and (min-width: 768px) {
  .b-header_wishlist-icon {
    height: 28px;
    width: 28px;
  }
}
@media screen and (min-width: 1367px) {
  .b-header_stuck .b-header_wishlist-icon, .b-header_slim .b-header_wishlist-icon {
    height: 26px;
    margin-bottom: 3px;
    width: 26px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-header_stuck .b-header_wishlist-icon, .b-header_slim .b-header_wishlist-icon {
    margin: 0 4px 0 0;
  }
}
@media screen and (max-width: 1366px) {
  .b-header_stuck .b-header_wishlist-icon, .b-header_slim .b-header_wishlist-icon {
    height: 24px;
    width: 24px;
  }
}
@media screen and (max-width: 1023px) {
  .b-header_stuck .b-header_wishlist-icon, .b-header_slim .b-header_wishlist-icon {
    margin-bottom: 1px;
  }
}
@media screen and (max-width: 767px) {
  .m-skinny_nav .b-header_wishlist-icon {
    height: 24px;
    margin-bottom: 1px;
    width: 24px;
  }
}
.b-header_wishlist-link {
  align-items: center;
  color: #45132C;
  display: flex;
  flex-direction: column;
  line-height: 16px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: color, fill;
}
@media not all and (pointer: coarse) {
  .b-header_wishlist-link:hover {
    opacity: 0.8;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-header_stuck .b-header_wishlist-link, .b-header_slim .b-header_wishlist-link {
    flex-direction: row;
  }
}
.b-header_wishlist-caption {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
  word-break: break-all;
}
@media screen and (max-width: 767px) {
  .b-header_wishlist-caption {
    max-width: 50px;
  }
}
.b-header_wishlist-icon {
  fill: none;
}
.b-header_wishlist-link.m-active .b-header_wishlist-icon {
  animation: heart-bit ease-out 1s;
  animation-delay: 2s;
  color: #45132C;
  fill: #45132C;
}
.b-menu-item .b-header_wishlist {
  border-bottom: 1px solid #D2D2D2;
  font-size: 15px;
  font-weight: 600;
}
.b-menu-item .b-header_wishlist-link {
  flex-direction: row;
  padding: 15px 12px;
}
.b-menu-item .b-header_wishlist-icon {
  height: 20px;
  margin: 0 10px 0 0;
  width: 20px;
}
.b-menu-item .b-header_wishlist-caption {
  max-width: 100%;
}

/*md

# g-text_overflow

This is global component designed to reduce text lines and add "..." in the end.

## Usage

```scss
.component-link {
	@include g-text_overflow;
}

.component-link {
	@include g-text_overflow(2);
}
```

*/
.l-grid_layout {
  background-color: var(--bg-layout-color);
  background-position: var(--bg-image-position);
  background-repeat: var(--bg-image-repeat);
  background-size: var(--bg-image-size);
  margin: 0 auto;
  max-width: 1920px;
  overflow: hidden;
  padding: 25px 0;
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_top-sm {
    padding-top: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_top-sm {
    padding-top: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_top-sm {
    padding-top: 25px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_top-md {
    padding-top: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_top-md {
    padding-top: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_top-md {
    padding-top: 45px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_top-lg {
    padding-top: 35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_top-lg {
    padding-top: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_top-lg {
    padding-top: 65px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_top-xl {
    padding-top: 45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_top-xl {
    padding-top: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_top-xl {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_top-zero {
    padding-top: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_top-zero {
    padding-top: 0;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_top-zero {
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_bottom-sm {
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_bottom-sm {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_bottom-sm {
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_bottom-md {
    padding-bottom: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_bottom-md {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_bottom-md {
    padding-bottom: 45px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_bottom-lg {
    padding-bottom: 35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_bottom-lg {
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_bottom-lg {
    padding-bottom: 65px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_bottom-xl {
    padding-bottom: 45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_bottom-xl {
    padding-bottom: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_bottom-xl {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-padding_bottom-zero {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-padding_bottom-zero {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_bottom-zero {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout {
    background-image: var(--bg-image-desktop);
    padding: 40px 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout {
    background-image: var(--bg-image-tablet);
    padding: 45px 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout {
    background-image: var(--bg-image-mobile);
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.b-cat_tiles {
    display: initial;
  }
}
.l-grid_layout .b-cat_tiles-grid {
  max-width: none;
}
@media screen and (max-width: 767px) {
  .l-grid_layout-hide_sm {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout-hide_md {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-hide_lg {
    display: none;
  }
}
.l-grid_layout.m-full_bleed, .l-grid_layout.m-full_with_centered {
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0;
}
.l-grid_layout.m-full_with_centered {
  max-width: 1440px;
}
.l-grid_layout.m-centered {
  max-width: 1024px;
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-centered {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-2_8_2 .l-grid_layout-item.m-lg_2 {
    display: none;
  }
}
.l-grid_layout.m-promotional .l-grid_layout {
  --bg-layout-color: none;
  --bg-image-desktop: none;
  --bg-image-tablet: none;
  --bg-image-mobile: none;
}
.l-grid_layout.m-supplementary {
  overflow: visible;
}
.l-grid_layout-content {
  display: grid;
}
@media screen and (min-width: 1367px) {
  .l-grid_layout-content {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-grid_layout-content {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout-content {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout-content {
    grid-gap: 16px 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
.m-full_with_centered .l-grid_layout-content {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .m-full_with_centered .l-grid_layout-content {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .m-full_with_centered .l-grid_layout-content {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .m-full_with_centered .l-grid_layout-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.l-grid_layout-content.m-hide_gutters {
  grid-gap: 0;
}
@media screen and (max-width: 767px) {
  .l-grid_layout-content.b-featured_category {
    gap: 16px;
  }
}
.l-grid_layout-content.b-currated {
  max-width: 1440px;
  padding: 0 24px;
}
@media screen and (max-width: 767px) {
  .l-grid_layout-content.b-currated {
    gap: 0;
    padding: 0 15px;
  }
}
.l-grid_layout-item {
  max-width: 100%;
}
.l-grid_layout-item.m-horizontal_left, .l-grid_layout-item.m-horizontal_center, .l-grid_layout-item.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.l-grid_layout-item.m-horizontal_left {
  justify-content: flex-start;
}
.l-grid_layout-item.m-horizontal_center {
  justify-content: center;
}
.l-grid_layout-item.m-horizontal_right {
  justify-content: flex-end;
}
.l-grid_layout-item.m-vertical_top {
  align-self: flex-start;
}
.l-grid_layout-item.m-vertical_middle {
  align-self: center;
}
.l-grid_layout-item.m-vertical_bottom {
  align-self: flex-end;
}
@media screen and (max-width: 767px) {
  .l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in {
    margin-bottom: 25px;
  }
}
.l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in {
    margin-bottom: 45px;
  }
}
.l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in {
    margin-bottom: 35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in {
    margin-bottom: 65px;
  }
}
.l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in {
    margin-bottom: 45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in {
    margin-bottom: 80px;
  }
}
.l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .l-grid_layout-item.m-margin_bottom-zero .l-grid_layout-item_in {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout-item.m-margin_bottom-zero .l-grid_layout-item_in {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-margin_bottom-zero .l-grid_layout-item_in {
    margin-bottom: 0;
  }
}
.l-grid_layout-item.m-margin_bottom-zero .l-grid_layout-item_in:last-child {
  margin-bottom: 0;
}
.l-grid_layout-item_in {
  display: flex;
}
.l-grid_layout-item_in > div,
.l-grid_layout-item_in > section {
  width: 100%;
}
.l-grid_layout-item_in > .m-alternative_category_tiles {
  margin-top: -25px;
}
.m-supplementary .l-grid_layout-item_in {
  margin: 10px 0;
}
@media screen and (max-width: 767px) {
  .m-supplementary .l-grid_layout-item_in {
    margin: 5px 0;
  }
}
.l-grid_layout-item_in:only-child {
  height: 100%;
}
.l-grid_layout-item[data-items=one] {
  align-self: auto;
}
.l-grid_layout-item.m-title {
  grid-column: span 6;
  order: -2;
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-title {
    grid-column: span 12;
  }
}
.l-grid_layout-item.m-fade_in > div, .l-grid_layout-item.m-fade_in > section {
  opacity: 0;
  transform: translateY(10%);
  transition: 1.2s ease-out;
  transition-property: transform, opacity;
}
.l-grid_layout-item.m-bounce {
  overflow: hidden;
}
.l-grid_layout-item.m-bounce > div, .l-grid_layout-item.m-bounce > section {
  opacity: 0;
  transform: scale(0.8);
}
.l-grid_layout-item.m-fade_in.m-animated > div, .l-grid_layout-item.m-fade_in.m-animated > section {
  opacity: 1;
  transform: translateY(0);
}
.l-grid_layout-item.m-bounce.m-animated > div, .l-grid_layout-item.m-bounce.m-animated > section {
  animation-duration: 1.2s;
  animation-fill-mode: both;
  animation-name: growIn;
}
.l-grid_layout-item.m-sm_6 {
  grid-column: span 6;
}
.l-grid_layout-item.m-sm_5 {
  grid-column: span 5;
}
.l-grid_layout-item.m-sm_4 {
  grid-column: span 4;
}
.l-grid_layout-item.m-sm_3 {
  grid-column: span 3;
}
.l-grid_layout-item.m-sm_2 {
  grid-column: span 2;
}
.l-grid_layout-item.m-sm_1 {
  grid-column: span 1;
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_12 {
    grid-column: span 12;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_11 {
    grid-column: span 11;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_10 {
    grid-column: span 10;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_9 {
    grid-column: span 9;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_8 {
    grid-column: span 8;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_7 {
    grid-column: span 7;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_6 {
    grid-column: span 6;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_5 {
    grid-column: span 5;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_4 {
    grid-column: span 4;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_3 {
    grid-column: span 3;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_2 {
    grid-column: span 2;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_1 {
    grid-column: span 1;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_12 {
    grid-column: span 12;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_11 {
    grid-column: span 11;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_10 {
    grid-column: span 10;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_9 {
    grid-column: span 9;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_8 {
    grid-column: span 8;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_7 {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_6 {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_5 {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_4 {
    grid-column: span 4;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_3 {
    grid-column: span 3;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_2 {
    grid-column: span 2;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_1 {
    grid-column: span 1;
  }
}
.l-grid_layout-item_title {
  color: #45132C;
  padding-bottom: 0;
}
.l-grid_layout.m-full_bleed .l-grid_layout-item_title {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  max-width: 1440px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-grid_layout.m-full_bleed .l-grid_layout-item_title {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid_layout.m-full_bleed .l-grid_layout-item_title {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-full_bleed .l-grid_layout-item_title {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.l-static_page:not(.m-full_width) .l-grid_layout.m-full_bleed .l-grid_layout-item_title {
  padding-left: 0;
  padding-right: 0;
}
.l-grid_layout.m-custom .l-grid_layout-item {
  display: flex;
}
.l-grid_layout.m-custom .l-grid_layout-item > div {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-mobile_reversed .l-grid_layout-item:last-child {
    order: -1;
  }
}
@media screen and (max-width: 767px) {
  .l-grid_layout.m-3_9 .l-grid_layout-item:last-child, .l-grid_layout.m-4_8 .l-grid_layout-item:last-child, .l-grid_layout.m-3_3_6 .l-grid_layout-item:last-child {
    order: -1;
  }
}
.l-grid_layout.m-3_9.m-mobile_reversed .l-grid_layout-item:last-child, .l-grid_layout.m-4_8.m-mobile_reversed .l-grid_layout-item:last-child, .l-grid_layout.m-3_3_6.m-mobile_reversed .l-grid_layout-item:last-child {
  order: 1;
}

.b-text_block {
  background-color: var(--bg-text-block, transparent);
  width: 100%;
}
.b-text_block.m-vertical_top {
  align-items: flex-start;
}
.b-text_block.m-vertical_middle {
  align-items: center;
}
.b-text_block.m-vertical_bottom {
  align-items: flex-end;
}
.b-text_block.m-vertical_fill {
  align-self: stretch;
}
.b-text_block.m-horizontal_left, .b-text_block.m-horizontal_center, .b-text_block.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
}
.b-text_block.m-horizontal_left {
  justify-content: flex-start;
}
.b-text_block.m-horizontal_center {
  justify-content: center;
}
.b-text_block.m-horizontal_right {
  justify-content: flex-end;
}
.b-text_block h1,
.b-text_block .title-h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.25;
}
.b-text_block h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;
}
.b-text_block h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
}
.b-text_block h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
}
.b-text_block h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
}
.b-text_block h6 {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.25;
}
.b-text_block h1,
.b-text_block .title-h1,
.b-text_block h2,
.b-text_block h3,
.b-text_block h4,
.b-text_block h5,
.b-text_block h6 {
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .b-text_block h1,
  .b-text_block .title-h1,
  .b-text_block h2,
  .b-text_block h3,
  .b-text_block h4,
  .b-text_block h5,
  .b-text_block h6 {
    margin-bottom: 12px;
  }
}
.m-promotional .b-text_block h1,
.m-promotional .b-text_block .title-h1,
.m-promotional .b-text_block h2,
.m-promotional .b-text_block h3,
.m-promotional .b-text_block h4,
.m-promotional .b-text_block h5,
.m-promotional .b-text_block h6 {
  margin-bottom: 10px;
}
@media screen and (max-width: 1023px) {
  .m-promotional .b-text_block h1,
  .m-promotional .b-text_block .title-h1,
  .m-promotional .b-text_block h2,
  .m-promotional .b-text_block h3,
  .m-promotional .b-text_block h4,
  .m-promotional .b-text_block h5,
  .m-promotional .b-text_block h6 {
    margin-bottom: 0;
  }
}
.b-text_block ul,
.b-text_block ol {
  display: block;
  margin-bottom: 16px;
  padding-left: 20px;
}
.b-text_block li {
  display: list-item;
  margin-bottom: 4px;
}
.b-text_block ul {
  list-style: disc outside;
}
.b-text_block ol {
  list-style: decimal outside;
}
.b-text_block p {
  margin-bottom: 16px;
}
.b-text_block blockquote {
  border-left: 4px solid hsl(0, 0%, 73%);
  font-style: italic;
  margin: 0 0 16px;
  padding: 0 0 0 20px;
}
.b-text_block a:hover {
  text-decoration: none;
}
.b-text_block *:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_top_sm .b-text_block-container {
    padding-top: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_top_sm .b-text_block-container {
    padding-top: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_top_sm .b-text_block-container {
    padding-top: 25px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_top_md .b-text_block-container {
    padding-top: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_top_md .b-text_block-container {
    padding-top: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_top_md .b-text_block-container {
    padding-top: 45px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_top_lg .b-text_block-container {
    padding-top: 35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_top_lg .b-text_block-container {
    padding-top: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_top_lg .b-text_block-container {
    padding-top: 65px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_top_xl .b-text_block-container {
    padding-top: 45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_top_xl .b-text_block-container {
    padding-top: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_top_xl .b-text_block-container {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_top_zero .b-text_block-container {
    padding-top: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_top_zero .b-text_block-container {
    padding-top: 0;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_top_zero .b-text_block-container {
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_right_sm .b-text_block-container {
    padding-right: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_right_sm .b-text_block-container {
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_right_sm .b-text_block-container {
    padding-right: 25px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_right_md .b-text_block-container {
    padding-right: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_right_md .b-text_block-container {
    padding-right: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_right_md .b-text_block-container {
    padding-right: 45px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_right_lg .b-text_block-container {
    padding-right: 35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_right_lg .b-text_block-container {
    padding-right: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_right_lg .b-text_block-container {
    padding-right: 65px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_right_xl .b-text_block-container {
    padding-right: 45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_right_xl .b-text_block-container {
    padding-right: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_right_xl .b-text_block-container {
    padding-right: 80px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_right_zero .b-text_block-container {
    padding-right: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_right_zero .b-text_block-container {
    padding-right: 0;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_right_zero .b-text_block-container {
    padding-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_bottom_sm .b-text_block-container {
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_bottom_sm .b-text_block-container {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_bottom_sm .b-text_block-container {
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_bottom_md .b-text_block-container {
    padding-bottom: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_bottom_md .b-text_block-container {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_bottom_md .b-text_block-container {
    padding-bottom: 45px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_bottom_lg .b-text_block-container {
    padding-bottom: 35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_bottom_lg .b-text_block-container {
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_bottom_lg .b-text_block-container {
    padding-bottom: 65px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_bottom_xl .b-text_block-container {
    padding-bottom: 45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_bottom_xl .b-text_block-container {
    padding-bottom: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_bottom_xl .b-text_block-container {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_bottom_zero .b-text_block-container {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_bottom_zero .b-text_block-container {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_bottom_zero .b-text_block-container {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_left_sm .b-text_block-container {
    padding-left: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_left_sm .b-text_block-container {
    padding-left: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_left_sm .b-text_block-container {
    padding-left: 25px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_left_md .b-text_block-container {
    padding-left: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_left_md .b-text_block-container {
    padding-left: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_left_md .b-text_block-container {
    padding-left: 45px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_left_lg .b-text_block-container {
    padding-left: 35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_left_lg .b-text_block-container {
    padding-left: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_left_lg .b-text_block-container {
    padding-left: 65px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_left_xl .b-text_block-container {
    padding-left: 45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_left_xl .b-text_block-container {
    padding-left: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_left_xl .b-text_block-container {
    padding-left: 80px;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block.m-padding_left_zero .b-text_block-container {
    padding-left: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block.m-padding_left_zero .b-text_block-container {
    padding-left: 0;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_left_zero .b-text_block-container {
    padding-left: 0;
  }
}
.m-promotional .b-text_block p {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}
.b-text_block-container {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-text_block-container {
    width: var(--width, auto);
  }
}
.b-text_block-container *:last-child {
  margin-bottom: 0;
}
.b-text_block a:not(.b-button, .m-standalone_link) {
  text-decoration: underline;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-text_block a:not(.b-button, .m-standalone_link):hover {
  color: #C23D74;
}
.b-text_block pre {
  white-space: pre-line;
}
@media screen and (min-width: 1024px) {
  .b-text_block .hidden-lg {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-text_block .hidden-md {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .b-text_block .hidden-sm {
    display: none;
  }
}