/*md

# Apple pay button

Part of the styling of Apple pay button is implemented by Demandware. Those styles inserted throgh
isactivedatahead tag. [Please see](https://confluence.ontrq.com/display/ACDC/ApplePay+button+styling)

We overwrite Demandware styles and provide modern approach for button styling and localization
possibilities.

 */

.b-apple_pay {
	margin: 20px 0 0;

	&.m-disabled {
		opacity: 0.4;
		pointer-events: none;
	}

	&-button {
		&#{&} {
			&,
			&:hover,
			&:active {
				// This is modification of default Demandware styles that inserted in header
				// through isactivedatahead. As approach used background-image:-webkit-named-image(apple-pay-logo-white);

				background-size: 75% 52%;
				border-radius: 5px;
				cursor: pointer;
				margin: 0;

				// Reset Demandware styles if iOS 10+ and use -apple-pay-button
				@supports (-webkit-appearance: -apple-pay-button) {
					appearance: -apple-pay-button;
					background: none;
					border: none;
					-apple-pay-button-style: white-outline;
				}
			}

			@supports (-webkit-appearance: -apple-pay-button) {
				&::after {
					content: '';
				}
			}
		}

		// Cover different button types options of Demandware apple pay
		&.dw-apple-pay-logo-white { // stylelint-disable-line selector-class-pattern
			-apple-pay-button-style: #FFFFFF;
		}

		&.dw-apple-pay-logo-white.dw-apple-pay-border { // stylelint-disable-line selector-class-pattern
			-apple-pay-button-style: white-outline;
		}

		// Modify button messaging on checkout ("Order with 🍏Pay")
		&.m-checkout {
			margin-bottom: 22px;

			@supports (-webkit-appearance: -apple-pay-button) {
				&,
				&:hover,
				&:active {
					-apple-pay-button-type: order;
				}
			}
		}

		// Modify button messaging on PDP ("Buy with 🍏Pay")
		&.m-pdp {
			@supports (-webkit-appearance: -apple-pay-button) {
				&,
				&:hover,
				&:active {
					-apple-pay-button-type: buy;
				}
			}
		}

		.b-apple_pay.m-disabled & {
			@include g-button(disabled);
		}
	}

	&-description {
		margin: 24px 0 20px;
	}

	&-help {
		color: $color-primary;
		font-size: 12px;
		margin-top: 8px;
	}

	.b-proceed_checkout & {
		@include media(md) {
			flex: 1;
			margin: 0;
		}
	}
}
