/*md

# b-carousel (based on scroll)

## Carousel examples

### 1. Hero carousel

```html_example
<div data-widget="carousel" class="b-carousel m-hero">
	<button class="b-carousel-ctrl m-prev" tabindex="-1" aria-hidden="true" title="Previous" data-ref="elemPrevButton" data-event-click="scrollToPrevPage">
		<svg width="40" height="40" viewBox="2 1 40 40" aria-hidden="true" focusable="false">
			<path d="m26.541 8.3536-12.728 12.728 12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
		</svg>
	</button>
	<button class="b-carousel-ctrl m-next" tabindex="-1" aria-hidden="true" title="Next" data-ref="elemNextButton" data-event-click="scrollToNextPage">
		<svg width="40" height="40" viewBox="-1 1 40 40" aria-hidden="true" focusable="false">
			<path d="m13.459 8.3536 12.728 12.728-12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
		</svg>
	</button>
	<div class="b-carousel-track" data-ref="elemCarouselTrack" data-event-scroll.passive="onScroll" data-event-touchstart="onScroll" data-event-mousedown.prevent="onMouseDown" data-event-mouseup="onMouseUp">
		<div class="b-carousel-item">
			<figure class="b-promo_box m-full_bleed m-caption_offcenter">
				<picture class="b-promo_box-picture">
					<img src="../images/guide/examples/hero-carousel-banner-1.jpg?$staticlink$" alt="Girl stand on the beach." loading="eager" width="1600" height="800">
				</picture>
				<figcaption class="b-promo_box-inner">
					<div class="b-promo_box-caption b-promo_caption">
						<h2 class="b-promo_caption-title">Carousel first slide</h2>
						<p class="b-promo_caption-subtitle">Everyone's fallen for boilerplate so we added to her games repertoire.</p>
						<div class="b-promo_caption-actions">
							<a class="b-button" href="#" aria-label="Shop new season at boilerplate">Shop New Season</a>
						</div>
					</div>
				</figcaption>
			</figure>
		</div>
		<div class="b-carousel-item">
			<figure class="b-promo_box m-full_bleed m-caption_offcenter">
				<picture class="b-promo_box-picture">
					<img src="../images/guide/examples/hero-carousel-banner-2.jpg?$staticlink$" alt="Girl with khaki panama." loading="lazy" width="1600" height="800">
				</picture>
				<figcaption class="b-promo_box-inner">
					<div class="b-promo_box-caption b-promo_caption">
						<h2 class="b-promo_caption-title">Carousel second slide</h2>
						<p class="b-promo_caption-subtitle">New playful styles inspired by darts and that staple of a British pub - the fruit machine.</p>
						<div class="b-promo_caption-actions">
							<a class="b-button" href="#" aria-label="Shop new Collection at boilerplate">Shop New Collection</a>
						</div>
					</div>
				</figcaption>
			</figure>
		</div>
		<div class="b-carousel-item">
			<figure class="b-promo_box m-full_bleed m-caption_offcenter">
				<picture class="b-promo_box-picture">
					<img src="../images/guide/examples/hero-carousel-banner-3.jpg?$staticlink$" alt="Girl stand in cloak." loading="lazy" width="1600" height="800">
				</picture>
				<figcaption class="b-promo_box-inner">
					<div class="b-promo_box-caption b-promo_caption">
						<h2 class="b-promo_caption-title">Carousel third slide</h2>
						<div class="b-promo_caption-actions">
							<a class="b-button" href="#" aria-label="Shop new Season at boilerplate">Shop New Season</a>
						</div>
					</div>
				</figcaption>
			</figure>
		</div>
	</div>
</div>
```

### 2. Products carousel

```html_example
<section data-widget="carousel" class="b-carousel m-products">
	<h2 class="b-carousel-title">Look what's new</h2>
	<button class="b-carousel-ctrl m-prev" tabindex="-1" aria-hidden="true" title="Previous" data-ref="elemPrevButton" data-event-click="scrollToPrevPage">
		<svg width="40" height="40" viewBox="2 1 40 40" aria-hidden="true" focusable="false">
			<path d="m26.541 8.3536-12.728 12.728 12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
		</svg>
	</button>
	<button class="b-carousel-ctrl m-next" tabindex="-1" aria-hidden="true" title="Next" data-ref="elemNextButton" data-event-click="scrollToNextPage">
		<svg width="40" height="40" viewBox="-1 1 40 40" aria-hidden="true" focusable="false">
			<path d="m13.459 8.3536 12.728 12.728-12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
		</svg>
	</button>
	<div class="b-carousel-track" data-ref="elemCarouselTrack" data-event-scroll.passive="onScroll" data-event-touchstart="onScroll" data-event-mousedown.prevent="onMouseDown" data-event-mouseup="onMouseUp">
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
		<div class="b-carousel-item">
			<section class="b-product_tile">
				<img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
			</section>
		</div>
	</div>
</section>
<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
	<symbol id="star">
		<path d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
	</symbol>
</svg>
```
*/

@use 'sass:math';

.b-carousel {
	position: relative;

	&.m-hybrid::after {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 1) 100%);
		bottom: 0;
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 80px;
	}

	&.m-hybrid.m-single {
		margin-right: 20px;
		min-width: 342px;
		width: 342px;

		&::after {
			display: none;
		}
	}

	&.m-einstein {
		padding-bottom: 40px;
		padding-top: 40px;

		@include media(xl) {
			padding-bottom: 45px;
			padding-top: 45px;
		}

		@include media(md-down) {
			padding-bottom: 25px;
			padding-top: 25px;
		}
	}

	&.m-bundle {
		border: 1px solid $color-divider-light;
		border-radius: $global-radius;
		box-shadow: $depth-1;
	}

	&.m-multy {
		@include media(sm) {
			margin: 0 15px;
		}
	}

	&-title {
		@include g-heading_3;

		color: $color-title;
		margin-bottom: 13px;
		text-align: center;

		@include media(sm) {
			font-size: 24px;
		}

		.b-text_block & {
			margin-bottom: 0;
		}

		.m-ideas & {
			@include media(md-down) {
				font-size: 24px;
			}
		}
	}

	&-callout {
		@include g-heading_4;

		color: $color-white;
		margin-bottom: 5px;
		text-transform: uppercase;

		@include media(md-up) {
			font-size: 27px;
		}

		&.m-category-grid {
			color: $color-burgundy;
			text-transform: none;
		}
	}

	&-content {
		&.m-promotional {
			position: relative;

			@include media(md-up) {
				margin: 0 -8px;
			}
		}

		&.m-currated_pd {
			height: 100%;
		}

		.m-ideas & {
			margin-bottom: 20px;
		}
	}

	&-track {
		@include g-snap_scroll;

		&.m-grabbing {
			cursor: grab;
			scroll-behavior: auto;
			user-select: none;

			&::before {
				bottom: 0;
				content: '';
				display: block;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: z(components, carousel, grabbing-overlay);
			}
		}

		&.m-no_animate {
			scroll-behavior: initial;
		}

		&.m-promotional,
		&.m-currated {
			border-radius: 4px 4px 16px 16px;
			gap: 16px;
			margin: -10px 0;
			padding: 0 0 10px;

			@include media(sm) {
				gap: 0;
			}
		}

		&.m-currated {
			gap: 16px;
			margin: -10px 0;
			padding: 10px 0;

			@include media(md-up) {
				gap: 8px;
			}

			@include media(sm) {
				margin: 0;
				padding: 8px 0;
			}
		}

		&.m-currated_pd {
			@include media(md-up) {
				display: grid;
				gap: 20px 8px;
				grid-auto-columns: 40%;
				grid-auto-flow: column dense;
				grid-template-rows: 1fr 1fr;
				height: 100%;
			}

			@include media(sm) {
				gap: 8px;
			}
		}

		&.m-lozenges {
			gap: 16px;
			margin-top: 12px;
		}
	}

	&-item {
		scroll-snap-align: start;

		&.m-hybrid {
			margin-right: 5px;
			max-width: 55%;
			min-width: 55%;
			position: relative;

			&:hover .b-product_gallery-zoom {
				opacity: 1;
			}

			&[role='button'] {
				cursor: url('./images/zoom-in.png'), zoom-in;
			}

			.b-product_image {
				background: $color-light-grey;
				display: block;
				height: 100%;
				overflow: hidden;
				position: relative;
				width: 100%;
			}

			.b-product_image-img {
				color: $color-light-grey;
				display: block;
				height: 100%;
				width: 100%;
			}
		}

		.m-single &.m-hybrid {
			@include media(md-up) {
				margin: 0;
				max-width: 100%;
				width: 100%;
			}
		}

		&.m-promotional {
			min-width: 34%;
			width: 34%;

			@include media(md) {
				min-width: 50%;
				width: 50%;
			}

			@include media(sm) {
				min-width: 100%;
				width: 100%;
			}
		}

		&.m-bundle {
			min-width: 100%;
			width: 100%;
		}

		&.m-currated {
			display: flex;
			flex-direction: column;
			gap: 20px;
			justify-content: space-between;
			min-width: calc(100% / 2.5);
			width: calc(100% / 2.5);

			@include media(sm) {
				display: flex;
				flex-direction: row;
				gap: 0;
				min-width: 100%;
				width: 100%;
			}
		}

		&.m-currated_pd {
			min-width: 100%;
			width: 100%;

			@include media(sm) {
				margin: 0;
				min-width: calc(50% - 4px);
				width: calc(50% - 4px);
			}

			> div {
				height: 100%;
			}
		}
	}

	&-item_inner {
		@include media(sm) {
			margin-right: 8px;
			min-width: calc(50% - 8px);
			width: calc(50% - 8px);
		}
	}

	&-ctrl {
		appearance: none;
		background-color: rgba($color-bg, 0.8);
		border: 1px solid $color-divider;
		border-radius: 50%;
		box-shadow: $depth-1;
		color: $color-action-obj;
		cursor: pointer;
		display: none;
		height: 50px;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		transition: $motion-ease;
		transition-property: background-color, opacity;
		user-select: none;
		width: 50px;
		z-index: 2;

		@include hover-supported {
			&:hover {
				background-color: $color-bg;
			}
		}

		@include media(sm) {
			height: 40px;
			width: 40px;

			svg {
				height: 19px;
				width: 19px;
			}
		}

		&.m-prev {
			left: 10px;

			.b-category_tile & {
				@include media(md) {
					left: -25px;
				}

				@include media(sm) {
					left: -15px;
				}
			}
		}

		&.m-next {
			right: 10px;

			.b-category_tile & {
				@include media(md) {
					right: -25px;
				}

				@include media(sm) {
					right: -15px;
				}
			}
		}

		&.m-bundle {
			margin: 0 -40px;

			@include media(sm) {
				margin: 0 -35px;
			}
		}

		&.m-hybrid {
			margin-top: 0;
			z-index: 2;

			@include media(sm) {
				height: 33px;
				width: 33px;

				svg {
					width: 16px;
				}
			}
		}

		&.m-promotional,
		.m-promotional &,
		&.m-currated {
			background-color: transparent;
			border: none;
			box-shadow: none;
			height: 100%;
			margin-top: 0;
			right: -15px;

			@include media(sm) {
				width: 40px;

				svg {
					height: 18px;
					position: relative;
					width: 18px;
				}
			}

			svg {
				position: relative;
				z-index: 1;
			}

			&::after {
				background-color: $color-white;
				border: 1px solid $color-mid-grey;
				border-radius: 50%;
				box-shadow: $depth-1;
				content: '';
				display: block;
				height: 50px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 100%;

				@include media(sm) {
					height: 40px;
				}
			}

			&::before {
				background: linear-gradient(to left, var(--bg-layout-color, $color-white) 0%, transparent 100%);
				content: '';
				height: 100%;
				margin: 0 8px;
				position: absolute;
				right: 13%;
				top: 50%;
				transform: translateY(-50%);
				width: 72px;
				z-index: -1;
			}
		}

		&.m-prev.m-promotional,
		.m-promotional &.m-prev {
			left: -15px;
			right: auto;

			&::before {
				background: linear-gradient(to right, var(--bg-layout-color, $color-white) 0%, transparent 100%);
				left: 13%;
			}
		}

		.b-comparison & {
			top: 175px;
		}

		&.m-currated {
			right: -24px;

			@include media(sm) {
				right: -15px;
			}

			&::before {
				height: calc(100% + 20px);
				margin: 0;
				right: 48%;
				width: 35px;

				@include media(sm) {
					right: 38%;
				}
			}

			svg {
				position: relative;
			}
		}

		.l-grid_layout &.m-currated {
			&::before {
				@include media(sm) {
					height: calc(100% - 8px);
				}
			}
		}

		&.m-prev.m-currated {
			left: -19px;

			@include media(sm) {
				left: -15px;
			}

			&::before {
				background: linear-gradient(to right, var(--bg-layout-color, $color-white) 0%, transparent 100%);
				left: 38%;
			}
		}
	}

	.b-rounded_tiles &-ctrl {
		top: 95px;
	}

	&.m-no_scroll &-ctrl,
	&-content.m-no_scroll &-ctrl,
	&-ctrl[disabled],
	&-content.m-inited &-ctrl[disabled] {
		// It should not be hidden with display: none
		// 1) screen reader should announce "Unavailable" to user
		// 2) we should prevent of user false clicks when button is disappears

		opacity: 0;
		z-index: -1;

		@include media(sm) {
			display: none;
		}
	}

	&.m-inited &-ctrl,
	&-content.m-inited &-ctrl {
		display: block;
	}

	&-page {
		background-color: transparent;
		border: none;
		cursor: pointer;
		display: block;
		height: 20px;
		line-height: 20px;
		position: relative;
		text-align: center;
		width: 20px;

		&::before {
			$dot-size: 8px;

			background-color: $color-text;
			border: 1px solid $color-white;
			border-radius: $dot-size;
			content: '';
			display: inline-block;
			height: $dot-size;
			left: 50%;
			margin-left: -#{math.div($dot-size, 2)};
			margin-top: -#{math.div($dot-size, 2)};
			position: absolute;
			top: 50%;
			transition: $motion-ease;
			transition-property: width, height, margin;
			width: $dot-size;
		}

		&.m-current {
			&::before {
				background-color: $color-action-obj;
				content: '';
				height: 14px;
				margin-left: -7px;
				margin-top: -7px;
				width: 14px;
			}
		}
	}

	&-description {
		font-size: 15px;
		margin-bottom: 20px;
		text-align: center;

		@include media(md-up) {
			margin-left: 50px;
			margin-right: 50px;
		}

		.m-ideas & {
			margin-top: 24px;
		}
	}

	&-viewall_wrapper {
		padding-top: 15px;
		text-align: center;
	}

	&-viewall {
		padding: 0 25px;
	}

	&.m-products {
		margin: 0 12px;

		@include media(md-down) {
			margin-left: 12px;
			margin-right: 12px;
		}

		.l-pdp-slots & {
			@include media(lg) {
				margin-left: 12px;
				margin-right: 12px;
			}
		}

		.l-cart-slots & {
			@include media(md-down) {
				margin-left: 15px;
				margin-right: 15px;
			}
		}

		.b-plp_no_results & {
			margin-bottom: 0;
		}
	}

	&.m-products &-title {
		margin-bottom: 24px;

		@include media(sm) {
			margin-bottom: 18px;
		}

		.g-squiggle {
			z-index: -1;
		}
	}

	&.m-products &-content {
		position: relative;

		@include media(lg-up) {
			margin-left: -11px;
			margin-right: -11px;
		}
	}

	&.m-products &-track {
		padding: 8px 0;
	}

	&.m-products &-ctrl {
		height: 50px;
		transform: translateY(0);
		width: 50px;

		svg {
			height: 24px;
			width: 24px;
		}

		&.m-prev {
			left: -15px;

			@include media(sm) {
				left: -24px;
			}
		}

		&.m-next {
			right: -15px;

			@include media(sm) {
				right: -24px;
			}
		}
	}

	&.m-products &-item {
		max-width: 20%;
		min-width: 20%;
		padding: 0 11px;

		@include media(md-down) {
			padding: 0 5px;
		}

		@include media(md) {
			max-width: 33.33%;
			min-width: 33.33%;
		}

		@include media(sm) {
			max-width: 50%;
			min-width: 50%;
		}
	}

	&.m-carousel-idea {
		.b-product_tile {
			padding-bottom: 0;
		}
	}

	&.m-carousel-idea &-item {
		max-width: 25%;
		min-width: 25%;
		padding: 0 11px;

		@include media(md-down) {
			padding: 0 5px;
		}

		@include media(md) {
			max-width: 33.33%;
			min-width: 33.33%;
		}

		@include media(sm) {
			max-width: 100%;
			min-width: 100%;
		}
	}

	&.m-carousel-comparison &-item_this {
		background-color: $color-orange2;
		border-radius: 2px 2px 0 0;
		font-size: 11px;
		height: 15px;
		left: 10px;
		line-height: 15px;
		position: absolute;
		right: 10px;
		text-align: center;
		top: -15px;
		width: calc(100% - 20px);

		@include media(sm) {
			left: 5px;
			right: 5px;
			width: calc(100% - 10px);
		}
	}

	&.m-hero {
		margin: 0 auto;
		max-width: $global-site-width-max;
	}

	&.m-hero &-ctrl {
		&.m-prev {
			left: 40px;
		}

		&.m-next {
			right: 40px;
		}
	}

	&.m-hero &-item {
		max-width: 100%;
		min-width: 100%;
	}

	&.m-lozenges &-ctrl {
		margin-top: 0;

		&.m-prev {
			left: auto;
			transform: translate(-50%, -50%);
		}

		&.m-next {
			right: 0;
			transform: translate(50%, -50%);
		}
	}

	&.m-rounded_tiles &-ctrl {
		margin-top: 25px;
		top: auto;
		transform: initial;
	}

	&.m-set_items & {
		&-title {
			font-size: 13px;
			font-weight: 600;
			margin-bottom: 8px;
			text-align: left;

			@include media(lg-up) {
				font-size: 17px;
				margin-bottom: 16px;
			}
		}

		&-content {
			margin-left: -4px;
			margin-right: -4px;
			position: relative;
		}

		&-item {
			max-width: 33.33%;
			min-width: 33.33%;
			padding: 0 4px;

			@include media(lg-up) {
				max-width: percentage(math.div(1, 6));
				min-width: percentage(math.div(1, 6));
			}
		}

		&-ctrl {
			margin-top: 0;
			top: 40%;

			@include media(md-up) {
				top: 32%;
			}

			&.m-prev {
				left: -10px;
			}

			&.m-next {
				right: -10px;
			}
		}
	}

	&.m-set_items {
		.b-product_tile {
			border: 0;
			border-radius: 0;
			box-shadow: none;
			font-size: 13px;
			padding-bottom: 0;

			&-info {
				padding: 5px 8px;

				@include media(md-up) {
					padding: 10px 8px;
				}
			}

			&-link {
				@include media(md-up) {
					-webkit-line-clamp: 3; /* number of lines to show */
					line-height: 1.45;
				}
			}
		}
	}

	&.m-category-carousel &-ctrl {
		background: none;
		border: none;
		color: $color-white;
		font-size: 10px;
		margin-top: 0;

		&.m-prev {
			left: -38px;
			text-align: right;
		}

		&.m-next {
			right: -38px;
			text-align: left;
		}

		svg {
			position: relative;
			width: 15px;
		}
	}

	&.m-category-carousel &-content {
		padding: 0 10px;
		position: relative;
	}

	&.m-category-carousel &-callout {
		margin-bottom: 10px;
	}

	&.m-category-carousel &-item {
		min-width: calc(100% / 8);
		width: calc(100% / 8);

		@include media(md) {
			min-width: calc(100% / 5);
		}

		@include media(sm) {
			min-width: calc(100% / 3);
		}
	}

	&.m-carousel-comparison {
		.b-product_tile {
			border-radius: 2px;
			box-shadow: none;
			padding-bottom: 0;
			text-align: center;
		}

		.b-product_tile-title {
			font-size: 13px;
		}

		.b-price,
		.b-rating {
			justify-content: center;
		}
	}

	&.m-carousel-comparison &-track {
		padding: 15px 0 0;
	}

	&.m-carousel-comparison &-item {
		padding: 0 10px;
		position: relative;

		@include media(sm) {
			padding: 0 5px;
		}

		&.m-current-item .b-product_tile {
			border: 2px solid $color-orange2;
		}
	}

	&.m-carousel-wishlist {
		margin-bottom: 20px;
	}

	&.m-category-grid {
		.b-category_item-img {
			filter: none;

			@include media(xl) {
				height: 48px;
				width: 48px;
			}

			@include media(sm) {
				height: 29px;
				width: 29px;
			}
		}

		.b-category_item {
			color: $color-burgundy;
			max-width: 100px;
		}

		.b-category_item-title {
			font-weight: 500;
		}
	}

	&.m-category-grid &-item {
		min-width: calc(100% / 7.4);
		width: calc(100% / 7.4);

		@include media(xl) {
			min-width: calc(100% / 8.35);
			width: calc(100% / 8.35);
		}

		@include media(md) {
			min-width: calc(100% / 4.5);
		}

		@include media(sm) {
			min-width: calc(100% / 3.5);
		}
	}

	&.m-category-grid &-ctrl {
		box-shadow: none;
		color: $color-burgundy;

		&::before {
			background: linear-gradient(to left, $color-white 0%, rgba($color-white, 0) 100%);
			content: '';
			height: 120px;
			position: absolute;
			right: calc(100% - 15px);
			top: 50%;
			transform: translateY(-50%);
			width: 55px;

			@include media(sm) {
				width: 70px;
			}
		}

		&.m-prev {
			&::before {
				background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba($color-white, 0) 100%);
				left: calc(100% - 15px);
			}
		}
	}

	.m-ideas &.m-products &-item {
		max-width: 25%;
		min-width: 25%;
		padding: 0 11px;

		@include media(md-down) {
			padding: 0 5px;
		}

		@include media(md) {
			max-width: 33.33%;
			min-width: 33.33%;
		}

		@include media(sm) {
			max-width: 100%;
			min-width: 100%;
		}
	}

	.m-promotional &.m-products &-item {
		max-width: 36%;
		min-width: 36%;
		padding: 0 8px;

		@include media(sm) {
			max-width: 100%;
			min-width: 100%;
			padding: 0;
		}
	}

	.m-promotional &.m-products &-ctrl {
		height: 100%;
		transform: translateY(-50%);

		@include media(sm) {
			width: 40px;

			svg {
				height: 18px;
				position: relative;
				width: 18px;
			}
		}

		&::before {
			z-index: -1;
		}

		&.m-prev {
			left: -15px;
		}

		&.m-next {
			right: -15px;
		}
	}

	.l-pdp-slots.m-top-slots &-title:not(.m-standard) {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.l-pdp-slots.m-top-slots:not(.m-standard_header) &-title.m-standard {
		display: none;
	}

	.l-pdp-slots.m-top-slots &-description {
		@include media(sm) {
			margin-left: -16px;
			margin-right: -16px;
		}
	}
}
