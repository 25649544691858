.b-footer {
	&-content {
		background-color: $color-bg-footer-region-1;
	}

	&-inner {
		@include g-section_holder_footer;

		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
		padding-top: 20px;

		@include media(md-large-down) {
			flex-wrap: wrap;
		}

		@include media(lg-large) {
			padding-top: 36px;
		}

		@include media(md-large) {
			justify-content: center;
			padding-top: 40px;
		}

		@include media(sm) {
			align-items: center;
			flex-direction: column-reverse;
		}
	}

	&-sidebar {
		color: $color-text-white;
		font-size: 14px;
		line-height: 21px;
		min-width: 320px;

		@include media(md-large-down) {
			width: 100%;
		}

		@include media(md-large) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-right: 10px;
			order: -1;
			width: 320px;
		}
	}

	&-newsletters {
		background-color: $color-blue;
		border-radius: 2px;
		color: $color-title;
		overflow: hidden;
		padding: 20px 16px;
		position: relative;

		@include media(md-large) {
			flex-shrink: 0;
			min-width: 320px;
		}

		@include media(md-up) {
			max-width: 320px;
		}

		.g-squiggle {
			opacity: 0;
		}
	}

	&-sidebar_content {
		@include media(md-large-down) {
			min-width: 260px;
		}
	}

	&-locale {
		align-items: center;
		display: flex;
		margin: 20px 0 0;
	}

	&-locale_selector {
		background-color: $color-bg;
		color: $color-input-text;
		cursor: pointer;
		margin-left: 10px;
	}

	&-payment_methods {
		display: inline-flex;
		width: 390px;

		@include media(sm) {
			max-width: 390px;
			width: auto;
		}

		@include media(md-up) {
			flex-shrink: 0;
		}
	}

	&-img {
		margin-left: 10px;

		@include media(sm) {
			margin-left: 5px;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	&-app {
		color: $color-white;
		font-size: 14px;
		line-height: 1.25;
		margin-right: 40px;
		min-width: 120px;
		width: 120px;

		@include media(sm) {
			display: none;
		}

		@include media(md-large) {
			display: flex;
			margin-right: 0;
			width: 250px;
		}

		.b-footer-sidebar & {
			@include media(md-up) {
				display: none;
			}

			@include media(sm) {
				display: block;
				margin-right: 0;
				width: auto;
			}
		}
	}

	&-app_banners {
		@include media(md-large) {
			width: 120px;
		}

		@include media(sm) {
			display: flex;
			justify-content: space-between;
			margin-top: 15px;
		}
	}

	&-app_banner {
		@include media(md-up) {
			margin-bottom: 15px;
		}
	}

	&-app_image {
		display: block;

		@include media(sm) {
			width: 144px;
		}
	}

	&-app_qr {
		@include media(md-large) {
			margin-right: 10px;
			order: -1;
			width: 120px;
		}

		@include media(sm) {
			display: none;
		}
	}

	&-qr_image {
		background-color: $color-white;
		border-radius: 10px;
		display: block;
		margin-bottom: 5px;
	}

	&-qr_text {
		margin-right: -10px;
	}
}
