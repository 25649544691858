/*md

# b-accordion

Please see [g-accordion](02-components-g-accordion.html) for details

*/

.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-button {
		@include g-accordion(_control);

		&:hover {
			@include g-accordion(_control, hover);
		}

		.b-icon_chevron {
			margin-left: 15px;
		}
	}

	&-button_icon {
		margin-left: 15px;

		[aria-expanded='true'] & {
			transform: rotate(180deg);
		}
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}

		// Make content accessible until JS is loaded
		.b-accordion-item:not([data-initialized='1']) & {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
