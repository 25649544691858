/*md

# b-global_alerts

This alerts used to notify blind user about dynamic changes on the page. Ex: load more products,
filters applied, sorting applied etc. To meet basic a11y requirements.

It is recommended to not hide this alerts and make them visible - it ease of testing
and fix as soon as it broken.

This messages should not have prominent visual styles.

```html_example
<div class="b-global_alerts">
    <div
        class="b-global_alerts-item"
        role="alert"
    >
        Filters applied
    </div>
</div>
```

## What this alerts covers

This is middle level between page and components.

Please see more [info](https://confluence.ontrq.com/pages/viewpage.action?pageId=228864950)

## Visually hide alerts

It is possible to hide this alerts visually, but it is not recommended.

We got special modificator to do so - `m-visually_hidden`. Please see hideAccessibilityAlerts site pref to more info.

*/

.b-global_alerts {
	bottom: 20px;
	left: 50%;
	position: fixed;
	text-align: center;
	transform: translateX(-50%);
	z-index: z(notifier);

	&.m-visually_hidden {
		@include hide(visually);
	}

	&-item {
		animation: slide-from-bottom $motion-ease;
		background-color: $color-accent-light;
		color: $color-primary;
		cursor: default;
		display: inline-block !important; //stylelint-disable-line
		font-size: 15px;
		line-height: 1.5;
		margin: 0 auto;
		max-width: 400px;
		min-width: 206px;
		padding: 10px 40px;

		&[hidden] {
			@include hide(visually);

			pointer-events: none;
		}
	}

	.m-notification {
		background: $color-green-light;
		border-radius: 10px;
		font-weight: 600;
		line-height: 1.25;
		max-width: 152px;
		min-width: auto;
		padding: 13px 16px;

		&::before {
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMiAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMS41NjMxIiByPSIxMSIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTkuMzMzMzcgMTYuNTYzMUM5LjEzMzM3IDE2LjU2MzEgOS4wMDAwNCAxNi40OTY0IDguODY2NzEgMTYuMzYzMUw1LjUzMzM3IDEzLjAyOThDNS4yNjY3MSAxMi43NjMxIDUuMjY2NzEgMTIuMzYzMSA1LjUzMzM3IDEyLjA5NjRDNS44MDAwNCAxMS44Mjk4IDYuMjAwMDQgMTEuODI5OCA2LjQ2NjcxIDEyLjA5NjRMOS4zMzMzNyAxNC45NjMxTDE2LjIgOC4wOTY0MkMxNi40NjY3IDcuODI5NzYgMTYuODY2NyA3LjgyOTc2IDE3LjEzMzQgOC4wOTY0MkMxNy40IDguMzYzMDkgMTcuNCA4Ljc2MzA5IDE3LjEzMzQgOS4wMjk3Nkw5LjgwMDA0IDE2LjM2MzFDOS42NjY3MSAxNi40OTY0IDkuNTMzMzcgMTYuNTYzMSA5LjMzMzM3IDE2LjU2MzFaIiBmaWxsPSIjQTFCRDNFIi8+Cjwvc3ZnPg==');
			background-position: top center;
			background-repeat: no-repeat;
			background-size: 22px;
			content: '';
			display: block;
			height: 30px;
		}

		&_copy {
			&::before {
				background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMyIgdmlld0JveD0iMCAwIDIyIDIzIiBmaWxsPSJub25lIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMS4wNjMxIiByPSIxMSIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTUuNzgwNTIgMy44NjI4NUM0LjY5MyAzLjg2Mjg1IDMuODAwNzggNC43NTUwNSAzLjgwMDc4IDUuODQyNTlWMTMuMzIzQzMuODAwNzggMTQuNDEwNSA0LjY5Mjk4IDE1LjMwMjcgNS43ODA1MiAxNS4zMDI3SDEzLjI2MDlDMTQuMzQ4NSAxNS4zMDI3IDE1LjI0MDcgMTQuNDEwNSAxNS4yNDA3IDEzLjMyM1Y1Ljg0MjU5QzE1LjI0MDcgNC43NTUwNyAxNC4zNDg1IDMuODYyODUgMTMuMjYwOSAzLjg2Mjg1SDUuNzgwNTJaTTUuNzgwNTIgNC45NDE3M0gxMy4yNjA5QzEzLjc2ODggNC45NDE3MyAxNC4xNTkxIDUuMzM0NyAxNC4xNTkxIDUuODQyNTlWMTMuMzIzQzE0LjE1OTEgMTMuODMwOSAxMy43Njg4IDE0LjIyMTIgMTMuMjYwOSAxNC4yMjEySDUuNzgwNTJDNS4yNzI2MSAxNC4yMjEyIDQuODc5NjYgMTMuODMwOSA0Ljg3OTY2IDEzLjMyM1Y1Ljg0MjU5QzQuODc5NjYgNS4zMzQ2OCA1LjI3MjYzIDQuOTQxNzMgNS43ODA1MiA0Ljk0MTczWiIgZmlsbD0iI0NGREZBNiIvPjxwYXRoIGQ9Ik0xNC42OTgyIDYuODI0NzdDMTQuNTU1MiA2LjgyNDg2IDE0LjQxOCA2Ljg4MTc1IDE0LjMxNjkgNi45ODI5NEMxNC4yMTU3IDcuMDg0MDQgMTQuMTU4OCA3LjIyMTIyIDE0LjE1ODcgNy4zNjQyQzE0LjE1ODggNy41MDcyOCAxNC4yMTU3IDcuNjQ0MzcgMTQuMzE2OSA3Ljc0NTQ3QzE0LjQxOCA3Ljg0NjY2IDE0LjU1NTIgNy45MDM1NSAxNC42OTgyIDcuOTAzNjRIMTYuMjIwOEMxNi43MjQzIDcuOTAzNjQgMTcuMTE5IDguMzAxMDcgMTcuMTE5IDguODA0NVYxNi4yODQ5QzE3LjExOSAxNi43ODg0IDE2LjcyNDIgMTcuMTgzMSAxNi4yMjA4IDE3LjE4MzFIOC43NDAzOUM4LjIzNjk0IDE3LjE4MzEgNy44Mzk1MyAxNi43ODgzIDcuODM5NTMgMTYuMjg0OUw3LjgzOTYyIDE0Ljc2NDlDNy44NDAxNiAxNC42MjE0IDcuNzgzNjIgMTQuNDgzNSA3LjY4MjQ0IDE0LjM4MThDNy41ODExNiAxNC4yODAxIDcuNDQzNjMgMTQuMjIyOSA3LjMwMDE4IDE0LjIyMjhDNy4xNTY2NiAxNC4yMjI5IDcuMDE5MTMgMTQuMjgwMSA2LjkxNzkzIDE0LjM4MThDNi44MTY3NCAxNC40ODM1IDYuNzYwMTIgMTQuNjIxNCA2Ljc2MDc1IDE0Ljc2NDlWMTYuMjg0OUM2Ljc2MDc1IDE3LjM3MiA3LjY1MzQgMTguMjY0NyA4Ljc0MDQ4IDE4LjI2NDdIMTYuMjIwOUMxNy4zMDggMTguMjY0NyAxOC4yMDA2IDE3LjM3MiAxOC4yMDA2IDE2LjI4NDlWOC44MDQ1QzE4LjIwMDYgNy43MTc0MiAxNy4zMDggNi44MjQ3NyAxNi4yMjA5IDYuODI0NzdIMTQuNjk4MloiIGZpbGw9IiNDRkRGQTYiLz4KCjwvc3ZnPg==');
			}
		}
	}
}
