.b-heading_1 {
	@include g-heading_1;
}

.b-heading_2 {
	@include g-heading_2;
}

.b-heading_3 {
	@include g-heading_3;
}

.b-heading_4 {
	@include g-heading_4;
}

.b-heading_4_1 {
	@include g-heading_4;
}

.b-heading_5 {
	@include g-heading_5;
}

.b-heading_6 {
	@include g-heading_6;
}
