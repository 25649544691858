/* stylelint-disable */
.osano-cm-dialog {
	border: 1px solid #000000;
}

.osano-cm-button--type_save,
.osano-cm-close,
.osano-cm-dialog__list,
.osano-cm-widget {
	display: none;
}

.osano-cm-content__message {
	padding-bottom: 10px;
}
/* stylelint-enable */
