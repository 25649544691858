.b-payment_total {
	text-align: left;
	width: 100%;

	@include media(lg-up) {
		display: none;
	}

	.b-minicart & {
		display: table;
	}

	&-value {
		text-align: right;
		vertical-align: top;
	}

	&-name,
	&-value {
		font-size: 15px;
		font-weight: 400;
		line-height: 1.5;
		padding: 5px 0;

		.m-total & {
			background: $color-white;
			font-weight: 600;
			padding-top: 15px;
		}
	}

	&-tax {
		display: block;
		font-size: 12px;
		font-weight: 500;
	}
}
