.g-content_tile {
	background-color: $color-bg;
	border-radius: $global-radius;
	box-shadow: $depth-1;
	height: 100%;
	overflow: hidden;

	&-picture {
		display: block;
		font-size: 0;

		.g-image {
			width: 100%;
		}
	}

	&-caption {
		padding: 10px;

		&.m-promo {
			padding: 15px 20px;
		}
	}

	&-title {
		@include g-title(true);

		&.m-promo {
			color: $color-black;
			font-size: 20px;
			line-height: 25px;
			margin-bottom: 10px;
		}
	}

	&-subtitle {
		&.m-promo {
			@include line-clamping(2);

			font-size: 13px;
			line-height: 20px;
			margin-bottom: 15px;
		}
	}

	&-link {
		color: $color-action-obj;
		text-decoration: none;

		@include hover-supported {
			&:hover {
				text-decoration: underline;
			}
		}

		&.m-bold {
			font-weight: 500;
		}
	}

	&-link,
	&-subtitle {
		.b-highlights-link:hover &,
		.b-featured_category-link:hover & {
			@include hover-supported {
				text-decoration: underline;
			}
		}
	}

	&-actions {
		margin-top: 15px;
	}

	&.m-horizontal {
		display: flex;
		flex-direction: row-reverse;

		.g-image {
			height: 100%;
		}
	}

	&.m-horizontal &-picture,
	&.m-horizontal &-caption {
		@include media(md-up) {
			flex: 0 0 50%;
		}
	}

	&.m-horizontal &-picture {
		@include media(sm) {
			flex: 0 0 43%;
		}
	}
}
