.b-seo_banner {
	@include g-section_holder;

	margin: 35px auto;

	@include media(md-up) {
		margin: 50px auto;
	}

	@include media(xl) {
		max-width: $plp-content-width-max;
	}

	&-block {
		border-top: 1px solid $color-border-grey;
		padding: 15px 0 30px;
		width: 100%;

		@include media(md-up) {
			padding: 20px 0 30px;
		}
	}

	&-title {
		color: $color-primary;
		font-size: 13px;
		font-weight: 600;
		line-height: 1.25;
		padding: 0 10px;

		@include media(md-up) {
			padding: 0 15px;
		}
	}

	&-carousel {
		&.m-products {
			@include media(md-down) {
				margin-left: 0;
				margin-right: 0;
			}

			.b-carousel-ctrl {
				&.m-prev {
					left: -10px;
				}

				&.m-next {
					right: -10px;
				}
			}
		}
	}

	&-links {
		display: flex;
		flex-flow: column wrap;
		padding: 0 10px;

		@include media(md-up) {
			flex-flow: row nowrap;
			padding: 0 15px;
		}
	}

	&-link {
		color: $color-action-obj;
		display: inline-block;
		font-size: 13px;
		font-weight: 500;
		margin: 30px 0 0;
		padding-right: 15px;
		position: relative;
		text-decoration: underline;

		@include media(md-up) {
			margin: 20px 55px 0 0;
		}

		@include hover-supported {
			&:hover {
				text-decoration: none;
			}
		}
	}

	&-link_icon {
		position: absolute;
		right: 0;
	}

	.b-product_tile {
		padding-bottom: 0;
	}
}
