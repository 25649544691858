.b-ugc {
	margin: 30px auto;

	@include media(lg-up) {
		margin: 50px auto;
	}

	.l-static_page & {
		@include g-section_holder;

		padding-bottom: 30px;
		padding-top: 30px;

		@include media(lg-up) {
			padding-bottom: 50px;
			padding-top: 50px;
		}
	}

	.l-clp & {
		@include g-section_holder;

		padding-bottom: 30px;
		padding-top: 30px;

		@include media(lg-up) {
			padding-bottom: 50px;
			padding-top: 50px;
		}

		&-inner {
			margin: 0 auto;
			max-width: $content-limit-lg + 45px;
		}
	}

	.l-pdp & {
		@include media(xl) {
			width: grid-span($column: 10);
		}
	}
}
