.b-header_track_order {
	@include media(lg-up) {
		display: none;
	}

	@include media(md-down) {
		@include g-link_hamburger;

		box-shadow: none;
		padding: rh(0 1);
	}

	&-icon {
		@include g-button_iconed;
	}
}
