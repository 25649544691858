.b-success_modal {
	&-window {
		padding: 10px 30px 59px;
	}

	&-body {
		margin-top: 10px;
	}

	&-footer {
		margin-top: 22px;
	}

	&-btn {
		height: 33px;
		margin: auto;
		min-width: 172px;
	}
}
