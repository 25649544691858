@use 'sass:math';

.b-input_search {
	border-color: $color-action-obj;
	border-radius: #{math.div($size-input-height, 2)} 0 0 #{math.div($size-input-height, 2)};
	font-size: 15px;
	min-width: 290px;
	padding-left: 20px;

	@include media(md-up) {
		min-width: 425px;
	}

	&::placeholder {
		color: $color-text-dimmed;
	}
}
