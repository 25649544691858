.b-address {
	color: $color-primary;
	line-height: 1.5;

	&-title {
		font-weight: 600;
	}

	&-subtitle {
		margin: 12px 0 16px;
	}

	&-type {
		@include media(md-down) {
			margin-bottom: 16px;
			order: -1;
		}

		@include media(lg-up) {
			margin: 10px 0 16px;
		}
	}

	&.m-email {
		margin-top: 8px;
		word-wrap: break-word;
	}

	&.m-phone {
		margin-top: 6px;

		.b-summary_group.m-billing & {
			display: none;
		}
	}

	&.m-clickcollect {
		margin-bottom: 10px;
	}

	.m-checkout_shipping & {
		@include media (md-down) {
			font-size: 15px;
		}
	}
}
