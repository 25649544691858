.g-squiggle {
	position: relative;
	z-index: 0;

	&-wrap {
		position: relative;
	}

	&-phrase.m-plain {
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&-phrase.m-graphic {
		opacity: 0;
		visibility: hidden;
	}

	&-icon {
		color: var(--color-squiggle, $color-orange);
		left: 50%;
		position: absolute;
		top: 40%;
		transform: translate(-50%, 0);
		z-index: -1;
	}
}
