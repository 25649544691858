.b-suggestions_guess {
	color: $color-text;
	font-size: 16px;
	font-weight: 300;
	margin-bottom: 0;
	text-decoration: none;

	&.m-focused,
	&:hover {
		text-decoration: none;
	}

	&-correction_wrap {
		font-weight: 500;
	}

	&-correction {
		text-decoration: underline;
		transition: color $motion-fast;

		.b-suggestions_guess.m-focused &,
		.b-suggestions_guess:hover & {
			color: $color-action;
		}
	}
}
