.b-cart_quickview {
	margin-bottom: 24px;

	&-title {
		@include g-title;

		margin-bottom: 13px;
		text-align: left;
	}

	&-actions {
		font-size: 15px;
		margin-top: 24px;

		@include media(lg-up) {
			display: flex;
			justify-content: space-between;
		}
	}

	&-button {
		width: 100%;

		@include media(lg-up) {
			width: calc(50% - 12px);
		}

		& + & {
			margin-top: 20px;

			@include media(lg-up) {
				margin-top: 0;
				order: -1;
			}
		}
	}

	&-slots {
		@include media(lg-up) {
			margin: 0 50px;

			.b-carousel {
				.b-carousel-item {
					max-width: 33.33%;
					min-width: 33.33%;
				}
			}
		}

		> div {
			min-height: 365px;

			@include media(md) {
				min-height: 410px;
			}

			&:empty {
				@include g-loader;

				position: relative;

				&::after {
					opacity: 1;
				}
			}
		}

		.b-slot_bg {
			overflow: visible;
		}

		.b-carousel.m-products {
			margin: 0;

			.b-carousel-title {
				font-size: 20px;
				margin-bottom: 0;
			}

			.b-product_tile {
				padding-bottom: 40px;
			}

			.b-product_tile-cta {
				display: flex;
			}
		}
	}
}
