.b-footer_trustpilot {
	@include g-section_holder_footer;

	width: 100%;

	@include media(sm) {
		margin-top: 20px;
	}

	&::before {
		background-color: $color-border-grey;
		content: '';
		display: block;
		height: 1px;
		margin-bottom: 20px;
		width: 100%;
	}

	&-logo {
		margin: 10px auto;
	}
}
