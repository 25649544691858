//to avoid upload font files from Global E

/* stylelint-disable */
.globale_popup_wrapper a.changeShipping,
input.backToShop.glDefaultBtn,
.glPopupContent .texts,
.glTitle,
.globale_popup_wrapper .SubheaderOfInfo.Switcher,
select#gle_selectedCountry,
select#gle_selectedCurrency,
.glMainContent.Switcher .caption,
.globale_popup_wrapper input.glDefaultBtn.glSaveBtn,
.globale_popup_wrapper .HeaderOfInfo.Switcher,
select#gle_selectedMerchantCurrency {
	font-family: $font-sans !important;
}
/* stylelint-enable */
#FreeShippingBanner {
	background-color: $color-burgundy;
	color: $color-white;
	display: none;
	font-size: 13px;
	margin-bottom: 10px;
	padding: 5px 0;
	text-align: center;
}
