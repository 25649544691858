.b-suggestions_product {
	background-color: $color-bg;
	border: 1px solid $color-divider-light;
	border-radius: 2px;
	box-shadow: $depth-1;
	color: $color-text;
	display: flex;
	margin: 0 30px 12px 0;
	text-decoration: none;

	@include media(md-up) {
		margin-bottom: 24px;
	}

	@include media(lg-up) {
		margin-right: 0;
	}

	&:hover {
		text-decoration: none;
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(124, 120));

		max-width: 100%;
		width: 124px;

		img {
			@include g-image_container(_img);
		}
	}

	&-info {
		margin: 12px 16px 12px 12px;
	}

	&-title {
		@include line-clamping;

		font-size: 13px;
		font-weight: 400;
		margin-bottom: 8px;
		transition: color $motion-fast;

		.b-suggestions_product:hover &,
		.b-suggestions_product.m-focused & {
			color: $color-action;
			text-decoration: underline;
		}
	}

	&-price_info {
		margin-right: 8px;
	}

	&-price {
		font-size: 15px;
		margin-bottom: 8px;
	}

	&-price_item {
		color: $color-text-grey;
		font-weight: 500;
		text-decoration: line-through;
	}

	.b-price-item {
		margin-right: 10px;
	}

	.b-rating-icon {
		min-width: 95px;
	}
}
