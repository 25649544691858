.g-badge {
	border-radius: 2px;
	color: $color-text-promo;
	font-size: 13px;
	font-weight: 500;
	left: 10px;
	line-height: 1.25;
	max-width: calc(100% - 20px); //same spasing bouth sides
	padding: 4px 8px;
	position: absolute;
	top: 10px;

	@include media(lg) {
		font-size: 16px;
		line-height: 20px;
	}

	@include media(xl) {
		font-size: 18px;
		line-height: 23px;
	}

	&-wrapper {
		position: relative;
	}

	&.m-corner {
		border-radius: 4px 0 10px;
		font-size: 20px;
		font-weight: 700;
		left: 0;
		max-width: 120px;
		padding: 12px 15px 15px;
		top: 0;
		word-break: break-word;

		@include media(md-up) {
			border-radius: 4px 0 20px;
		}
	}

	&.m-corner-small {
		border-radius: 4px 0 10px;
		font-weight: 700;
		left: 0;
		min-width: 63px;
		padding: 6px 10px;
		top: 0;

		@include media(md-up) {
			border-radius: 4px 0 20px;
			font-size: 20px;
			padding: 7px 11px;
		}

		@include media(lg-up) {
			min-width: 77px;
		}

		@include media(xl) {
			min-width: 103px;
			padding: 15px 17px;
		}
	}

	&.m-sale {
		background-color: $color-fg-promo;
	}

	&.m-new {
		background-color: $color-blue3;
	}

	&.m-campaign {
		background-color: $color-bg-tag;
	}

	&.m-friday {
		background-color: $color-action-obj;
	}

	&.m-soon {
		background-color: $color-white;
		border: 1px solid $color-blue3;
		color: $color-blue3;
		display: inline-block;
		font-size: 13px;
		left: auto;
		line-height: 14px;
		position: relative;
		top: auto;
		width: fit-content;
	}
}
