@mixin g-page-designer-typography() {
	h1,
	.title-h1 {
		@include g-heading_1;
	}

	h2 {
		@include g-heading_2;
	}

	h3 {
		@include g-heading_3;
	}

	h4 {
		@include g-heading_4;
	}

	h5 {
		@include g-heading_5;
	}

	h6 {
		@include g-heading_6;
	}

	h1,
	.title-h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 20px;

		@include media(sm) {
			margin-bottom: 12px;
		}

		.m-promotional & {
			margin-bottom: 10px;

			@include media(md-down) {
				margin-bottom: 0;
			}
		}
	}

	ul,
	ol {
		display: block;
		margin-bottom: 16px;
		padding-left: 20px;
	}

	li {
		display: list-item;
		margin-bottom: 4px;
	}

	ul {
		list-style: disc outside;
	}

	ol {
		list-style: decimal outside;
	}

	p {
		margin-bottom: 16px;
	}

	blockquote {
		border-left: 4px solid hsl(0, 0%, 73%);
		font-style: italic;
		margin: 0 0 16px;
		padding: 0 0 0 20px;
	}

	a:hover {
		text-decoration: none;
	}

	// stylelint-disable
	*:last-child {
		margin-bottom: 0;
	}
	// stylelint-enable
}
