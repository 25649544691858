/*md

# b-promotion

Designed to provide same styles of promotion across different components.

We do not support any HTML in promotion messages.

## Promotion with configured link

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for <a href="#">order</a>
	</div>
</div>
```

## Empty promotion

```html_example
<div class="b-promotion">
	<div class="b-promotion-message"></div>
</div>
```

## Promotion with open dialog button

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for order
	</div>
	<button
		class="b-promotion-details"
		type="button"
		data-widget="emitBusEvent"
		data-bus-event-type="dialog.show"
		data-event-click.prevent="emitBusEvent"
		data-tau="promotion_details_cta"
		data-initialized="1"
	>
		Details
	</button>
</div>
```

*/

.b-promotion {
	align-items: center;
	color: $color-fg-promo;
	display: flex;
	position: relative;

	&.m-plus {
		color: $color-primary;
		display: none;
	}

	&-message {
		align-items: center;
		border: 1px solid $color-fg-promo;
		border-radius: 2px;
		display: flex;
		font-size: 11px;
		gap: 4px;
		line-height: 1.4;
		margin-top: 8px;
		padding: 2px 8px;

		&.m-link {
			&:hover {
				border-color: $color-burgundy-light1;
				color: $color-burgundy-light1;
			}
		}

		&.m-empty {
			display: none;
		}

		a {
			color: inherit;
			text-decoration: underline;

			&:hover {
				color: $color-burgundy-light1;
			}
		}

		button {
			&:hover {
				color: $color-burgundy-light1;
			}
		}
	}

	&.m-plus &-message {
		background-color: $color-bg-shade;
		border-color: $color-bg-shade;
	}

	&-details {
		color: $color-fg-promo;
		cursor: pointer;
		text-align: left;
		text-decoration: underline;
	}

	&.m-bundle &-message {
		font-size: 14px;
		font-weight: 500;
		line-height: 1;
		margin: 0;
		padding: 8px;

		&.m-bundle {
			border-radius: 2px;
			font-size: 11px;
			font-weight: 400;
			line-height: 1.5;
			padding: 1px 8px;
		}
	}

	&.m-badge &-message {
		background-color: $color-icon-availability;
		border-color: $color-icon-availability;
		color: $color-text-white;
	}

	.b-product_details & {
		margin: 10px 0;

		&.m-bundle {
			margin: 8px 0;
		}
	}
}
