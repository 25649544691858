@media print {
	@page {
		margin: 10mm;
		padding: 0;
		size: a4; // stylelint-disable-line
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
	}

	.b-hide_print {
		display: none !important; // stylelint-disable-line
	}

	.b-header_utility,
	.l-header,
	.b-menu_panel,
	.b-footer {
		display: none;
	}
}
