.b-suggestions {
	background-color: $color-bg;
	overflow-y: auto;
	padding: 12px 0 32px;
	position: relative;
	right: 0;
	width: 100%;
	z-index: z(components, search, suggestions);

	@include media(sm) {
		padding-bottom: 8px;
	}

	// Layout
	&-inner {
		@include g-section_holder;

		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: $search-box-width-max;
		transition: opacity $motion-ease;

		@include media(md-up) {
			padding-left: 20px;
			padding-right: 20px;
		}

		&.m-empty { // for error message
			align-items: center;
			color: $color-text-dimmed;
			display: flex;
			justify-content: center;
			min-height: 120px;
			padding: 0 rh(2);
			text-align: center;
		}

		.b-suggestions.m-loading & {
			opacity: 0.3;
			pointer-events: none;
		}
	}

	&-section {
		grid-column: span 12;

		@include media(sm) {
			margin-bottom: 30px;
		}

		@include media(md) {
			grid-column: span 6;

			&.m-categories {
				grid-column: span 12;
			}
		}

		@include media(lg-up) {
			grid-column: span 4;

			&.m-one_type {
				grid-column: span 6;
			}
		}

		@include media(xl) {
			grid-column: span 3;

			&.m-one_type,
			&.m-categories {
				grid-column: span 6;
			}
		}

		@include media(md-up) {
			&.m-col_2 {
				grid-column: span 6;
			}
		}
	}

	&-grid {
		@include g-grid;

		@include media(sm) {
			display: block;
		}
	}

	// Content
	&-title {
		border-bottom: 1px solid $color-divider;
		color: $color-title;
		font-size: 17px;
		font-weight: 600;
		margin-bottom: 24px;
		padding-bottom: 4px;

		@include media(sm) {
			font-size: 15px;
			margin-bottom: 16px;
			padding-bottom: 7px;
		}
	}

	&-options {
		font-size: 15px;

		&.m-tags {
			display: flex;
			flex-wrap: wrap;
		}

		&.m-links {
			display: inline-flex;
			flex-direction: column;
		}
	}

	&-option {
		margin-bottom: 16px;

		@include media(sm) {
			font-size: 13px;
		}

		&.b-tag {
			font-size: 15px;
			line-height: 1;
			margin-right: 16px;
		}
	}

	&-message {
		@include hide(visually);
	}

	&-view_results {
		@include media(sm) {
			width: 100%;
		}

		&.m-focused {
			background: $color-action-obj;
			color: adjust-color-to-bg($color-action-obj);
		}
	}
}
