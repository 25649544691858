.b-country_dialog {
	overflow-y: auto;
	transition: none;

	&-window {
		background: $color-white url('../images/modal-bg/modal-bg.png') no-repeat;
		min-width: auto;
		width: 320px;
	}

	&-label {
		color: $color-text-dimmed;
		display: flex;
		font-size: 13px;
		font-weight: normal;
		line-height: 1.45;
		margin: 16px 0 6px;
	}

	&-icon {
		height: 6px;
	}

	&-button {
		max-height: 41px;
		max-width: 178px;
	}

	&-input {
		color: $color-primary;
		font-size: 13px;
	}

	&.m-opened {
		transition: $motion-ease-popups;
		transition-property: visibility, background-color;
	}
}
