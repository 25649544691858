.b-header_account {
	@include g-header_item;

	.b-menu-item & {
		@include g-header_item_menu;

		&-icon {
			height: 28px;
			margin: -4px 6px -4px -4px;
			width: 28px;
		}
	}
}
