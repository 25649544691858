/*md

# b-link

```html_example
<a class="b-link" href="../customer-services/cp-contact-us.html">our A-Z directory of brands</a>
```
*/

.b-link {
	@include g-link;
}
