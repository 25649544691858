.b-search_dialog {
	height: 100%;
	width: 100%;

	&-inner {
		display: flex;
		flex-direction: column;
		max-height: 100%;
		position: relative;
		width: 100%;
		z-index: z(search);
	}

	&-inner_top {
		background-color: $color-bg;
	}

	&-inner_top_content {
		@include g-section_holder;

		display: flex;
		justify-content: center;
		max-width: $search-box-width-max;

		@include media(md-up) {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include media(sm) {
			padding-right: 8px;
		}
	}

	&-form_wrap {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 12px 0 16px;
		position: relative;
		width: 100%;

		@include media(lg-up) {
			margin: 28px 0 24px;
			padding: 0;
		}
	}

	&-cancel {
		color: $color-action-obj;
		cursor: pointer;
		padding: 12px;
		transition: color $motion-fast;

		&:focus,
		&:hover {
			color: $color-action-dark;
		}
	}
}
