.b-tag {
	background-color: $color-bg-tag;
	border-radius: $controls-radius;
	color: $color-text-promo;
	display: inline-flex;
	font-weight: 500;
	height: $size-input-height;
	padding: 12px 25px;
	transition: background-color $motion-ease;

	@include hover-supported {
		&:hover {
			background-color: $color-bg-tag-hover;
			text-decoration: none;
		}
	}
}
