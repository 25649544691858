.b-footer_social {
	margin: 20px 0 0;

	&-items {
		color: $color-action-obj;
		display: flex;
		margin: 12px 0 0;
	}

	&-link {
		@include g-button_iconed;

		background-color: $color-bg-footer-region-2;
		border-radius: 50%;
		margin-right: 20px;

		&:last-child {
			@include media(md-large-down) {
				margin-right: 0;
			}
		}
	}

	svg {
		fill: $color-action-obj;
	}

	image {
		filter: invert(9%) sepia(16%) saturate(7352%) hue-rotate(302deg) brightness(92%) contrast(95%);
	}
}
