.b-icon_chevron {
	display: block;
	flex-shrink: 0;
	height: 14px;
	margin-left: auto;
	position: relative;
	width: 14px;

	&::after,
	&::before {
		animation-duration: 0.23s;
		animation-fill-mode: forwards;
		animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		border-bottom: 2px solid;
		content: '';
		display: block;
		height: 2px;
		position: absolute;
		top: 50%;
		width: 9px;
	}

	&::before {
		animation-name: animation-chevron-down;
		border-bottom-left-radius: 1px;
		border-top-left-radius: 1px;
		left: 0;

		[aria-expanded='true'] ~ &,
		[aria-expanded='true'] & {
			animation-name: animation-chevron-up;
		}
	}

	&::after {
		animation-name: animation-chevron-up;
		border-bottom-right-radius: 1px;
		border-top-right-radius: 1px;
		right: 0;

		[aria-expanded='true'] ~ &,
		[aria-expanded='true'] & {
			animation-name: animation-chevron-down;
		}
	}
}
