/*md

# Form section

Use this component to add some structure to forms.
It is possible to use any form element inside.

## Input element with label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input1">
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
	</div>
```

## Input element with caption

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input3">
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input3" placeholder="Promo Code" name="couponCode" required="" value="">
		<span class="b-form_section-caption">
			Casing &amp; hyphens need to be exact
		</span>
	</div>
```

## Input element with error

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input4">
			Enter Promo Code
		</label>
		<input class="b-input m-invalid" type="text" id="input4" placeholder="Promo Code" name="couponCode" required="" value="">
		<div role="alert" class="b-form_section-message">
			Coupon cannot be added to your cart
		</div>
		<span class="b-form_section-caption">
			Casing &amp; hyphens need to be exact
		</span>
	</div>
```

## Select element with label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="select1">
			Country
		</label>
		<div class="b-select">
			<select class="b-select-input" id="select1" name="" required="" value="">
				<option value="US" data-id="US">United States</option>
				<option value="CA" data-id="CA">Canada</option>
			</select>
			<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
				<path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
			</svg>
		</div>
	</div>
```

## Checkbox element with label

```html_example
	<div class="b-form_section">
		<div class="b-checkbox">
			<input type="checkbox" class="b-checkbox-input" id="checkox1">
			<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
			</svg>
			<label class="b-form_section-label" for="checkox1">
				By signing up, you agree to <a href="#" target="_blank">Privacy and Cookie Policies</a>
			</label>
		</div>
	</div>
```

*/

.b-form_section {
	margin-bottom: 24px;

	&.m-small {
		width: 250px;
	}

	&.m-highlight {
		background: $color-bg-shade;
		padding: rh(4);
	}

	&.m-fullwidth {
		width: 100%;
	}

	&.m-button_row {
		@include media(sm) {
			text-align: center;
			width: 100%;
		}
	}

	.b-registration-inner &,
	.b-registration-item &,
	.b-data_sheets-item & {
		margin-bottom: 0;
	}

	.b-form & {
		&.m-no_margin {
			margin-bottom: 0;
		}
	}

	.b-track_order & {
		margin-bottom: 10px;
	}

	.b-dialog_migrate & {
		padding: 0 15px;
	}

	.b-data_sheets & {
		width: 100%;
	}

	&-subtitle {
		font-size: 12px;

		&.m-payment {
			margin-top: 24px;
		}
	}

	&-label {
		color: $color-primary;
		display: flex;

		&.m-wai {
			@include hide(visually);
		}

		.b-checkbox & {
			display: block;
		}

		.b-product_notify_me-field & {
			color: $color-text-dimmed;
			font-size: 11px;
			white-space: nowrap;
		}

		.b-registration & {
			line-height: 1.45;
		}
	}

	&-link {
		@include g-link;

		font-weight: 500;

		.b-registration & {
			font-weight: 600;
		}

		.b-product_notify_me-field & {
			text-decoration: none;
		}
	}

	& > &-label {
		font-size: 13px;
		line-height: 1.4;
		margin-bottom: 6px;

		.b-dialog_migrate & {
			font-size: 11px;
			line-height: 15px;
		}
	}

	&-caption {
		display: block;
		font-size: 11px;
		margin-top: 5px;
	}

	&-value {
		display: block;
		font-size: 16px;
		line-height: 1;
	}

	&-message {
		color: $color-error;
		display: block !important; //stylelint-disable-line
		font-size: 11px;
		line-height: 16px;
		margin-top: 4px;

		&[hidden] {
			@include hide(visually);

			pointer-events: none;
		}

		.b-coupon_form-content & {
			margin-top: 4px;
		}
	}

	/* stylelint-disable */
	&.m-required &-label {
		.b-form.m-registration &,
		.b-billing_form &,
		.b-checkout_step.m-summary &,
		.b-form.m-checkout_shipping & {
			&::after {
				content: '*';
			}
		}
	}
	/* stylelint-enable */
	.b-cart_product-qty & {
		margin-bottom: 0;
	}
}
