.b-category_tile {
	margin-bottom: -25px;
	margin-top: 5px;

	@include media(xl) {
		max-width: $plp-content-width-max;
	}

	@include media(sm) {
		margin-bottom: -22px;
		margin-top: 10px;
	}

	&-item {
		border: 0.5px solid $color-divider;
		border-radius: 4px;
		box-shadow: $depth-1;
		cursor: pointer;
		flex-basis: 15%;
		margin-bottom: 16px;
		margin-inline-end: 16px;
		min-width: 15%;
		text-align: center;

		@include media(md) {
			margin-right: 10px;
			min-width: 24%;
		}

		@include media(sm) {
			min-width: 48%;
		}

		&:last-child {
			margin-inline-end: 0;
		}

		.m-alternative_category_tiles &.m-category-tiles {
			margin-bottom: 0;
			min-height: 120px;
			min-width: calc(100% / 7 - 16px);
			width: calc(100% / 7 - 16px);

			@include media(md) {
				min-width: calc(100% / 3.5 - 8px);
				width: calc(100% / 3.5 - 8px);
			}

			@include media(sm) {
				margin-bottom: 0;
				min-width: calc(100% / 2 - 8px);
				width: calc(100% / 2 - 8px);
			}
		}
	}

	&-link {
		display: block;
		max-width: 100%;
		overflow: hidden;
		width: 100%;

		@include media(xl) {
			@include line-clamping(3);

			display: block;

			strong {
				@include line-clamping(2);
			}
		}
	}

	&-img {
		min-height: 130px;
		min-width: 100%;
	}

	&-subtitle {
		color: $color-primary;
		font-size: 13px;
		font-weight: 500;
		line-height: 16px;
		padding: 8px;
	}

	.b-header_category & {
		margin-bottom: -4px;

		@include media(md) {
			margin-right: -32px;
		}

		@include media(sm) {
			margin: 10px -15px -2px 0;
		}

		&-wrapper {
			@include media(md) {
				gap: 16px;
				padding-right: 32px;
			}

			@include media(sm) {
				gap: 10px;
				padding-right: 15px;
			}
		}

		&-item {
			flex-basis: 12%;
			margin-bottom: 5px;
			min-width: 12%;

			@include media(md) {
				margin-inline-end: 0;
				min-width: 20.5%;
			}

			@include media(sm) {
				margin-inline-end: 0;
				min-width: 42%;
			}
		}

		&-subtitle {
			strong {
				font-weight: 500;
			}
		}

		.b-carousel-ctrl {
			&::before {
				content: url('../images/icons/arrow-right.svg');
				display: flex;
				height: 17px;
				justify-content: center;
				width: 100%;
			}

			&.m-next {
				margin: 0;
				right: -25px;

				@include media(md) {
					right: 25px;
				}

				@include media(sm) {
					right: 10px;
				}
			}

			&.m-prev {
				left: -25px;
				margin: 0;

				@include media(sm) {
					left: 10px;
				}

				&::before {
					transform: rotate(180deg);
				}
			}

			svg {
				display: none;
			}
		}
	}

	.m-alternative_category_tiles & {
		@include media(sm) {
			margin-bottom: 0;
			margin-right: -15px;
		}

		&-item {
			border-radius: 20px;
			flex-basis: 190px;
			margin-inline-end: 19px;
			min-width: 190px;
			overflow: hidden;
			position: relative;

			@include media(sm) {
				flex-basis: auto;
				margin-bottom: 0;
				margin-inline-end: 15px;
				min-width: calc(50% - 15px);
				width: calc(50% - 15px);
			}

			&:last-child {
				margin-inline-end: 0;

				@include media(sm) {
					margin-inline-end: 15px;
				}
			}
		}

		&-img {
			border-radius: 19px;
		}

		&-subtitle {
			background: rgba($color-white, 0.9);
			border-radius: 2px;
			color: $color-title;
			font-size: 20px;
			font-weight: 700;
			left: 0;
			line-height: 30px;
			margin: auto;
			max-width: 90%;
			padding: 5px 10px;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
			width: fit-content;

			@include media(sm) {
				font-size: 17px;
				line-height: 1.5;
			}
		}

		&-link {
			text-decoration: none;
		}

		.b-carousel-ctrl {
			background: none;
			border: none;
			box-shadow: none;
			height: 100%;
			margin: 0;
			top: 0;

			@include media(sm) {
				width: 40px;

				svg {
					height: 26px;
					width: 26px;
				}
			}

			svg {
				position: relative;
				z-index: 1;
			}

			&::after {
				background-color: $color-white;
				border: 1px solid $color-mid-grey;
				border-radius: 50%;
				box-shadow: $depth-1;
				content: '';
				display: block;
				height: 50px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 50px;

				@include media(sm) {
					height: 40px;
					width: 40px;
				}
			}

			&::before {
				background: linear-gradient(to left, var(--bg-layout-color, $color-white) 0%, transparent 100%);
				content: '';
				height: 100%;
				position: absolute;
				right: 48%;
				top: 0;
				width: 70px;
				z-index: -1;

				@include media(md) {
					border-radius: 0;
					right: 49%;
				}

				@include media(sm) {
					right: 10px;
				}
			}

			&.m-category-tiles {
				top: 50%;
			}

			&.m-prev {
				left: -24px;

				@include media(sm) {
					left: -15px;
				}

				&::before {
					background: linear-gradient(to right, var(--bg-layout-color, $color-white) 0%, transparent 100%);
					left: 48%;

					@include media(md) {
						border-radius: 0;
						left: 48%;
					}

					@include media(sm) {
						left: 10px;
					}
				}
			}

			&.m-next {
				right: -25px;

				@include media(sm) {
					right: 0;
				}
			}
		}
	}

	.g-badge {
		font-size: 13px;
		line-height: 1.25;
	}
}
