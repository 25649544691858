.b-footer_info {
	background-color: $color-bg-footer-simplified;

	&-inner {
		@include g-section_holder_footer;

		align-items: center;
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
		padding-top: 20px;

		@include media(md-large-down) {
			flex-direction: column;
		}
	}
}
