/*md

# b-link_phone

Phone link with **tel:[number]** scheme

```html_example
<a href="tel:1-888-222-3380" class="b-link_phone">1-888-222-3380</a>
```
*/

.b-link_phone {
	text-decoration: underline;
	transition: color $motion-fast;
	white-space: nowrap;

	&:hover {
		color: $color-action;
		text-decoration: none;
	}

	&.m-simple {
		text-decoration: none;
	}

	&-icon {
		display: inline-flex;
		margin-right: 10px;
		min-width: 15px;
		vertical-align: middle;
	}
}
