@mixin g-page-designer-component-paddings($block-inside) {
	@each $type in ('top', 'right', 'bottom', 'left') {
		@each $size, $breakpoint in $page-designer-gutters {
			&.m-padding_#{$type}_#{$size} #{$block-inside} {
				@each $key, $val in $breakpoint {
					@include media(#{$key}) {
						padding-#{$type}: $val;
					}
				}
			}
		}
	}
}
