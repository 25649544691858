/*md

# b-button_multi_state

This button used to have several states, like: "Add to cart" - "Processed" - "Added" and icon of busy state.
It currently used on PDP and Checkout with Adyen integration.

```html_example
<button class="b-button_multi_state m-processing b-button" type="button">
	<svg class="b-button_multi_state-icon" width="19" height="19" viewBox="0 0 19 19" focusable="false">
	    <g fill="currentColor" transform="rotate(-90 9.5 9.5)">
	        <circle cx="15.9" cy="13.3" r="1.1"/>
	        <circle cx="13" cy="16.2" r="1.1"/>
	        <circle cx="16.9" cy="9.1" r="1.1"/>
	        <circle cx="16.1" cy="5.1" r="1.1" opacity=".9"/>
	        <circle cx="13" cy="2" r="1.1" opacity=".8"/>
	        <circle cx="9.1" cy="1.2" r="1.1" opacity=".6"/>
	        <circle cx="5.1" cy="2" r="1.1" opacity=".6"/>
	        <circle cx="2" cy="5.2" r="1.1" opacity=".5"/>
	        <circle cx="1.2" cy="9.1" r="1.1" opacity=".4"/>
	        <circle cx="1.2" cy="9.1" r="1.1" opacity=".4"/>
	        <circle cx="2" cy="13" r="1.1" opacity=".3"/>
	        <circle cx="5.1" cy="16.1" r="1.1" opacity=".2"/>
	        <circle cx="9.1" cy="16.9" r="1.1" opacity=".1"/>
	        <circle cx="9.1" cy="16.9" r="1.1" opacity=".1"/>
	    </g>
	</svg>
	<span>Processing...</span>
</button>
```

*/

.b-button_multi_state {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;

	&:disabled,
	&.m-disabled {
		opacity: 1;

		&:not(.m-processing, .m-no_disabled) {
			background: $color-action-disabled;
			border: $color-action-disabled;
			color: $color-text-dimmed;
		}
	}

	.b-dialog.m-quick_view & {
		min-height: 46px;
	}

	&.m-processing {
		cursor: wait;
		display: inline-flex;
		pointer-events: none;
	}

	&-icon {
		animation: rotate 1.5s linear infinite;
		display: none;
		margin-right: 8px;

		.m-processing & {
			display: block;
		}

		.b-button_multi_state.m-processing & {
			display: block;
		}
	}

	&-icon_bag {
		margin-right: 6px;

		svg {
			margin-top: -3px;
			width: 21px;
		}
	}

	&.m-store &-icon_bag,
	&.m-processing &-icon_bag,
	&:not(.m-no_disabled):disabled &-icon_bag,
	&:not(.m-no_disabled).m-disabled &-icon_bag {
		display: none;
	}
}
