/*md

# b-message

TBD

*/

.b-message {
	background-color: $color-info-obj;
	border-radius: 2px;
	color: $color-primary;
	margin-bottom: 10px;
	padding: 4px 8px;

	&-button {
		cursor: pointer;

		@include hover-supported {
			&:hover {
				color: $color-action;
			}
		}
	}

	&.m-success {
		background-color: $color-bg;
		border: $global-line solid $color-success-obj;
		color: $color-success;
	}

	&.m-error {
		background-color: $color-bg;
		border: $global-line solid $color-error;
		color: $color-error;

		.b-checkout_login-section &,
		.b-account &,
		.b-track_order &,
		.b-password_reset &,
		.b-data_sheets & {
			background-color: transparent;
			border: none;
			font-size: 11px;
			line-height: 16px;
			padding-left: 18px;
			padding-top: 8px;
			position: relative;
			text-align: start;

			&::before {
				background: url('../images/ma/info.svg') no-repeat;
				content: '';
				height: 15px;
				left: 0;
				position: absolute;
				top: 8px;
				width: 15px;
			}
		}

		.b-password_reset & {
			margin: 0 auto 20px;
			max-width: 288px;
		}

		.l-checkout-messages & {
			background: $color-error;
			border-radius: $global-radius;
			color: $color-text-white;
			padding: 7px 9px 7px 32px;

			&::before {
				background: url('./images/icons/info.svg') no-repeat;
				content: '';
				height: 20px;
				left: 9px;
				position: absolute;
				top: 7px;
				width: 20px;
			}
		}
	}

	&.m-warning {
		background-color: transparent;
		color: $color-error;
		padding: 0;
	}

	// used as a promo banner
	&.m-order_confirmation {
		font-size: 16px;
		font-weight: 500;
		margin-top: 30px;
	}

	&.m-notification {
		background-color: $color-bg-message-notifcation;
		color: $color-text-white;
		font-size: 15px;
		padding: 13px;
		text-align: center;
	}

	&.m-shipping {
		align-items: center;
		background-color: transparent;
		border: 1px solid $color-burgundy;
		color: $color-burgundy;
		display: flex;

		svg {
			width: 18px;
		}
	}

	&-link {
		@include g-link;

		&.m-highlighted {
			font-weight: 600;
		}
	}

	&-inner {
		&.m-warning {
			display: flex;
			text-align: left;
		}

		.m-expanded &.m-not_selected {
			display: none;
		}
	}

	&-icon {
		margin-right: 6px;
	}

	&.m-shipping &-icon {
		margin-right: 10px;
	}
}
