/*md

# g-text_overflow

This is global component designed to reduce text lines and add "..." in the end.

## Usage

```scss
.component-link {
	@include g-text_overflow;
}

.component-link {
	@include g-text_overflow(2);
}
```

*/

@mixin g-text_overflow($number-lines: 1) {
	-webkit-box-orient: vertical;
	// stylelint-disable
	display: -webkit-box;
	// stylelint-enable

	-webkit-line-clamp: $number-lines;
	overflow: hidden;
	text-overflow: ellipsis;
}
