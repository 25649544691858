.b-header_wishlist {
	@include g-header_item;

	&-icon {
		fill: none;

		.b-header_wishlist-link.m-active & {
			animation: heart-bit ease-out 1s;
			animation-delay: 2s;
			color: $color-burgundy;
			fill: $color-burgundy;
		}
	}

	.b-menu-item & {
		@include g-header_item_menu;
	}
}
