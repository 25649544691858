.b-password_estimator {
	margin-top: 8px;

	&-inner {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	&-indicator {
		display: flex;
		padding: 8px 0;
	}

	&-indicator_item {
		background-color: $color-divider-light;
		height: 3px;
		margin-right: 4px;
		transition: background-color $motion-fast;
		width: 35px;

		@include media(md-up) {
			width: 50px;
		}

		&:last-child {
			margin-right: 0;
		}

		&.m-weak {
			background-color: $color-error;
		}

		&.m-medium {
			background-color: $color-warning;
		}

		&.m-strong {
			background-color: $color-success;
		}

		.b-confirmation_create_account & {
			@include media(md) {
				width: 70px;
			}

			@include media(lg) {
				width: 28px;
			}

			@include media(xl) {
				width: 35px;
			}
		}
	}

	&-indicator_description {
		color: $color-text-dimmed;
		font-size: 12px;
		font-weight: 500;
		min-width: 105px;
		padding-left: 10px;
	}

	&-caption {
		color: $color-text-dimmed;
		font-weight: 500;
		margin-top: 4px;
	}
}
