.b-stepper {
	$button-size: 41px;
	$button-size-cart: 24px;

	display: flex;
	user-select: none;
	width: $button-size * 3;

	&.m-cart {
		width: auto;
	}

	&-button {
		border: $global-line solid $color-input-border;
		color: $color-action-obj;
		cursor: pointer;
		display: block;
		line-height: $button-size - 2 * $global-line;
		min-height: $button-size;
		min-width: $button-size;
		text-align: center;
		touch-action: manipulation; // Prevent iOS from double tab to zoom

		&.m-cart {
			border-color: $color-input-border;
			line-height: 20px;
			min-height: $button-size-cart;
			min-width: $button-size-cart;

			svg {
				height: 9px;
				width: 9px;
			}
		}

		&:active {
			background-color: $color-action-obj;
			color: $color-bg;
			fill: currentColor;
		}

		&[disabled] {
			background-color: $color-bg;
			color: $color-text-grey;
			pointer-events: none;
		}

		&.m-minus {
			border-bottom-left-radius: 3px;
			border-top-left-radius: 3px;
		}

		&.m-plus {
			border-bottom-right-radius: 3px;
			border-top-right-radius: 3px;
		}
	}

	&-input {
		appearance: none;
		border-color: $color-input-border;
		border-radius: 0;
		border-style: solid;
		border-width: 1px 0;
		box-shadow: none;
		font-size: 15px; // This is needed to prevent iOS from zoom the page
		font-weight: 500;
		min-height: $button-size;
		min-width: $button-size;
		padding: 0;
		text-align: center;
		width: 100%;

		&.m-cart {
			font-size: 11px;
			min-height: $button-size-cart;
			min-width: $button-size-cart;
		}

		&:focus {
			border-color: $color-action-obj;

			@include media(md-down) {
				font-size: 16px;
			}
		}

		&[disabled] {
			background-color: $color-bg;
			color: $color-text-grey;
			cursor: default;
			opacity: 1;
			-webkit-text-fill-color: $color-action-disabled;
		}
	}
}
