.b-minicart {
	background-color: $color-bg-panel;
	bottom: 0;
	color: $color-primary;
	height: 100%;
	max-width: 400px;
	overflow-y: auto;
	position: fixed;
	right: 0;
	top: 0;
	transform: translateX(100%);
	transition-property: transform;
	visibility: hidden;
	width: 80vw;
	z-index: z(modal);

	&.m-activated { // need this class to not show panel transition on page load. Transition only after user interaction
		transition: $motion-ease-popups;
	}

	&.m-active {
		box-shadow: $depth-3;
		transform: translateX(0);
		transition: $motion-ease-popups;
		visibility: visible;
	}

	&.m-loading_long::before {
		@include g-spinner(3em);

		left: 50%;
		margin: -1em 0 0 -1em;
		position: absolute;
		top: 50%;
	}

	&-inner {
		padding: 20px;

		.b-minicart[aria-busy='true'] & {
			opacity: 0.6;
			transition: opacity $motion-ease;
		}
	}

	&-header {
		margin-bottom: 15px;
	}

	&-messages_item {
		font-size: 13px;
		line-height: 1.45;
		margin-bottom: 10px;
		padding: 4px 8px;
		text-align: left;

		&:last-child {
			margin-bottom: 10px;
		}
	}

	&-content {
		margin-bottom: 30px;
	}

	&-summary {
		margin-bottom: 20px;
	}

	&-title {
		@include g-title;
	}

	&-actions {
		margin-bottom: 30px;

		.b-button {
			font-size: 15px;
		}

		.b-button + .b-button {
			margin-top: 20px;
		}
	}

	&-empty {
		margin-top: 85px;
		text-align: center;
	}

	&-empty_title {
		@include g-heading_4;

		color: $color-title;
		margin: 0 auto 40px;
		max-width: 195px;
	}

	&-empty_button {
		font-size: 15px;
		min-width: 296px;
	}
}
