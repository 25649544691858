@use 'sass:math';

.b-search_input {
	$input-height: 38px;
	$submit-width: 43px;

	align-items: center;
	background-color: $color-bg;
	border-radius: math.div(($input-height + $global-line * 2), 2);
	display: flex;
	font-size: 15px;
	margin-right: 32px;
	position: relative;
	width: 100%;

	@include media(sm) {
		margin-right: 16px;
	}

	&::after {
		border-right: 1px solid $color-divider;
		content: '';
		height: $input-height;
		left: calc(100% + 24px);
		position: absolute;
		width: 0;

		@include media(sm) {
			left: calc(100% + 16px);
		}
	}

	&-submit {
		@include g-button_iconed;

		align-self: stretch;
		background: $color-action-obj;
		border-radius: 0; // normalize IOS input styles
		border-bottom-right-radius: math.div(($input-height - $global-line * 2), 2);
		border-top-right-radius: math.div(($input-height - $global-line * 2), 2);
		color: $color-white;
		height: $input-height;
		transition: background-color $motion-fast;
		width: $submit-width;

		@include hover-supported {
			&:hover,
			&:focus {
				background: $color-action-dark;
			}
		}
	}

	&-submit_icon {
		color: $color-white;
	}

	&-input {
		appearance: none;
		background: transparent;
		border: $global-line solid $color-action-obj;
		border-radius: 0; // normalize IOS input styles
		border-bottom-left-radius: math.div(($input-height - $global-line * 2), 2);
		border-top-left-radius: math.div(($input-height - $global-line * 2), 2);
		box-sizing: border-box;
		color: adjust-color-to-bg($color-bg);
		height: $input-height;
		line-height: $input-height;
		padding: 0 0 0 20px;
		text-align: left;
		width: 100%;

		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration,
		&::-webkit-inner-spin-button {
			appearance: none;
		}

		&::-ms-clear {
			display: none;
		}

		&::placeholder {
			color: $color-text-dimmed;
		}

		&:focus {
			@include media(md-down) {
				font-size: 16px;
			}
		}
	}

	&-clear {
		@include g-link;

		color: $color-text-grey;
		display: none;
		font-size: 13px;
		font-weight: 600;
		margin-right: 12px;
		position: absolute;
		right: $submit-width;

		&.m-visible {
			display: flex;
		}
	}
}
