.b-cart_product {
	display: block;
	position: relative;

	& + & {
		margin-top: 20px;
	}

	&-caption {
		max-width: 100%;
		width: 100%;
	}

	&-head_row {
		display: flex;
	}

	&-item {
		border: $global-line solid $color-divider;
		border-radius: 4px;
		box-shadow: 0 0 8px 0 rgba($color-black, 0.1);
		color: $color-text;
		display: grid;
		grid-template-areas:
			'details details'
			'misc misc'
			'bundle bundle';
		grid-template-columns: 148px 1fr;
		position: relative;

		@include media(md-up) {
			grid-template-areas:
				'details details misc'
				'image bundle bundle';
			grid-template-columns: 166px 1fr 60px;
		}

		& + & {
			margin-top: 8px;
		}

		&.m-alert {
			border-color: $color-error-border;
		}

		&.m-recent {
			grid-template-columns: 106px 1fr;

			@include media(md-up) {
				grid-template-columns: 106px 1fr calc(53% - 106px);
			}
		}
	}

	&-head_cell {
		font-size: 15px;
		font-weight: 400;
		padding: 0 0 10px;
		text-align: left;

		&.m-details {
			width: calc(47% + 106px);

			@include media(xl) {
				padding-left: 24px;
			}

			@include media(lg) {
				padding-left: 14px;
			}

			@include media(md) {
				width: calc(47% + 60px);
			}
		}

		&.m-total {
			margin-left: auto;
			text-align: right;

			@include media(xl) {
				padding-right: 24px;
			}

			@include media(lg) {
				padding-right: 14px;
			}
		}
	}

	&-cell {
		display: block;
		padding-bottom: 10px;
		padding-top: 10px;

		@include media(sm) {
			padding-top: 0;
		}

		&.m-recent {
			padding-bottom: 15px;
			padding-top: 15px;
		}
	}

	&-body {
		display: block;
	}

	&-image {
		grid-area: image;
	}

	&-image_link {
		display: block;
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(1, 1));

		border-radius: 2px;

		img {
			@include g-image_container(_img);
		}
	}

	&-details {
		display: grid;
		font-size: 13px;
		gap: 0 15px;
		grid-area: details;
		grid-template-areas:
			'image details'
			'image promo'
			'image age'
			'message message';
		grid-template-columns: 148px 1fr;
		line-height: 1.54;
		padding: 10px 5px 5px;
		position: relative;

		@include media(md-up) {
			grid-template-areas:
				'image details'
				'image promo'
				'image age'
				'image message';
			grid-template-columns: 166px 1fr;
			padding: 10px;
		}

		&.m-recent {
			grid-template-areas:
				'image details'
				'promo promo'
				'age age'
				'message message';
			grid-template-columns: 76px 1fr;
			padding: 15px 10px 15px 15px;

			@include media(md-up) {
				grid-template-areas:
					'image details'
					'image promo'
					'image age'
					'image message';
			}
		}
	}

	&-details_inner {
		grid-area: details;
	}

	&-misc {
		display: flex;
		grid-area: misc;
		justify-content: flex-end;
		padding-right: 10px;

		@include media(sm) {
			display: block;
			margin-top: -20px;
			padding-bottom: 0;
			padding-left: 168px;
		}

		&.m-recent {
			padding-right: 15px;

			@include media(sm) {
				margin-top: 0;
				padding: 15px 15px 0;
			}
		}
	}

	&-misc_inner {
		@include media(md-up) {
			position: relative;
		}

		@include media(sm) {
			margin-bottom: -5px;
		}

		&.m-recent {
			display: grid;
			gap: 12px;
			grid-template-areas:
				'qty badge'
				'msg total'
				'actions actions';
			margin-bottom: 0;
			width: 100%;

			@include media(md-up) {
				gap: 0;
				grid-template-areas:
					'qty total'
					'msg msg'
					'actions actions';
			}
		}
	}

	&-title {
		font-weight: 500;
		margin-bottom: 3px;

		@include media(sm) {
			padding-right: 25px;
		}

		.m-recent & {
			margin-bottom: 8px;
		}

		a {
			@include g-link;

			text-decoration: none;
			transition: $motion-ease;
			transition-property: color;
		}
	}

	&-qty_wrap {
		margin-top: 8px;
		width: 75px;

		@include media(md-up) {
			margin-bottom: 10px;
		}

		&.m-recent {
			grid-area: qty;
			margin-top: 0;
			width: 91px;
		}
	}

	&-qty {
		position: relative;

		&.m-preselected {
			font-size: 13px;
		}
	}

	&-availability {
		grid-area: msg;

		@include media(sm) {
			margin-top: 10px;
		}
	}

	&-price {
		display: flex;

		&.m-total {
			font-size: 17px;
			justify-content: flex-end;
		}

		.b-price {
			flex-wrap: nowrap;

			@include media(sm) {
				height: 18px;
			}
		}

		.b-price-subtotal {
			@include media(sm) {
				bottom: 0;
				display: block;
				position: absolute;
				right: 0;

				.m-recent & {
					display: flex;
					position: static;
				}
			}
		}

		.b-price-subtotal_item {
			@include media(md-up) {
				display: inline-block;
				vertical-align: top;

				.m-recent & {
					display: block;
				}
			}
		}
	}

	&-promotion {
		grid-area: promo;

		@include media(md-up) {
			.b-promotion:first-child {
				.b-promotion-message {
					margin-top: 0;
				}
			}
		}

		.b-promotion-message {
			margin: 5px 0;
		}
	}

	&-edit.m-link {
		border: none;
		color: $color-action-obj;
		margin-top: -13px;
		padding: 0;
	}

	&-actions {
		color: $color-text-dimmed;
		display: flex;
		grid-area: actions;
		justify-content: space-between;

		@include media(sm) {
			margin-bottom: 15px;
		}

		@include media(md-up) {
			.b-cart_quickview &,
			.l-cart & {
				align-items: flex-end;
				flex-direction: column;
				justify-content: flex-end;
			}
		}

		&.m-recent {
			@include media(md-up) {
				margin-top: 15px;
			}
		}
	}

	&-remove {
		@include g-link;

		color: $color-text-dimmed;
		font-size: 15px;
		text-decoration: none;
		transition: $motion-ease;
		transition-property: color;

		@include media(md-up) {
			font-size: 11px;
			margin-bottom: 10px;
		}

		@include media(sm) {
			padding: 7px 10px 10px 0;
			position: absolute;
			right: 0;
			top: 0;

			.m-recent & {
				padding: 0;
				position: static;
			}
		}
	}

	&-button_icon {
		animation-duration: 0.23s;
		animation-fill-mode: forwards;
		animation-name: animation-svg-chevron-up;
		animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		color: $color-action-obj;
		padding-left: 5px;

		svg {
			height: 10px;
			width: 16px;
		}
	}

	&-icon {
		svg {
			width: 16px;
		}

		.m-recent & {
			margin-right: 6px;

			svg {
				width: 13px;
			}
		}
	}

	&-icon_label {
		display: none;

		.m-recent & {
			display: inline;
		}
	}

	&-msg {
		color: $color-error;
		display: block;
		font-size: 13px;
		font-weight: 500;
		margin: 5px 0 20px;
	}

	&-badge {
		background-color: $color-bg-cart-badge;
		border-radius: 2px;
		color: $color-white;
		display: inline-block;
		grid-area: badge;
		margin: 5px 0;
		padding: 2px 8px;
		text-align: center;

		&.m-desktop {
			@include media(sm) {
				display: none;
			}

			.m-recent & {
				margin: 15px 0;
			}
		}

		&.m-mobile {
			@include media(md-up) {
				display: none;
			}

			.m-recent & {
				align-self: center;
				justify-self: end;
				margin: 5px 0;
			}
		}
	}

	&-total {
		@include media(md-up) {
			bottom: 0;
			position: absolute;
			right: 0;
		}

		&.m-recent {
			grid-area: total;
			position: static;
		}
	}

	&-bundle {
		grid-area: bundle;
		padding: 0 10px 10px;

		@include media(md-up) {
			padding-left: 25px;
		}
	}

	&-hidden {
		margin-top: 20px;
	}

	&-wishlist_wrapper {
		height: 25px;

		@include media(sm) {
			padding-bottom: 20px;
		}
	}

	&-button {
		border-bottom: $global-line solid $color-divider;
		color: $color-primary;
		cursor: pointer;
		display: flex;
		font-size: 13px;
		justify-content: space-between;
		margin-top: 30px;
		padding: 0 8px 8px;
		text-align: left;
		width: 100%;
	}

	&-button.m-active &-button_icon {
		animation-name: animation-svg-chevron-down;
	}

	.b-evoucher {
		margin-bottom: 25px;
		padding: 0;

		@include media(sm) {
			margin-bottom: 5px;
		}
	}

	.b-price-subtotal_item {
		line-height: 1;
	}

	.b-wishlist_button {
		bottom: 10px;
		font-size: 13px;
		left: 190px;
		position: absolute;

		&.m-simple {
			color: $color-text-dimmed;
			text-decoration: underline;

			@include media(sm) {
				bottom: 4px;
				font-size: 15px;
				left: 168px;
			}
		}

		&-label {
			@include media(sm) {
				display: none;
			}

			.m-recent & {
				@include media(sm) {
					display: inline;
				}
			}
		}

		&[aria-pressed='true'] {
			@include hover-supported {
				&:hover {
					.b-wishlist_button-icon {
						color: $color-action;
						fill: $color-action;
					}
				}
			}
		}
	}

	.m-recent .b-wishlist_button {
		&.m-simple {
			position: static;
			text-decoration: none;

			@include media(md-up) {
				font-size: 11px;
			}
		}
	}
}
