.b-country_selector {
	$line-color: adjust-color-to-bg($color-bg-header-line-1, white, rgba(0, 0, 0, 27%));

	cursor: pointer;

	@include media(lg-up) {
		align-items: center;
		display: flex;
		position: relative;
	}

	&-locale {
		align-items: center;
		appearance: none;
		background: transparent;
		border: none;
		cursor: pointer;
		display: flex;
		width: 100%;

		@include media(lg-up) {
			height: 28px;
			padding: 3px 7px 3px 3px;
		}

		&.m-switcher:hover {
			@include media(lg-up) {
				background: $color-bg-shade;
			}
		}
	}

	&-item {
		width: 100%;
	}

	&-switcher {
		@include media(md-down) {
			@include g-link_hamburger;

			box-shadow: none;
		}

		@include media(lg-up) {
			border: $global-line solid $line-color;
			display: block;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&-locale_language {
		color: $color-text;
		font-size: 14px;
		line-height: 16px;
		margin-left: 5px;
		margin-right: 7px;
	}

	&-locale_icon {
		@include media(md-down) {
			margin-left: auto;
		}
	}

	&-flyout {
		background-color: $color-bg;
		border: 1px solid invert($color-bg-header-line-1, 30%);
		box-shadow: $depth-2-no-top;
		color: $color-text;
		display: none;
		left: 0;
		position: absolute;
		top: 49px;
		width: 100%;
		z-index: z(country-selector);

		&[aria-hidden='false'] {
			display: block;
		}
	}
}
