.b-quick_view {
	$quick-view-modal-indent-right: 22;

	&-window {
		min-height: 80vh;
		opacity: 0;
		padding: 0 20px 20px;
		transition: opacity $motion-ease;

		@include media(md) {
			min-width: calc(100% - #{grid-margin(md) * 2});
		}

		@include media(lg-up) {
			max-width: 686px;
			padding-bottom: 30px;
			width: 100%;
		}
	}

	&-header {
		margin-bottom: 20px;
		padding-top: 0;
	}

	&-body {
		text-align: left;
	}

	&-nav {
		align-items: center;
		display: flex;
		justify-content: space-between;
		min-height: 40px;
		padding-right: 42px;
	}

	&-nav_ctrl {
		align-items: center;
		cursor: pointer;
		display: flex;
		font-size: 13px;
		height: 40px;
		padding: 3px;

		@include media(sm) {
			justify-content: center;
			text-align: center;
		}

		svg {
			margin: 0 2px;
			width: 22px;
		}
	}

	&.m-idea {
		.b-dialog-close {
			right: 12px;
			top: 11px;
		}
	}

	&.m-idea & {
		&-window {
			min-height: 630px;
			opacity: 1;
			padding: 0 16px 16px;

			@include media(sm) {
				width: calc(100% - #{grid-margin(sm) * 2});
			}

			@include media(md) {
				width: calc(100% - #{grid-margin(md) * 2});
			}

			@include media(md-up) {
				padding: 0 20px 16px;
			}

			@include media(lg-up) {
				min-width: 800px;
				width: 800px;
			}
		}

		&-nav {
			margin: 0 -16px;

			@include media(md-up) {
				margin: 0 -20px;
			}
		}

		&-body,
		&-header {
			margin: 0;
		}
	}
}
