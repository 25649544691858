.b-dialog_migrate {
	&-window {
		padding: 0 30px 40px;
		width: 400px;

		@include media(md-down) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&-warn {
		color: $color-error;
		font-size: 12px;
		margin-bottom: 20px;
		padding: 0 30px;
		text-align: center;
	}

	&-title {
		padding: 0 30px;
		text-align: center;

		@include media(sm) {
			padding: 0;
		}
	}

	&-submit {
		font-size: 15px;
		font-weight: 500;
		line-height: 15px;
		margin-bottom: 20px;
	}
}
