.b-newsletters {
	$submit-width: 83px;

	&-caption {
		margin-bottom: 16px;
	}

	&-form {
		padding-right: $submit-width;
		position: relative;

		&[hidden] {
			display: none;
		}
	}

	&-input {
		appearance: none;
		background: $color-white;
		border: 1px solid $color-border-active;
		border-radius: 20px;
		box-shadow: none;
		box-sizing: border-box;
		font-family: inherit;
		font-size: 13px;
		height: 40px;
		line-height: 17px;
		padding: 8px 16px;
		width: calc(100% - 10px);

		&::-ms-clear {
			display: none;
		}

		&::placeholder {
			color: $color-text-dimmed;
		}
	}

	&-message_validation {
		color: $color-error;
		line-height: 1.2;
		margin-top: 8px;
	}

	&-message_success {
		color: $color-action-obj;
		font-size: 20px;
		font-weight: 700;
		line-height: 25px;
		margin: 40px 0 5px;
		text-align: center;
	}

	&-text_success {
		margin-bottom: 40px;
		text-align: center;
	}

	&-icons_list {
		display: none;
	}

	&-message_success[hidden] ~ &-icons_list,
	&-message_success[hidden] ~ &-text_success {
		display: none;
	}

	&-message_success_announcement {
		@include hide(visually);
	}

	&-icon_success {
		position: absolute;

		&.m-first {
			color: $color-bg-newsletters-yellow;
			left: 53px;
			top: -5px;
		}

		&.m-second {
			color: $color-action;
			right: 0;
			top: 0;
		}

		&.m-third {
			color: $color-action;
			left: 0;
			top: 75px;
		}

		&.m-fourth {
			color: $color-bg-newsletters-yellow;
			right: 0;
			top: 80px;
		}

		&.m-fifth {
			bottom: 5px;
			color: $color-bg-newsletters-yellow;
			left: 25px;
		}

		&.m-sixth {
			bottom: 0;
			color: $color-action;
			right: 75px;
		}
	}

	&-privacy {
		margin: 16px -#{$submit-width} 0 0;

		a {
			@include g-link;
		}

		.b-checkbox-icon {
			min-width: 16px;
		}
	}

	&-submit {
		position: absolute;
		right: 0;
		text-transform: none;
		top: 0;
		width: $submit-width;
	}

	.b-form_section {
		margin-bottom: 0;
	}
}
