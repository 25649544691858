.b-minicart_product {
	border: $global-line solid $color-divider;
	border-radius: $global-radius;
	box-shadow: $depth-1;
	color: $color-text;
	padding: 15px;
	position: relative;

	& + & {
		margin-top: 10px;
	}

	&-inner {
		column-gap: 16px;
		display: grid;
		grid-template-areas:
			'msg .'
			'image details'
			'qty total'
			'bundle bundle'
			'remove .';
		grid-template-columns: 76px auto;
		grid-template-rows: auto auto auto; // stylelint-disable-line
		position: relative;
		width: 100%;
	}

	&-voucher {
		font-size: 13px;
		grid-area: voucher;
	}

	&-msg {
		background-color: $color-bg;
		border: $global-line solid $color-error;
		border-radius: 2px;
		color: $color-error;
		font-size: 13px;
		grid-column: span 2;
		margin-bottom: 10px;
		padding: 4px 8px;
	}

	&-link {
		display: block;
	}

	&-image {
		grid-area: image;
	}

	&-details {
		grid-area: details;
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(1, 1));

		img {
			@include g-image_container(_img);
		}
	}

	&-title {
		font-size: 13px;
		font-weight: 400;
		line-height: 1.45;
		margin-bottom: 4px;
	}

	&-price {
		font-size: 13px;
		font-weight: 600;

		&.m-colored {
			color: $color-accent;
		}
	}

	&-price_total {
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		font-size: 17px;
		grid-area: total;
		margin-top: 20px;

		.b-price-subtotal {
			display: flex;
		}

		.b-price-subtotal_item.m-old {
			margin-left: 8px;
		}
	}

	&-info {
		grid-area: qty;
		margin-top: 20px;
	}

	&-qty {
		display: flex;
		font-size: 13px;
	}

	&-qty_inline {
		font-weight: 600;
		margin-right: 5px;
	}

	&-remove {
		grid-area: remove;
		margin-top: 25px;
	}

	&-remove_text {
		margin-left: 6px;
	}

	&-remove_btn {
		align-items: center;
		color: $color-text-dimmed;
		cursor: pointer;
		display: flex;
		transition: opacity $motion-ease;

		@include hover-supported {
			&:hover {
				opacity: 0.8;
			}
		}

		&.m-disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	&-bundle {
		grid-area: bundle;
		margin-top: 20px;

		.b-cart_bundle-label {
			font-size: 13px;
		}
	}

	.b-cart_product-edit {
		color: $color-info;
		font-size: 13px;
		font-weight: 600;
		margin-top: 15px;
		padding: 0;
	}
}
