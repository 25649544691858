.b-wishlist_button {
	align-items: center;
	cursor: pointer;
	display: flex;
	fill: none;
	font-size: 14px;
	transition: $motion-fast;
	transition-property: color, fill;

	&.m-simple {
		@include g-link;

		font-size: 15px;
		text-decoration: none;
		transition: $motion-ease;
		transition-property: color;

		@include media(md-up) {
			font-size: 11px;
		}
	}

	&[aria-busy='true'] {
		cursor: wait;
		pointer-events: none;
	}

	&[aria-pressed='true'] {
		color: $color-action-obj;
		fill: $color-action-obj;
	}

	&[aria-pressed='true'] &-label {
		&.m-not-added {
			display: none;
		}
	}

	&[aria-pressed='false'] &-label {
		&.m-added {
			display: none;
		}
	}

	.b-product_details.m-idea & {
		&[aria-pressed='true'] {
			color: $color-bg;
			fill: $color-bg;
		}
	}

	&-icon {
		@include g-button_iconed;

		transition: $motion-ease;
		transition-property: color, fill;

		&.m-simple {
			height: 16px;
			margin-right: 6px;
			width: 17px;

			@include media(md-up) {
				height: 14px;
				width: 15px;
			}
		}

		&:hover {
			color: inherit;
		}

		.l-cart & {
			color: $color-burgundy;
		}
	}

	&.m-tile &-icon {
		color: $color-action-obj;
		height: 20px;
		width: 20px;
	}

	&.m-tile {
		position: absolute;
		right: 10px;
		top: 11px;
	}
}
