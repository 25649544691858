@mixin g-page-designer-paddings() {
	@each $type in ('top', 'bottom') {
		@each $size, $breakpoint in $page-designer-gutters {
			&.m-padding_#{$type}-#{$size} {
				@each $key, $val in $breakpoint {
					@include media(#{$key}) {
						padding-#{$type}: $val;
					}
				}
			}
		}
	}
}

@mixin g-page-designer-bottom_margins() {
	@each $size, $breakpoint in $page-designer-gutters {
		&.m-margin_bottom-#{$size} &_in {
			@each $key, $val in $breakpoint {
				@include media(#{$key}) {
					margin-bottom: $val;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
