/*md
@no-stat

# global-animations

This component is designed to store all global animation and include it only once in single place
so all other components could reuse this animations.
*/

@at-root {
	@keyframes fade-in {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes fade-out {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes animation-chevron-up {
		0% {
			transform: translateY(-50%) rotate(46deg);
			width: 9px;
		}

		50% {
			transform: translate(-20%, -50%) rotate(0deg);
			width: 7px;
		}

		100% {
			transform: translateY(-50%) rotate(-46deg);
			width: 9px;
		}
	}

	@keyframes animation-chevron-down {
		0% {
			transform: translateY(-50%) rotate(-46deg);
			width: 9px;
		}

		50% {
			transform: translate(-20%, -50%) rotate(0deg);
			width: 7px;
		}

		100% {
			transform: translateY(-50%) rotate(46deg);
			width: 9px;
		}
	}

	@keyframes animation-svg-chevron-down {
		from {
			transform: rotateX(0deg);
		}

		to {
			transform: rotateX(180deg);
		}
	}

	@keyframes animation-svg-chevron-up {
		from {
			transform: rotateX(180deg);
		}

		to {
			transform: rotateX(0deg);
		}
	}

	@keyframes thumbs-zoom {
		0% {
			transform: translateY(35px);
		}

		100% {
			transform: translateY(0);
		}
	}

	@keyframes slide-from-bottom {
		0% {
			transform: translateY(100%);
		}

		100% {
			transform: translateY(0);
		}
	}

	@keyframes dialog-openeing {
		0% {
			transform: scale(0.8);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes hero-carousel-progress {
		from {
			stroke-dashoffset: 104;
		}

		to {
			stroke-dashoffset: 1;
		}
	}

	@keyframes loader {
		0%,
		100% {
			box-shadow: 0 -2.6em 0 0 $color-loader, 1.8em -1.8em 0 0 rgba($color-loader, 0.2), 2.5em 0 0 0 rgba($color-loader, 0.2), 1.75em 1.75em 0 0 rgba($color-loader, 0.2), 0 2.5em 0 0 rgba($color-loader, 0.2), -1.8em 1.8em 0 0 rgba($color-loader, 0.2), -2.6em 0 0 0 rgba($color-loader, 0.5), -1.8em -1.8em 0 0 rgba($color-loader, 0.7);
		}

		12.5% {
			box-shadow: 0 -2.6em 0 0 rgba($color-loader, 0.7), 1.8em -1.8em 0 0 $color-loader, 2.5em 0 0 0 rgba($color-loader, 0.2), 1.75em 1.75em 0 0 rgba($color-loader, 0.2), 0 2.5em 0 0 rgba($color-loader, 0.2), -1.8em 1.8em 0 0 rgba($color-loader, 0.2), -2.6em 0 0 0 rgba($color-loader, 0.2), -1.8em -1.8em 0 0 rgba($color-loader, 0.5);
		}

		25% {
			box-shadow: 0 -2.6em 0 0 rgba($color-loader, 0.5), 1.8em -1.8em 0 0 rgba($color-loader, 0.7), 2.5em 0 0 0 $color-loader, 1.75em 1.75em 0 0 rgba($color-loader, 0.2), 0 2.5em 0 0 rgba($color-loader, 0.2), -1.8em 1.8em 0 0 rgba($color-loader, 0.2), -2.6em 0 0 0 rgba($color-loader, 0.2), -1.8em -1.8em 0 0 rgba($color-loader, 0.2);
		}

		37.5% {
			box-shadow: 0 -2.6em 0 0 rgba($color-loader, 0.2), 1.8em -1.8em 0 0 rgba($color-loader, 0.5), 2.5em 0 0 0 rgba($color-loader, 0.7), 1.75em 1.75em 0 0 $color-loader, 0 2.5em 0 0 rgba($color-loader, 0.2), -1.8em 1.8em 0 0 rgba($color-loader, 0.2), -2.6em 0 0 0 rgba($color-loader, 0.2), -1.8em -1.8em 0 0 rgba($color-loader, 0.2);
		}

		50% {
			box-shadow: 0 -2.6em 0 0 rgba($color-loader, 0.2), 1.8em -1.8em 0 0 rgba($color-loader, 0.2), 2.5em 0 0 0 rgba($color-loader, 0.5), 1.75em 1.75em 0 0 rgba($color-loader, 0.7), 0 2.5em 0 0 $color-loader, -1.8em 1.8em 0 0 rgba($color-loader, 0.2), -2.6em 0 0 0 rgba($color-loader, 0.2), -1.8em -1.8em 0 0 rgba($color-loader, 0.2);
		}

		62.5% {
			box-shadow: 0 -2.6em 0 0 rgba($color-loader, 0.2), 1.8em -1.8em 0 0 rgba($color-loader, 0.2), 2.5em 0 0 0 rgba($color-loader, 0.2), 1.75em 1.75em 0 0 rgba($color-loader, 0.5), 0 2.5em 0 0 rgba($color-loader, 0.7), -1.8em 1.8em 0 0 $color-loader, -2.6em 0 0 0 rgba($color-loader, 0.2), -1.8em -1.8em 0 0 rgba($color-loader, 0.2);
		}

		75% {
			box-shadow: 0 -2.6em 0 0 rgba($color-loader, 0.2), 1.8em -1.8em 0 0 rgba($color-loader, 0.2), 2.5em 0 0 0 rgba($color-loader, 0.2), 1.75em 1.75em 0 0 rgba($color-loader, 0.2), 0 2.5em 0 0 rgba($color-loader, 0.5), -1.8em 1.8em 0 0 rgba($color-loader, 0.7), -2.6em 0 0 0 $color-loader, -1.8em -1.8em 0 0 rgba($color-loader, 0.2);
		}

		87.5% {
			box-shadow: 0 -2.6em 0 0 rgba($color-loader, 0.2), 1.8em -1.8em 0 0 rgba($color-loader, 0.2), 2.5em 0 0 0 rgba($color-loader, 0.2), 1.75em 1.75em 0 0 rgba($color-loader, 0.2), 0 2.5em 0 0 rgba($color-loader, 0.2), -1.8em 1.8em 0 0 rgba($color-loader, 0.5), -2.6em 0 0 0 rgba($color-loader, 0.7), -1.8em -1.8em 0 0 $color-loader;
		}
	}

	@media (prefers-reduced-motion) {
		// stylelint-disable
		* {
			animation: none !important;
			transition: none !important;
		}
		// stylelint-enable
	}
}
