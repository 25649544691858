.b-body_text {
	&-11 {
		font-size: 11px;
		line-height: 150%;
	}

	&-13 {
		font-size: 13px;
		line-height: 150%;
	}

	&-15 {
		font-size: 15px;
		line-height: 150%;
	}

	&-15_2 {
		font-size: 15px;
		line-height: 125%;
	}

	&-17 {
		font-size: 17px;
		line-height: 125%;
	}
}

.m-regular {
	font-weight: 400;
}

.m-medium {
	font-weight: 500;
}

.m-semibold {
	font-weight: 600;
}

.m-standalone_link {
	color: $color-burgundy;
	font-weight: 600;
	text-decoration: none;

	&:hover,
	.b-text_block &:hover {
		text-decoration: underline;
	}
}
