.b-countdown {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: -10px;
	position: relative;

	&-time {
		@include g-heading_2;

		color: $color-title;
	}
}
