/*md

# b-availability

Designed to provide same styles of availability across different components.

```html_example
<div data-ref="container" class="b-availability m-instock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		In Stock
	</div>
</div>
```

```html_example
<div data-ref="container" class="b-availability m-outofstock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		Out Of Stock
	</div>
</div>
```

```html_example
<div data-ref="container" class="b-availability m-lowinstock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		5 Items In Stock
	</div>
</div>
```

*/

.b-availability {
	align-items: center;
	color: $color-primary;
	display: flex;
	font-size: 15px;
	margin-bottom: 15px;

	&-button {
		cursor: pointer;
		display: inline-block;
		font-weight: 600;
		text-decoration: underline;
	}

	&-icon {
		align-items: center;
		color: $color-icon-availability;
		display: flex;
		justify-content: center;
		margin-right: 12px;
		min-width: 24px;
		width: 24px;

		&.m-outofstock {
			color: $color-icon-availability-outofstock;
		}

		svg {
			height: auto;
			max-width: 100%;
		}
	}

	&.m-last,
	&:last-child {
		margin-bottom: 0;
	}

	&-low_stock {
		color: $color-inventory-message;
		font-weight: 600;
	}
}
