.l-grid_layout {
	$root: &;

	@include g-page-designer-paddings;

	background-color: var(--bg-layout-color);
	background-position: var(--bg-image-position);
	background-repeat: var(--bg-image-repeat);
	background-size: var(--bg-image-size);
	margin: 0 auto;
	max-width: $global-site-width-max;
	overflow: hidden;
	padding: 25px 0;

	@include media(lg-up) {
		background-image: var(--bg-image-desktop);
		padding: 40px grid-margin(lg);
	}

	@include media(md) {
		background-image: var(--bg-image-tablet);
		padding: 45px grid-margin(md);
	}

	@include media(sm) {
		background-image: var(--bg-image-mobile);
		padding-left: grid-margin(sm);
		padding-right: grid-margin(sm);
	}

	&.b-cat_tiles {
		@include media(lg-up) {
			display: initial;
		}
	}

	.b-cat_tiles-grid {
		max-width: none;
	}

	&-hide {
		@include media(sm) {
			&_sm {
				display: none;
			}
		}

		@include media(md) {
			&_md {
				display: none;
			}
		}

		@include media(lg-up) {
			&_lg {
				display: none;
			}
		}
	}

	&.m-full_bleed,
	&.m-full_with_centered {
		max-width: $global-site-width-max;
		padding-left: 0;
		padding-right: 0;
	}

	&.m-full_with_centered {
		max-width: $content-limit-xl;
	}

	&.m-centered {
		max-width: $content-limit-lg + 40px;

		@include media(lg-up) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&.m-2_8_2 {
		@include media(sm) {
			#{$root}-item.m-lg_2 {
				display: none;
			}
		}
	}

	&.m-promotional & {
		--bg-layout-color: none;
		--bg-image-desktop: none;
		--bg-image-tablet: none;
		--bg-image-mobile: none;
	}

	&.m-supplementary {
		overflow: visible;
	}

	&-content {
		@include g-grid;

		.m-full_with_centered & {
			@include g-section_holder;
		}

		&.m-hide_gutters {
			grid-gap: 0;
		}

		&.b-featured_category {
			@include media(sm) {
				gap: 16px;
			}
		}

		&.b-currated {
			max-width: $content-limit-xl;
			padding: 0 24px;

			@include media(sm) {
				gap: 0;
				padding: 0 15px;
			}
		}
	}

	&-item { // stylelint-disable-line
		$sm-cols: grid-columns('sm');
		$md-cols: grid-columns('md');
		$lg-cols: grid-columns('lg');

		@include g-page-designer-alignments;
		@include g-page-designer-bottom_margins;

		max-width: 100%;

		&_in {
			display: flex;

			> div,
			> section {
				width: 100%;
			}

			> .m-alternative_category_tiles {
				margin-top: -25px;
			}

			.m-supplementary & {
				margin: 10px 0;

				@include media(sm) {
					margin: 5px 0;
				}
			}
		}

		&_in:only-child {
			height: 100%;
		}

		&[data-items='one'] {
			align-self: auto;
		}

		&.m-title {
			grid-column: span 6;
			order: -2;

			@include media('md-up') {
				grid-column: span 12;
			}
		}

		&.m-fade_in > div,
		&.m-fade_in > section {
			opacity: 0;
			transform: translateY(10%);
			transition: 1.2s ease-out;
			transition-property: transform, opacity;
		}

		&.m-bounce {
			overflow: hidden;
		}

		&.m-bounce > div,
		&.m-bounce > section {
			opacity: 0;
			transform: scale(0.8);
		}

		&.m-fade_in.m-animated > div,
		&.m-fade_in.m-animated > section {
			opacity: 1;
			transform: translateY(0);
		}

		&.m-bounce.m-animated > div,
		&.m-bounce.m-animated > section {
			animation-duration: 1.2s;
			animation-fill-mode: both;
			animation-name: growIn;
		}

		@for $column from $sm-cols through 1 {
			&.m-sm_#{$column} {
				grid-column: span $column;
			}
		}

		@for $column from $md-cols through 1 {
			@include media('md-up') {
				&.m-md_#{$column} {
					grid-column: span $column;
				}
			}
		}

		@for $column from $lg-cols through 1 {
			@include media('lg-up') {
				&.m-lg_#{$column} {
					grid-column: span $column;
				}
			}
		}
	}

	&-item_title {
		color: $color-burgundy;
		padding-bottom: 0;

		#{$root}.m-full_bleed & {
			@include g-section_holder;

			max-width: $content-limit-xl;

			.l-static_page:not(.m-full_width) & {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&.m-custom {
		#{$root}-item {
			display: flex;
		}

		#{$root}-item > div {
			width: 100%;
		}
	}

	&.m-mobile_reversed {
		@include media(sm) {
			#{$root}-item:last-child {
				order: -1;
			}
		}
	}

	&.m-3_9,
	&.m-4_8,
	&.m-3_3_6 {
		@include media(sm) {
			#{$root}-item:last-child {
				order: -1;
			}
		}

		&.m-mobile_reversed #{$root}-item:last-child {
			order: 1;
		}
	}
}
