.b-evoucher {
	display: grid;
	gap: 5px 10px;
	grid-template-columns: auto 1fr;
	margin: 0;
	padding: 10px 8px;

	.b-minicart_product-details & {
		font-size: 13px;
		padding: 0;
	}

	&-item {
		font-size: 13px;
		line-height: 16px;
	}

	&-name {
		font-weight: 600;
		grid-column: 1 / 2;
	}

	&-value {
		grid-column: 2 / 3;
		line-height: 18px;
		margin: 0;
		word-break: break-word;
	}
}
