// Cookies acceptance / Consent tracking modeless dialog
.b-notification_panel {
	background: $color-bg-slot-pink;
	bottom: 0;
	color: $color-action-obj;
	display: none;
	left: 0;
	padding: 15px 0;
	position: fixed;
	right: 0;
	z-index: z(notification-panel);

	&.m-visible {
		display: block;
	}

	&-inner {
		@include g-section_holder;

		align-items: center;
		display: flex;

		@include media(md-down) {
			align-items: flex-start;
			flex-direction: column;
		}
		
		@include media(lg-up) {
			padding-right: 90px;
		}
	}

	&-title {
		@include hide(visually);
	}

	&-description {
		font-size: 15px;

		@include media(lg-up) {
			font-size: 13px;
		}
	}

	&-link {
		color: inherit;
		text-decoration: none;
	}

	&-button {
		margin: 20px 0 0;

		@include media(lg-up) {
			margin: 0 20px;
		}

		&.m-link {
			height: auto;
		}
	}

	&-image {
		bottom: 0;
		position: absolute;
		right: 0;
	}
}
