.b-cart_bundle {
	font-size: 15px;

	&-inner {
		border-bottom: $global-line solid $color-divider-dimmed;
		padding: 0 13px 8px 10px;
	}

	&-label {
		align-items: center;
		color: $color-primary;
		cursor: pointer;
		display: flex;
		font-size: 15px;
		justify-content: space-between;
		width: 100%;
	}

	&-icon {
		animation-duration: 0.23s;
		animation-fill-mode: forwards;
		animation-name: animation-svg-chevron-up;
		animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		color: $color-action-obj;
		display: flex;
		padding-left: 15px;

		[aria-expanded='true'] ~ &,
		[aria-expanded='true'] & {
			animation-name: animation-svg-chevron-down;
		}
	}

	&-content {
		color: $color-text-dimmed;
		padding: 13px 6px 0;

		[aria-expanded='true'] ~ & {
			animation-name: animation-svg-chevron-down;
		}
	}

	&-item {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 3px;
		}
	}
}
