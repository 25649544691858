.b-product_tile_alt_view {
	@include media(md-down) {
		&::after,
		&::before {
			background-color: $color-text-grey;
			border: 3.5px solid $color-bg;
			border-radius: 100%;
			bottom: rh(2);
			content: '';
			display: inline-block;
			height: 14px;
			left: 50%;
			pointer-events: none;
			position: absolute;
			width: 14px;
			z-index: 1;
		}

		&::before {
			background-color: $color-action-obj;
			transform: translateX(-11px);
		}

		&::after {
			transform: translateX(11px);
		}

		&.m-alt_active {
			&::before {
				background-color: $color-text-grey;
			}

			&::after {
				background-color: $color-action-obj;
			}
		}
	}

	&-track {
		height: 100%;
		overflow: hidden;
		position: relative;
		width: 100%;

		@include media(md-down) {
			display: flex;
			-ms-overflow-style: none;
			overflow-x: auto;
			scroll-snap-type: x mandatory;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	&-item {
		height: 100%;

		@include media(md-down) {
			min-width: 100%;
			scroll-snap-align: start;
		}

		&.m-alt {
			@include media(lg-up) {
				background-color: $color-bg;
				bottom: 0;
				left: 0;
				opacity: 0;
				position: absolute;
				right: 0;
				top: 0;
				transition: opacity $motion-ease;
			}

			.b-product_tile_alt_view:hover & {
				@include media(lg-up) {
					opacity: 1;
				}
			}
		}
	}
}
