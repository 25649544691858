.b-hero_carousel {
	margin: 0 auto;
	max-width: $content-limit-xl;
	overflow: hidden;
	position: relative;

	&.m-currated {
		display: flex;
	}

	&-track {
		display: flex;
		overflow: hidden;

		&.m-grabbing {
			cursor: grab;
			user-select: none;

			&::before {
				bottom: 0;
				content: '';
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: z(components, carousel, grabbing-overlay);
			}
		}

		.m-currated_main & {
			height: 100%;
		}
	}

	&-item {
		height: 100%;
		left: 0;
		min-width: 100%; // until JS loaded it is show single slide. This need to flex packing
		top: 0;
		width: 100%;
		will-change: transform;

		&:not(:first-child) {
			visibility: hidden;
		}

		&.m-currated {
			display: flex;
		}

		.b-hero_carousel.m-initialized & {
			overflow: hidden;
			position: absolute;
			transition: cubic-bezier(0.56, 0.03, 0.47, 0.98) 0.8s;
			transition-property: transform, visibility;
			visibility: hidden;
		}

		&.m-prev {
			.b-hero_carousel.m-initialized & {
				transform: translateX(-100%);
			}
		}

		&.m-next {
			.b-hero_carousel.m-initialized & {
				transform: translateX(100%);
			}
		}

		&.m-current {
			.b-hero_carousel.m-initialized & {
				position: static;
				transform: translateX(0);
				visibility: visible;
			}
		}
	}

	&-navigation {
		align-items: center;
		background-color: rgba($color-white, 0.75);
		border-radius: $controls-radius;
		bottom: 10px;
		display: none;
		left: 50%;
		padding: 6px 10px;
		position: absolute;
		transform: translateX(-50%);
		z-index: z(components, carousel, controls);
	}

	&-ctrl {
		appearance: none;
		border: none;
		color: $color-text;
		cursor: pointer;
		height: 22px;
		text-align: center;
		transition: $motion-ease;
		transition-property: background-color, opacity;
		user-select: none;
		width: 22px;

		svg {
			width: 22px;
		}
	}

	&-pagination {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 0 10px;
		opacity: 0;
		transition: opacity $motion-ease;
		visibility: hidden;

		@include media(sm) {
			transform: none;
		}

		&.m-initialized {
			opacity: 1;
			visibility: visible;
		}
	}

	&-pagination_dots {
		display: flex;
	}

	&-pagination_dot {
		$dot-size: 14px;

		border: none;
		color: transparent;
		cursor: pointer;
		display: block;
		fill: $color-input-border;
		height: $dot-size;
		margin-right: 16px;
		padding: 0;
		position: relative;
		width: $dot-size;

		@include hover-supported {
			&:hover {
				fill: $color-action-obj;
			}
		}

		&.m-current {
			fill: $color-action-obj;
		}

		&:last-child {
			margin-right: 0;
		}

		&[aria-disabled='true'] {
			cursor: initial;
		}
	}

	&-pagination_svg {
		display: block;
	}

	&-autoplay {
		background: transparent;
		border: none;
		border-radius: 50%;
		cursor: pointer;
		display: none;
		margin-right: 10px;
		padding: 0;

		&.m-initialized {
			display: block;
		}

		&.m-animated {
			animation: hero-carousel-progress linear;
		}
	}

	&-autoplay_svg {
		fill: transparent;
		stroke-linecap: round;
		stroke-width: 1;
	}

	&-autoplay_progress {
		stroke: $color-input-border;
		stroke-width: 2;

		.b-hero_carousel-autoplay.m-animated & {
			stroke: $color-action-obj;
		}
	}

	&-autoplay_progress_back {
		stroke: $color-input-border;
		stroke-width: 2;
	}

	&-autoplay_play,
	&-autoplay_pause {
		display: block;
		fill: $color-action-obj;
		opacity: 1;
		transition: opacity ease 0.2s;

		@include hover-supported {
			.b-hero_carousel-autoplay:hover & {
				fill: $color-action-obj;
			}
		}
	}

	&-autoplay_play {
		.b-hero_carousel-autoplay[aria-pressed='false'] & {
			opacity: 0;
		}
	}

	&-autoplay_pause {
		.b-hero_carousel-autoplay[aria-pressed='true'] & {
			opacity: 0;
		}
	}

	&.m-initialized &-navigation {
		display: flex;
	}
}
