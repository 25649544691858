.b-video_module {
	margin-bottom: 30px;
	padding-top: 20px;
	position: relative;

	&.m-html5 {
		margin: 0;
		padding: 0;
	}

	&-title {
		color: $color-burgundy;
		font-size: 32px;
		font-weight: 700;
		margin-bottom: 0;
		text-align: center;

		@include media(sm) {
			font-size: 24px;
		}
	}

	&-main {
		margin: 15px 0;
		text-align: center;
	}

	&-wrapper {
		position: relative;

		.b-play_button {
			background: none;

			&.m-hide {
				display: none;
			}
		}
	}

	&-item {
		margin: 0 8px;
		position: relative;
		width: calc(25% - 16px);

		@include media(sm) {
			margin: 8px 0;
			min-width: calc(50% - 8px);
			width: calc(50% - 8px);
		}

		&.m-current {
			border: 2px solid $color-pink;
			border-radius: 2px;

			.b-play_button {
				display: none;
			}
		}

		.g-content_tile-subtitle {
			@include line-clamping(2);
		}
	}

	&-thumbs {
		margin: 0 -8px;

		@include media(sm) {
			margin: 20px 0 0;
			overflow: hidden;
			padding: 0 10px;
		}
	}

	&-video {
		overflow: hidden;
		position: relative;
		width: 100%;

		img {
			margin: 0 auto;
		}
	}

	&-playing {
		background: rgba($color-pink, 0.75);
		bottom: 0;
		color: $color-white;
		font-size: 20px;
		font-weight: bold;
		line-height: 30px;
		padding: 5px;
		position: absolute;
		text-align: center;
		width: 100%;

		.m-current & {
			display:  block !important; // stylelint-disable-line
		}
	}

	&-content {
		color: $color-burgundy;
		margin-left: 60px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;

		@include media(xl) {
			margin-left: 210px;
		}

		@include media(sm) {
			margin-left: 40px;
		}

		&.m-rounded,
		&.m-overlay {
			background: rgba($color-natural, 0.75);
			border-radius: 0 35px 35px 0;
			margin: 0;
			max-width: 475px;
			padding: 24px 40px;

			@include media(lg) {
				border-radius: 0 25px 25px 0;
				max-width: 340px;
				padding: 20px 40px;
			}

			@include media(md) {
				border-radius: 0 20px 20px 0;
				max-width: 255px;
				padding: 15px 30px;
			}

			@include media(sm) {
				border-radius: 20px 20px 0 0;
				bottom: 0;
				left: 50%;
				max-width: 330px;
				padding: 15px;
				text-align: center;
				top: auto;
				transform: translateX(-50%) translateY(0);
				width: max-content;
			}

			@include media(sm_landscape) {
				border-radius: 0 20px 20px 0;
				bottom: auto;
				left: auto;
				max-width: 255px;
				padding: 15px 30px;
				text-align: left;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&.m-overlay {
			align-items: flex-start;
			background: none;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;

			@include media(sm) {
				align-items: center;
				justify-content: flex-end;
			}

			@include media(sm_landscape) {
				align-items: flex-start;
				justify-content: center;
			}

			&::before {
				background: linear-gradient(to right, rgba($color-natural, 0.75) 0%, rgba($color-natural, 0.45) 50%, rgba($color-white, 0) 100%);
				content: '';
				display: block;
				inset: 0 0 0 0;
				position: absolute;
				width: 420px;
				z-index: -1;

				@include media(lg) {
					width: 356px;
				}

				@include media(md) {
					width: 285px;
				}

				@include media(sm) {
					background: linear-gradient(to top, rgba($color-natural, 0.75) 0%, rgba($color-natural, 0.45) 50%, rgba($color-white, 0) 100%);
					height: 180px;
					left: -50%;
					top: auto;
					width: 200%;
				}

				@include media(sm_landscape) {
					background: linear-gradient(to right, rgba($color-natural, 0.75) 0%, rgba($color-natural, 0.45) 50%, rgba($color-white, 0) 100%);
					height: 100%;
					inset: 0 0 0 0;
					width: 285px;
				}
			}

			&.m-dark {
				background: none;
			}
		}

		&.m-dark {
			background: rgba($color-burgundy, 0.75);
			color: $color-natural;

			&::before {
				background: linear-gradient(to right, rgba($color-burgundy, 0.75) 0%, rgba($color-burgundy, 0.45) 50%, rgba($color-white, 0) 100%);

				@include media(sm) {
					background: linear-gradient(to top, rgba($color-burgundy, 0.75) 0%, rgba($color-burgundy, 0.45) 50%, rgba($color-white, 0) 100%);
				}

				@include media(sm_landscape) {
					background: linear-gradient(to right, rgba($color-burgundy, 0.75) 0%, rgba($color-burgundy, 0.45) 50%, rgba($color-white, 0) 100%);
				}
			}
		}
	}

	&-heading {
		font-size: 32px;
		font-weight: 700;
		line-height: 1.25;
		margin-bottom: 8px;
		max-height: 120px;
		overflow: hidden;

		@include media(xl) {
			font-size: 48px;
			margin-bottom: 16px;
			max-height: 180px;
		}

		@include media(lg) {
			font-size: 40px;
			margin-bottom: 16px;
			max-height: 150px;
		}

		@include media(sm) {
			margin-left: auto;
			margin-right: auto;
		}

		@include media(sm_landscape) {
			font-size: 28px;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&-subheading {
		@include line-clamping(2);

		font-size: 20px;
		font-weight: 700;

		@include media(lg-up) {
			font-size: 24px;
			margin-bottom: 4px;
		}

		@include media(sm) {
			margin-left: auto;
			margin-right: auto;
		}

		@include media(sm_landscape) {
			font-size: 18px;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&-button {
		padding: 0 28px;

		.m-dark & {
			background-color: $color-natural;
			border-color: $color-natural;
			color: $color-burgundy;

			&:hover {
				background-color: $color-burgundy;
				border-color: $color-burgundy;
				color: $color-natural;
			}
		}
	}

	&-player {
		aspect-ratio: 16 / 5;
		background: var(--poster) no-repeat;
		background-size: cover;
		display: block;
		margin: 0 auto;
		max-width: $content-limit-xl;

		@include media(sm) {
			background: var(--poster-mobile) no-repeat;
			background-size: cover;
			height: 100vw;
			max-height: none;
		}

		@include media(sm_landscape) {
			aspect-ratio: 16 / 5;
			background: var(--poster) no-repeat;
			background-size: cover;
			height: auto;
		}
	}

	&-controls {
		left: 0;
		padding: 18px;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&-play {
		background-color: rgba($color-white, 0.75);
		border-radius: 50%;
		cursor: pointer;
		height: 40px;
		position: absolute;
		right: 18px;
		top: 18px;
		width: 40px;

		@include media(sm) {
			right: 10px;
			top: 10px;
		}
	}

	&-play_svg {
		fill: transparent;
	}

	&-progress {
		stroke: $color-burgundy;
		stroke-width: 2;
	}

	&-pause_icon {
		display: none;

		.m-playing & {
			display: block;
		}
	}

	&-play_icon {
		.m-playing & {
			display: none;
		}
	}

	.b-play_button svg {
		height: 100px;
		width: 100px;
	}

	.b-carousel-track {
		margin: -8px 0;
		padding: 8px 0;

		@include media(sm) {
			gap: 16px;
		}
	}

	.b-carousel-ctrl {
		border: none;
		box-shadow: none;
		height: 40px;
		margin-top: 0;
		width: 40px;

		&::after {
			background-color: $color-white;
			border: 1px solid $color-mid-grey;
			border-radius: 50%;
			box-shadow: $depth-1;
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&::before {
			background: linear-gradient(to left, var(--bg-layout-color, $color-white) 0%, rgba($color-white, 0) 100%);
			content: '';
			height: 300px;
			position: absolute;
			right: 8px;
			top: 50%;
			transform: translateY(-50%);
			width: 40px;
			z-index: -1;
		}

		&.m-prev {
			left: 0;

			&::before {
				background: linear-gradient(to right, var(--bg-layout-color, $color-white) 0%, rgba($color-white, 0) 100%);
				left: 8px;
			}
		}

		&.m-next {
			right: 0;
		}

		svg {
			height: 19px;
			position: relative;
			width: 19px;
			z-index: 1;
		}
	}
}
