/*md

# Input

Default input element

## Default

```html_example
	<input
		class="b-input"
		type="text"
		placeholder="Promo Code"
		name="couponCode"
		required=""
		value=""
	>
```

## Invalid

```html_example
	<input
		class="b-input m-invalid"
		type="text"
		placeholder="Promo Code"
		name="couponCode"
		required=""
		value=""
	>
```

*/

.b-input {
	appearance: none;
	border: $global-line solid $color-input-border;
	border-radius: $input-radius;
	color: $color-input-text;
	cursor: text;
	font-family: inherit;
	font-size: 13px;
	height: $size-input-height;
	line-height: $size-input-height;
	padding: 0 10px;
	transition: border-color $motion-ease;
	vertical-align: baseline;
	width: 100%;

	&:hover,
	&:active,
	&:focus {
		border-color: $color-select-border;
	}

	&:focus {
		@include media(md-down) {
			font-size: 16px;
		}
	}

	&.m-invalid {
		border-color: $color-error-border;
	}

	&.m-width_intrinsic {}

	.b-input_password & {
		padding-right: rh(17);
	}

	&[disabled] {
		background: $color-divider-light;
		color: $color-action-disabled;
	}

	&::placeholder {
		color: $color-text-grey;
	}

	.b-giftcard &.m-equal {
		max-width: 178px;
	}

	.b-data_sheets & {
		border-radius: 20px 0 0 20px;
	}
}
